import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { uommanagementlist, delete_uom } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class UOMList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            deleteSuccess: "UOM Has been Deleted Successfully",
            serverErrors: ["uom_delete", "uommanagement_res"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "UOM Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "UOM Code",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'conFact',
                    label: "Conv Factor",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        function: this.deleteRow
                    }]
                }
            ]
        }

    }
    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }


    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.uommanagementlist(data);
        this.setState({ isLoading: true });
    }


    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.uommanagementlist(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_uom(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "uommanagement_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "uom_delete", this.loadResult, this.state.deleteSuccess);
        }
        return true;
    }

    render() {
        const { uommanagement_res } = this.props;
        const totResult = uommanagement_res ? uommanagement_res.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        UOM (Units of Measurement)
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null :
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    {/* <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} /> */}
                                                    <LinkWithItag to={routehelp.uomAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add UOM" />
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            totResult === undefined ? <p className="text-center">No Data Found</p> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    uommanagement_res !== undefined && uommanagement_res.count === 0 ?
                                                        <p className="text-center">No Data Found</p>
                                                        :
                                                        uommanagement_res && uommanagement_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort}
                                                                data={uommanagement_res} props={this.props} />
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {totResult !== undefined ? uommanagement_res !== undefined && uommanagement_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        uommanagement_res: state.uommanagement_res,
        uom_delete: state.uom_delete
    };
}

const mapDispatchToProps = dispatch => ({
    uommanagementlist: data => dispatch(uommanagementlist(data)),
    delete_uom: item_id => dispatch(delete_uom(item_id))
});

UOMList = connect(
    mapStateToProps,
    mapDispatchToProps
)(UOMList);

export default UOMList;