import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { brand_search } from '../../actions';

class Branddropdown extends Component {

  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   brand: ''
  //   // };
  // }

  componentDidMount() {
    let data = {};
    data.sortBy = "name";
    data.sort = "asc";
    this.props.brand_search(data);
  }

  render() {
    const {
      brand_response_search,
      ondropdownChange,
      name,
      value,
      onSelectResetsInput,
      placeholder,
      isMulti,
      isSearchable,
      labelValue, idx
    } = this.props;

    const options = [];
    if (brand_response_search !== undefined && brand_response_search.list) {
      brand_response_search.list.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        // if (b.name.toUpperCase() > a.name.toUpperCase()) return 1;
        return 0;
      }).map(item =>
        options.push({ value: item._id, label: item.name })
      );
    }

    return (
      <div>
        {labelValue === undefined ? <label className="control-label">Brand</label> : null}

        {idx !== undefined ? <Select
          // {...(value === "") ? { value: "" } : {}}
          isSearchable={isSearchable}
          isMulti={isMulti}
          name={name}
          value={value}

          // value={value}
          onSelectResetsInput={onSelectResetsInput}
          onChange={value => {
            ondropdownChange(idx, value);
          }}
          options={options}
          placeholder={placeholder !== undefined ? placeholder : "Brand"}
        /> : isMulti && isMulti === true ?
          <Select
            // {...(value === "") ? { value: "" } : {}}
            isSearchable={isSearchable}
            isMulti={isMulti}
            name={name}
            // Value={value}

            value={value}
            onSelectResetsInput={onSelectResetsInput}
            onChange={value => {
              ondropdownChange(name, value);
            }}
            options={options}
            placeholder={placeholder !== undefined ? placeholder : "Brand"}
          /> :
          <Select
            // {...(value === "") ? { value: "" } : {}}
            isSearchable={isSearchable}
            isMulti={isMulti}
            name={name}
            value={value}

            // value={value}
            onSelectResetsInput={onSelectResetsInput}
            onChange={value => {
              ondropdownChange(name, value);
            }}
            options={options}
            placeholder={placeholder !== undefined ? placeholder : "Brand"}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { brand_response_search: state.brand_response_search };
}

const mapDispatchToProps = dispatch => ({
  brand_search: data => dispatch(brand_search(data))
});

Branddropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(Branddropdown);

export default Branddropdown;
