import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { beatsmanagecreate, region_search, territory_search, beatssingle, beatsmanageupdate } from '../../actions';
import { errorRender, successRedriect, validate, inputCheck } from '../../helpers/functions';

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Territorydropdown = lazy(() => import('../territoryManagement/territoryDropdown'));
const RegionDropdown = lazy(() => import('../regionManagement/RegionDropdown'));
const Zonedropdown = lazy(() => import('../zoneManagement/zoneDropdwon'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));

class Beats_manage_add extends Component {
    state = {
        pageType: "add",
        itemId: "",
        name: '',
        code: '',
        zone: null,
        region: null,
        territory: null,
        serverErrors: ["beats_create_res", "beats_update", "beats_single"],
        forRedirect: [
            { name: "beats_create_res", pageName: 'Beat', pageUrl: 'Beatsmanagement', action: "Added" },
            { name: "beats_update", pageName: 'Beat', pageUrl: 'Beatsmanagement', action: "Updated" }
        ],
        setFields: [
            { name: "name", type: "text" },
            { name: "code", type: "text" },
            { name: "zone", type: "select" },
            { name: "region", type: "select" },
            { name: "territory", type: "select" }
        ],
        nameError: '',
        codeError: '',
        zoneError: '',
        regionError: '',
        territoryError: '',
        isLoading: false,
        getSingleLoader: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.beatssingle(id);
        }
    }

    componentWillUnmount() {
        this.setState({});
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }

        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "zone") {
                if (this.state.zone !== null) {
                    if (val.value !== this.state.zone.value) {
                        this.setState({ region: null, territory: null });
                        let data = {
                            zone: val.value
                        };
                        this.props.region_search(data);
                    }
                } else {
                    let data = {
                        zone: val.value
                    };
                    this.props.region_search(data);
                }
            }
            if (name === "region") {
                if (this.state.region !== null) {
                    if (val.value !== this.state.region.value) {
                        this.setState({ territory: null });
                        let data = {
                            region: val.value
                        };
                        this.props.territory_search(data);
                    }
                } else {
                    let data = {
                        region: val.value
                    };
                    this.props.territory_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 20, type: "text", message: "", errName: "codeError" },
            { name: 'zone', label: "Zone", value: this.state.zone, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "zoneError" },
            { name: 'region', label: "Region", value: this.state.region, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "regionError" },
            { name: 'territory', label: "Territory", value: this.state.territory, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "territoryError" }
        ]
        let data = {
            name: this.state.name,
            code: this.state.code,
            zone: this.state.zone && this.state.zone.value,
            region: this.state.region && this.state.region.value,
            territory: this.state.territory && this.state.territory.value
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true })
            if (this.state.pageType === "update") {
                this.props.beatsmanageupdate(data, this.state.itemId);
            } else {
                this.props.beatsmanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            if (nextProps.beats_single && nextProps.beats_single.success === true) {
                this.setState({ getSingleLoader: false });
                nextProps.beats_single.success = null;
                if (nextProps.beats_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.name]: nextProps.beats_single.item[value.name] })
                        } else if (value.type === "select" && nextProps.beats_single.item[value.name]) {
                            let obj = {
                                label: nextProps.beats_single.item[value.name].name,
                                value: nextProps.beats_single.item[value.name]._id
                            }
                            this.setState({ [value.name]: obj });
                            this.ondropdownChange(obj, value.name);
                            obj = null
                        }
                    })
                    nextProps.beats_single.success = null;
                }
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update Beats" : "Add Beats"}
                                    </div>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" name="name" value={this.state.name} maxlength="100" className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} type="text" name="code" value={this.state.code} maxlength="10" className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                            </div>
                                            <div className="col-md-6 form-group required col-12 col-lg-4">
                                                <Zonedropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "zone")}
                                                    placeholder="Search Zone"
                                                    value={this.state.zone}
                                                    errMessage={this.state.zoneError}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group required col-12 col-lg-4">
                                                <RegionDropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "region")}
                                                    dontPreload={pageType === "update"}
                                                    placeholder="Search Region"
                                                    value={this.state.region}
                                                    errMessage={this.state.regionError}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group required col-12 col-lg-4">
                                                <Territorydropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "territory")}
                                                    placeholder="Search Territory"
                                                    dontPreload={pageType === "update"}
                                                    value={this.state.territory}
                                                    errMessage={this.state.territoryError}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.Beatsmanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        beats_create_res: state.beats_create_res,
        beats_single: state.beats_single,
        beats_update: state.beats_update
    };
}

const mapDispatchToProps = dispatch => ({
    beatsmanageupdate: (data, id) => dispatch(beatsmanageupdate(data, id)),
    beatsmanagecreate: data => dispatch(beatsmanagecreate(data)),
    region_search: data => dispatch(region_search(data)),
    territory_search: data => dispatch(territory_search(data)),
    beatssingle: data => dispatch(beatssingle(data)),
});

Beats_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Beats_manage_add);

export default Beats_manage_add;