import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { orders_top_10, brandmanagementlist } from '../../actions';
// import { routehelp } from '../../Routehelper';
import { Link } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
import { errorRender, commonDebounce, loaderUpdate } from '../../helpers/functions';
// import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

// const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
// const Pagination = lazy(() => import('../../components/pagination/index'));
// const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const Entries = lazy(() => import('../../components/entries/entires'));
// const SearchInput = lazy(() => import('../../components/searchInput'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Loader = lazy(() => import('../../components/loader'));

// let itemsPerPage = ITEM_PER_PAGES;
// let currentPage = CURRENT_PAGE;
// let sortBy = SORT_BY;
// let sort = SORT;

const now = new Date();
class TopOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
            endDate: new Date(),
            successRequest: [],
            serverErrors: ["orders_top_10_res", "orders_top_10_update"],
            isLoading: false,
            isFilter: false,
            tableHeaders: [
                {
                    name: 'companyOrderNum',
                    label: "Order Number",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'customer.name',
                    label: "Customer Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'productName',
                    label: "Design Number",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'name',
                    label: "Shade Number",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'totalAmount',
                //     label: "Ex-mill price",
                //     type: "text",
                //     sortable: false,
                //     isShow: true
                // },
                {
                    name: 'quantity',
                    label: "No. of Units sold",
                    type: "text",
                    sortable: false,
                    isShow: true
                }]
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult()
    }

    loadResult = async () => {
        let data;
        let filterData = JSON.parse(localStorage.getItem('filter-date'));
        if (!filterData) {
            data = {
                filter: {
                    createdAt: {
                        "from": this.state.startDate,
                        "to": this.state.endDate
                    }
                }
            }
        } else {
            data = filterData;
        }
        this.props.orders_top_10(data);
        await this.props.brandmanagementlist({
            "filter": {
                "code": ""
            },
            "sortBy": "createdAt",
            "sort": "desc",
            "skip": 0,
            "limit": 50
        });
        this.setState({ isLoading: true });
    }

    componentWillUnmount() {
        localStorage.removeItem('filter-date');
    }

    onChange = async (e) => {

        let data;
        let filterData = JSON.parse(localStorage.getItem('filter-date'));
        if (filterData) {
            data = filterData;
        } else {
            data = {
                filter: {
                    createdAt: {
                        "from": this.state.startDate,
                        "to": this.state.endDate
                    }
                }
            }
        }

        data.filter.brand = e.target.value;
        // console.log(data);
        await this.props.orders_top_10(data);

        this.setState({
            isLoading: true
        });

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "orders_top_10_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el, el === "orders_top_10_update" ? this.loadResult : undefined));
            // this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
        }
        return true;
    }

    callforDebounce = () => this.loadResult();

    render() {
        // let orders_top_10_res = {
        //     list: []
        // }
        const { brandmanagement_res, orders_top_10_res } = this.props;
        // orders_least_10 = [];
        // console.log(orders_top_10_res);
        let orderTop = {
            list: []
        };
        if (orders_top_10_res && orders_top_10_res.list && orders_top_10_res.list.length > 0) {
            let finalList = orders_top_10_res.list.map((ele) => {
                const filteredData = {
                    customer: ele.customer,
                    companyOrderNum: ele.companyOrderNum,
                    name: "",
                    totalAmount: ele.totalAmount,
                    quantity: "",
                    productName: ""
                };
                if (filteredData.totalAmount === "NaN") {
                    filteredData.totalAmount = 0
                }
                if (ele.products && ele.products.length > 0) {
                    filteredData.productName = ele.products[0].productName;
                    if (ele.products[0].attributes && ele.products[0].attributes.length > 0) {
                        filteredData.name = ele.products[0].attributes[0].name;
                        // filteredData.price = ele.products[0].attributes[0].price;
                        filteredData.quantity = ele.products[0].attributes[0].quantity;
                    }
                }
                return filteredData;
            });
            // console.log(finalList);
            orderTop.list = finalList;
        }

        const totResult = orders_top_10_res !== undefined ? orders_top_10_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">

                                <div className="mr-3 mt-2 text-right">
                                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                </div>

                                <div className="card-header">
                                    <div className="card-title">
                                        Top 10 Orders
                                    </div>
                                </div>

                                <div className="mt-4">
                                    {
                                        brandmanagement_res && brandmanagement_res.list && brandmanagement_res.list.length > 0 ?
                                            <div className="col-sm-3 ml-auto">
                                                <select className="form-control" name="brands"
                                                    onChange={(e) => this.onChange(e)}>
                                                    <option value="">Select Brand</option>
                                                    {
                                                        brandmanagement_res.list.map(element =>
                                                            // console.log(element.name)
                                                            element.name ? <option value={element._id} key={element._id}>{element.name}</option> : "NA"
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            : null
                                    }
                                </div>

                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="alert alert-info font-weight-bold">
                                                    Note: For Brand D & J , Freefilt and Elitus the unit is "Taka", and For Vercelli the unit is "Meters".
                                                </div>
                                                <div className="table-responsive">
                                                    {totResult === undefined ? <p className="text-center">No Data Found</p> :
                                                        this.state.isLoading ? <Loader /> : orderTop && orderTop.list && orderTop.list.length === 0 ?
                                                            <p className="text-center">No Data Found</p> : orderTop && orderTop.list && orderTop.list.length > 0 ?
                                                                <DataTable headers={this.state.tableHeaders} data={orderTop} />
                                                                :
                                                                <p className="text-center">
                                                                    Something Went Wrong
                                                                </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        orders_top_10_res: state.orders_top_10_res,
        brandmanagement_res: state.brandmanagement_res
    };
}

const mapDispatchToProps = dispatch => ({
    orders_top_10: data => dispatch(orders_top_10(data)),
    brandmanagementlist: data => dispatch(brandmanagementlist(data)),
});

TopOrders = connect(
    mapStateToProps,
    mapDispatchToProps
)(TopOrders);

export default TopOrders;