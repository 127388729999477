import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { territorymanagecreate, territorysingle, region_search, territorymanageupdate } from '../../actions';
import { errorRender, successRedriect, validate, inputCheck } from '../../helpers/functions';

const RegionDropdown = lazy(() => import('../regionManagement/RegionDropdown'));
const Zonedropdown = lazy(() => import('../zoneManagement/zoneDropdwon'));
const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));
class Territory_manage_add extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        zone: null,
        region: null,
        serverErrors: ["territory_create_res", "territory_update", "territory_single"],
        forRedirect: [
            { name: "territory_create_res", pageName: 'Territory', pageUrl: 'Territorymanagement', action: "Added" },
            { name: "territory_update", pageName: 'Territory', pageUrl: 'Territorymanagement', action: "Updated" }
        ],
        setFields: [
            { name: "name", type: "text" },
            { name: "code", type: "text" },
            { name: "zone", type: "select" },
            { name: "region", type: "select" }
        ],
        nameError: '',
        codeError: '',
        ZoneError: '',
        regionError: '',
        isLoading: false,
        getSingleLoader: false
    }


    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }


    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.territorysingle(id);
        }
    }



    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "zone") {
                if (this.state.zone !== null) {
                    if (val.value !== this.state.zone.value) {
                        this.setState({ region: null });
                        let data = {
                            zone: val.value
                        };
                        this.props.region_search(data);
                    }
                } else {
                    let data = {
                        zone: val.value
                    };
                    this.props.region_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }



    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 20, type: "text", message: "", errName: "codeError" },
            { name: 'zone', label: "Zone", value: this.state.zone, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "zoneError" },
            { name: 'region', label: "Region", value: this.state.region, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "regionError" },
        ]
        const data = {
            name: this.state.name,
            code: this.state.code,
            zone: this.state.zone && this.state.zone.value,
            region: this.state.region && this.state.region.value
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.territorymanageupdate(data, this.state.itemId);
            } else {
                this.props.territorymanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.territory_single && nextProps.territory_single.success === true) {
                this.setState({ getSingleLoader: false });
                nextProps.territory_single.success = null;
                if (nextProps.territory_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.name]: nextProps.territory_single.item[value.name] })
                        } else if (value.type === "select" && nextProps.territory_single.item[value.name]) {
                            let obj = {
                                label: nextProps.territory_single.item[value.name].name,
                                value: nextProps.territory_single.item[value.name]._id
                            }
                            this.setState({ [value.name]: obj });
                            this.ondropdownChange(obj, value.name);
                            obj = null
                        }
                    })
                    nextProps.territory_single.success = null;
                }
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update Territory" : "Add Territory"}
                                    </div>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4 ">
                                                <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" maxlength="100" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} type="text" maxlength="10" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                            </div>
                                            <div className="col-md-6 form-group required col-12 col-lg-4">
                                                <Zonedropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "zone")}
                                                    placeholder="Search Zone"
                                                    value={this.state.zone}
                                                    errMessage={this.state.zoneError}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group required col-12 col-lg-4">
                                                {/* {console.log(pageType)} */}
                                                <RegionDropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "region")}
                                                    placeholder="Search Region"
                                                    dontPreload={pageType === "update"}
                                                    value={this.state.region}
                                                    errMessage={this.state.regionError}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.Territorymanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        territory_create_res: state.territory_create_res,
        territory_single: state.territory_single,
        territory_update: state.territory_update
    };
}


const mapDispatchToProps = dispatch => ({
    territorysingle: data => dispatch(territorysingle(data)),
    territorymanagecreate: data => dispatch(territorymanagecreate(data)),
    territorymanageupdate: (data, id) => dispatch(territorymanageupdate(data, id)),
    region_search: data => dispatch(region_search(data))
});

Territory_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Territory_manage_add);

export default Territory_manage_add;