import React, { Component, Fragment } from "react";
// import Swipe from 'react-easy-swipe';
// import './style.css';
let head = document.getElementsByTagName('head')[0];
let vendor = document.createElement('link');
let plugins = document.createElement('link');
let script = document.createElement('link');
class LandingPage extends Component {


    state = {
        loader: true
    }

    componentDidMount() {
        script.rel = 'stylesheet';
        script.type = 'text/css';
        script.href = '/css/style1.css';

        vendor.rel = 'stylesheet';
        // vendor.type = 'text/css';
        vendor.href = '/assets/css/vendor/vendor.min.css';

        plugins.rel = 'stylesheet';
        // plugins.type = 'text/css';
        plugins.href = '/assets/css/plugins/plugins.min.css';
        head.appendChild(script);
        head.appendChild(vendor);
        head.appendChild(plugins);
        setTimeout(() => {
            this.setState({ loader: false });
        }, 500);
    }

    componentWillUnmount() {
        head.removeChild(script);
        head.removeChild(vendor);
        head.removeChild(plugins)
    }

    // onSwipeStart(event) {
    //     console.log('Start swiping...', event);
    // }

    // onSwipeMove(position, event) {
    //     console.log(`Moved ${position.x} pixels horizontally`, event);
    //     console.log(`Moved ${position.y} pixels vertically`, event);
    // }

    // onSwipeEnd(event) {
    //     console.log('End swiping...', event);
    // }

    render() {
        if (this.state.loader) {
            // debugger
            return (
                <Fragment>
                    <div className="preloader-activate preloader-active open_tm_preloader">
                        <div className="preloader-area-wrap">
                            <div className="spinner d-flex justify-content-center align-items-center h-100">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else if (!this.state.loader) {
            // debugger
            return (
                <Fragment>
                    <div className="scroll-1">
                        {/* <div className="preloader-activate preloader-active open_tm_preloader">
                            <div className="preloader-area-wrap">
                                <div className="spinner d-flex justify-content-center align-items-center h-100">
                                    <div className="bounce1"></div>
                                    <div className="bounce2"></div>
                                    <div className="bounce3"></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="header-area header-area--default">
                            <div className="header-bottom-wrap header-sticky">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <nav className="navbar navbar-expand-md navbar-light">

                                                <a className="navbar-brand" href="index.html" target="_blank"><img src="assets/images/logo.svg"
                                                    alt="" /></a>

                                                {/* <button className="navbar-toggler" type="button" data-toggle="collapse"
                                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                                    aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button> */}

                                                <div className="collapse navbar-collapse mr-5" id="navbarSupportedContent">
                                                    <ul className="navbar-nav ml-auto py-4 py-md-0">
                                                        {/* <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                            <a className="nav-link" href="#">Features</a>
                                                        </li>
                                                        <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                            <a className="nav-link" href="#">Web CMS</a>
                                                        </li>
                                                        <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                            <a className="nav-link" href="#">Mobile App</a>
                                                        </li>
                                                        <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                            <a className="nav-link" href="#">Pricing</a>
                                                        </li>
                                                        <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                            <a className="nav-link" href="#">Contact Us</a>
                                                        </li> */}
                                                    </ul>
                                                </div>



                                                <div className="hero-button">
                                                    <button onClick={() => this.props.history.push('/login')} className="ht-btn ht-btn-md">Login</button>
                                                </div>


                                                {/* <div className="header-right-box  wow slideInRight" data-wow-duration="2s" data-wow-delay="1s">
                                                    <div className="header-right-inner" id="hidden-icon-wrapper">
                                                        <div className="language-menu">
                                                            <div className="hero-button">
                                                                <button onClick={() => this.props.history.push('/login')} className="ht-btn ht-btn-md">Login</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="hidden-icons-menu d-block d-md-none" id="hidden-icon-trigger">
                                                        <a href="true">
                                                            <i className="far fa-ellipsis-h-alt"></i>
                                                        </a>
                                                    </div>
                                                </div> */}
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="main-wrapper">
                            <div className="site-wrapper-reveal">
                                <div className="infotechno-hero infotechno-bg">
                                    <div className="container h-100">
                                        <div className="row align-items-center h-100">
                                            <div className="col-lg-6 col-md-6 wow slideInLeft" data-wow-duration="2s" data-wow-delay="1s">
                                                <div className="infotechno-hero-text  wow move-up">
                                                    <h3 className="font-weight--bold mb-15">Intelligent Sales &<br /> Order Management system
                                                    </h3>
                                                    <p>Invento system helps to manage customers, orders, brand, stock and dispatch intelligently.
                                                        A user can place any kinds of sales order through Mobile application and visualize the organization KPI on Dashboard.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 wow slideInRight" data-wow-duration="2s" data-wow-delay="1s">
                                                <div className="infotechno-hero-inner-images">
                                                    <div className="infotechno-inner-two move-up animation-up-down">
                                                        <img className="img-fluid" src="assets/images/invento-home.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="feature-images-wrapper top-card">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="feature-images__five resolutions-hero-bottom">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6 wow move-up wow slideInUp" data-wow-duration="2s"
                                                            data-wow-delay="2s">

                                                            <div className="ht-box-images style-05">
                                                                <div className="image-box-wrap">
                                                                    <div className="content text-center">
                                                                        <h3 className="heading mb-0">B2B</h3>
                                                                    </div>
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid" src="assets/images/b2b.svg" alt="" />
                                                                        </div>
                                                                        <div className="hover-images">
                                                                            <img className="img-fulid" src="assets/images/b2b-1.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up wow slideInUp" data-wow-duration="2s"
                                                            data-wow-delay="2s">

                                                            <div className="ht-box-images style-05">
                                                                <div className="image-box-wrap">
                                                                    <div className="content text-center">
                                                                        <h3 className="heading mb-0">B2C</h3>
                                                                    </div>
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid" src="assets/images/b2c.svg" alt="" />
                                                                        </div>
                                                                        <div className="hover-images">
                                                                            <img className="img-fulid" src="assets/images/b2c-1.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up wow slideInUp" data-wow-duration="2s"
                                                            data-wow-delay="2s">

                                                            <div className="ht-box-images style-05">
                                                                <div className="image-box-wrap">
                                                                    <div className="content text-center">
                                                                        <h3 className="heading mb-0">POS</h3>
                                                                    </div>
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid" src="assets/images/pos.svg" alt="" />
                                                                        </div>
                                                                        <div className="hover-images">
                                                                            <img className="img-fulid" src="assets/images/pos-1.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service-projects-wrapper section-space--pt_100 mb-20">
                                        <div className="container">
                                            <div className="swiper-container ">
                                                <div className="swiper-wrapper service-slider__project">
                                                    <div className="swiper-slide">
                                                        <div className="row align-items-center">
                                                            <div className="slide-content col-lg-6 col-xl-6 wow slideInLeft">
                                                                <div className="service-project-slide-info mt-0">
                                                                    <h3 className="heading mb-4">About Invento</h3>
                                                                    <div className="text mb-3">Invento system helps to manage customers, orders,
                                                                        brand, stock and dispatch intelligently. A user can place any kinds
                                                                        of sales order through Mobile application and visualize the
                                                                        organization KPI on Dashboard. </div>
                                                                    <div className="text">The system plays important role to take data driven
                                                                        decisions.
                                                                        Systems provide next quarter sales forecasting and Inventory stock
                                                                        prediction
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-xl-6 text-center wow slideInRight">
                                                                <div className="slide-image">
                                                                    <div className="image-wrap">
                                                                        <div className="image">
                                                                            <img className="img-fluid" src="assets/images/about-invento.svg"
                                                                                alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="invento-p about-resources-wrapper section-space--ptb_100">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-lg-12  wow slideInUp">
                                                <div className="resources-right-box">
                                                    <div className="resources-right-inner text-center">
                                                        <div className="resources-images">
                                                            <img className="img-fluid" src="assets/images/invento-process.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="feature-invento feature-images-wrapper bg-theme-blue  section-space--ptb_100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="feature-images__one">
                                                    <div className="row">

                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInLeft">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid"
                                                                            src="assets/images/features/Mobile-Ordering.svg" alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Mobile Ordering </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInLeft">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid" src="assets/images/features/POS-System.svg"
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">POS System</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInRight">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid" src="assets/images/features/E-Catalogue.svg"
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">E-Catalogue</h6>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInRight">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid" src="assets/images/features/QR-Code.svg"
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">QR Code</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInLeft">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid"
                                                                            src="assets/images/features/Real-Time-Order Management.svg"
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Real Time Order
                                                                            Management</h6>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 wow move-up  wow slideInUp">
                                                            <div
                                                                className="ht-box-images  h-100 d-flex align-items-center justify-content-center">
                                                                <div className="section-title-wrap text-center">
                                                                    <h3 className="heading">Features </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInRight">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid"
                                                                            src="assets/images/features/Payment-Processing.svg" alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Payment Processing</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInLeft">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid"
                                                                            src="assets/images/features/Expense-Management.svg" alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Expense Management</h6>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInLeft">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid"
                                                                            src="assets/images/features/Route-Planning.svg" alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Route Planning</h6>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInRight">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid" src="assets/images/features/MIS.svg" alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">MIS</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInRight">
                                                            <div className="ht-box-images style-01">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <img className="img-fulid"
                                                                            src="assets/images/features/Sales-Analytics Reports.svg" alt="" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Sales Analytics Reports</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="invento-benefit feature-large-images-wrapper section-space--ptb_100">
                                    <div className="container">

                                        <div className="row">
                                            <div className="col-lg-12  wow slideInUp">
                                                <div className="section-title-wrap text-center section-space--mb_60">
                                                    <h3 className="heading">Benefits<span className="text-color-primary"> </span></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="feature-images__six">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/Business-On-Finger-Tips.svg"
                                                                                alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Business On Finger Tips</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/Workflow-Automation.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Workflow Automation</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/Customer-Satisfaction.svg"
                                                                                alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Customer Satisfaction </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/Sales-Operations.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Easy To Manage Sales Operations
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/Real-Time-Sales-Analytics.svg"
                                                                                alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Real Time Sales Analytics</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/route-planning.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Better User Experience And
                                                                            Engagement
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/territory-management.svg"
                                                                                alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Increase Efficiency ,Productivity and Reduce
                                                                            Dispatch Timeline
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/stock-management.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Offline Working Capability
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/benefits/productivity.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Improved Sales Team Performance</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="feature-images-wrapper bg-theme-blue  section-space--ptb_100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 wow slideInUp">
                                                <div className="section-title-wrap text-center section-space--mb_60">
                                                    <h3 className="heading"> Predictive Analytics<span className="text-color-primary"> </span></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 order-2 order-lg-1 slideInLeft">
                                                <div className="resources-left-box">
                                                    <div className="resources-inner">
                                                        <h4 className="heading mb-4">Inventory Replenishment<span> </span></h4>
                                                        <p>To avoid the issues of out of stocks and overstocks, Inventory replenishment
                                                            process plays vital role for team to take necessary action to maintain your
                                                            stock levels.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2 slideInRight">
                                                <div className="resources-right-box">
                                                    <div className="resources-right-inner text-center">
                                                        <div className="resources-images animation-up-down">
                                                            <img className="img-fluid" src="assets/images/Inventory-Replenishment.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 order-1 order-lg-1 slideInLeft">
                                                <div className="resources-right-box">
                                                    <div className="resources-right-inner text-center">
                                                        <div className="resources-images animation-up-down">
                                                            <img className="img-fluid" src="assets/images/Sales-Patterns.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-2 order-lg-2 slideInRight">
                                                <div className="resources-left-box">
                                                    <div className="resources-inner">
                                                        <h4 className="heading mb-4">Sales Patterns<span>                                        </span></h4>
                                                        <p>Make data-driven decisions on top performing and underperforming
                                                            products/services. Also, find your valuable customers and understand their needs
                                                            to keep them happy.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 order-2 order-lg-1 slideInLeft">
                                                <div className="resources-left-box">
                                                    <div className="resources-inner">
                                                        <h4 className="heading mb-4">Products Sales Analysis <span> </span></h4>
                                                        <p>KPIs and Charts of overall Products Sales can help you to find out how your sales
                                                            revenue has grown.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2 slideInRight">
                                                <div className="resources-right-box">
                                                    <div className="resources-right-inner text-center">
                                                        <div className="resources-images animation-up-down">
                                                            <img className="img-fluid" src="assets/images/Products-Sales-Analysis.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="brand-logo-slider-area section-space--ptb_60 bg-gray">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title-wrap text-center section-space--mb_60">
                                                    <h3 className="heading"> Integrations<span className="text-color-primary"> </span></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="brand-logo-slider__container-area">
                                                    <div className="swiper-container brand-logo-slider__container">
                                                        <div className="brand-logo-slider__one">
                                                            <Swipe
                                                                className="brand-logo-slider__one"
                                                                onSwipeStart={this.onSwipeStart}
                                                                onSwipeMove={this.onSwipeMove}
                                                                onSwipeEnd={this.onSwipeEnd}>

                                                                <div className="swiper-slide brand-logo brand-logo-slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/logic-icon.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/logic-icon1.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="swiper-slide brand-logo brand-logo-slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/logic-icon.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/logic-icon1.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="swiper-slide brand-logo brand-logo-slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/logic-icon.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/logic-icon1.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="swiper-slide brand-logo brand-logo-slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/mycrosoft-icon.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/mycrosoft-icon1.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="swiper-slide brand-logo brand-logo-slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/sap-icon.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/sap-icon1.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/siebel-icon.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/siebel-icon1.png" className="img-fluid"
                                                                                alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                                    <a href="#">
                                                                        <div className="brand-logo__image">
                                                                            <img src="assets/images/brand/tally.png" className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="brand-logo__image-hover">
                                                                            <img src="assets/images/brand/tally1.png" className="img-fluid" alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Swipe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="feature-large-images-wrapper section-space--ptb_100 industry">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12  wow slideInUp">
                                                <div className="section-title-wrap text-center section-space--mb_60">
                                                    <h3 className="heading">Industry We Serve<span className="text-color-primary"> </span></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="feature-images__six">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Healthcare-01-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Healthcare</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Real Estate-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Real Estate
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid" src="assets/images/industry/surface1.svg"
                                                                                alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Manufacturing</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Hospitality-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Hospitality</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Insurance-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Insurance</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Retail-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Retail</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Agency-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Agency</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Textile-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Textile</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Finance-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Finance</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                                                            <div className="ht-box-images style-06">
                                                                <div className="image-box-wrap">
                                                                    <div className="box-image">
                                                                        <div className="default-image">
                                                                            <img className="img-fulid"
                                                                                src="assets/images/industry/Logistics-01.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h6 className="heading">Logistics</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-area-wrapper bg-gray">
                                <div className="footer-area section-space--ptb_80">
                                    <div className="container">
                                        <div className="row footer-widget-wrapper">
                                            <div
                                                className="col-lg-4 col-md-6 col-sm-6 footer-widget  wow slideInLeft d-flex align-items-center">
                                                <div className="footer-widget__logo mb-30">
                                                    <img src="assets/images/logo.svg" className="img-fluid" alt="" />
                                                </div>
                                            </div>


                                            <div className="col-md-4 text-center text-md-center footer-cneter  wow slideInLeft footer-widget">
                                                <ul className="footer-widget__list text-center">
                                                    <li><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.invento" className="image_btn mr-md-2 mr-2"><img className="img-fluid"
                                                        src="assets/images/icons/aeroland-button-google-play.jpg" alt="" /></a></li>
                                                    <li><a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/id1523300772" className="image_btn"><img className="img-fluid"
                                                        src="assets/images/icons/aeroland-button-app-store.jpg" alt="" /></a></li>
                                                </ul>

                                                {/* <ul className="list ht-social-networks solid-rounded-icon">

                                                    <li className="item">
                                                        <a href="https://twitter.com/" rel="noopener noreferrer" target="_blank" aria-label="Twitter"
                                                            className="social-link hint--bounce hint--top hint--primary">
                                                            <i className="fab fa-twitter link-icon"></i>
                                                        </a>
                                                    </li>
                                                    <li className="item">
                                                        <a href="https://facebook.com/" rel="noopener noreferrer" target="_blank" aria-label="Facebook"
                                                            className="social-link hint--bounce hint--top hint--primary">
                                                            <i className="fab fa-facebook-f link-icon"></i>
                                                        </a>
                                                    </li>
                                                    <li className="item">
                                                        <a href="https://instagram.com/" rel="noopener noreferrer" target="_blank" aria-label="Instagram"
                                                            className="social-link hint--bounce hint--top hint--primary">
                                                            <i className="fab fa-instagram link-icon"></i>
                                                        </a>
                                                    </li>
                                                    <li className="item">
                                                        <a href="https://linkedin.com/" rel="noopener noreferrer" target="_blank" aria-label="Linkedin"
                                                            className="social-link hint--bounce hint--top hint--primary">
                                                            <i className="fab fa-linkedin link-icon"></i>
                                                        </a>
                                                    </li>
                                                </ul> */}

                                            </div>


                                            <div className="col-md-4 text-center text-md-center footer-cneter  wow slideInLeft footer-widget">
                                                <h6 className="footer-widget__title mb-20">REACH OUT NOW!</h6>
                                                <ul className="footer-widget__list">
                                                    <li><a href="tel:123344556" className="hover-style-link text-black font-weight--bold">(+91)
                                                        7698 855 855</a></li>
                                                </ul>
                                                <div className="contact-us-button mt-20">
                                                    <a className="ht-btn ht-btn-md" href="true">Request a Demo</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-copyright-area section-space--pb_30  wow slideInUp">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 text-center text-md-center">
                                                <span className="copyright-text">&copy; 2023 Invento <a href='true' onClick={e => e.preventDefault()}>All Rights
                                                    Reserved.</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a className="scroll-top" id="scroll-top" href="true">
                            <i className="arrow-top fal fa-long-arrow-up"></i>
                            <i className="arrow-bottom fal fa-long-arrow-up"></i>
                        </a>

                    </div>
                </Fragment>
            )
        }
    }
}


export default LandingPage;