import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { routehelp } from "../../Routehelper";
import { brandmanagecreate, brandsingle, brandmanageupdate } from "../../actions";
import { apiUrl, uploadUrl } from "../../config";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import { errorRender, successRedriect, getValue, validate, inputCheck } from "../../helpers/functions";
import toastr from "reactjs-toastr";
import Translate from '../../libs/translation';
// import SearchableDropDown from '../../components/searchableDropDown';

const Inputfield = lazy(() => import('../../components/inputfields'));
const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const ErrorView = lazy(() => import('../../components/errorView'));
const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
const Loader = lazy(() => import('../../components/loader'));
const InputNumberField = lazy(() => import('../../components/inputnumberfields'));
class Brands_manage_add extends Component {
  state = {
    pageType: 'add',
    itemId: '',
    name: "",
    code: "",
    description: "",
    multiplier: 1,
    uploadPercentage: 0,
    logo: {},
    serverError: ["brand_create_res", "brand_update", "brand_single"],
    forRedirect: [
      { name: "brand_create_res", pageName: 'Brand', pageUrl: 'Brandmanagement', action: "Added" },
      { name: "brand_update", pageName: 'Brand', pageUrl: 'Brandmanagement', action: "Updated" }
    ],
    setFields: [
      { name: "name", stateName: "name", type: "text" },
      { name: "code", stateName: "code", type: "text" },
      { name: "description", stateName: "description", type: "text" },
      { name: "logo", stateName: "logo", type: "text" },
      { name: "multiplier", stateName: "multiplier", type: "text" },
      { name: "series", stateName: "series", type: "text" }
    ],
    nameError: "",
    codeError: "",
    descriptionError: "",
    multiplierError: "",
    isLoading: false,
    getSingleLoader: false,
    series: [],
    seriesName: "",
    seriesError: ""
  }

  componentDidMount() {
    let id = this.props.match.params.item_id;
    if (id) {
      this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
      this.props.brandsingle(id);
    }
  }

  componentWillUnmount() {
    this.setState({});
  }

  handleChange = (name, e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);

    if (e.target.name && e.target.name === 'multiplier' && e.target.value === '0') {
      // console.log('called');
      alert('you can not set multiplier value to 0. Please change the value of multiplier');
      this.setState({
        multiplier: 1
      });
      return true;
    }

    let isValid = inputCheck(name, e.target.value);

    if (isValid) {
      this.setState({ [name]: e.target.value });
    }

    if (e.target.name === "seriesName") {
      this.setState({
        seriesError: ""
      });
    }

    // const re = /([^\s]+)/;
    // if (e.target.value === "" || re.test(e.target.value)) {
    //   this.setState({ [name]: e.target.value });
    // }

  }

  onChange = (e) => {
    // console.log(e.target.name)
    const re = /([^\s]+)/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
    // this.setState({ [e.target.name]: e.target.value })
  };

  onsubmit = (e) => {
    // console.log(e);
    let validationFields = [
      { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
      { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" },
      { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "descriptionError" },
      { name: 'multiplier', label: "Multiplier", value: this.state.multiplier, hasError: false, isRequired: true, isNumber: false, onlyChar: false, min: 1, type: "text", message: "", errName: "multiplierError", isDecimal: true }
    ];
    // console.log(this.state);
    let data = {
      name: this.state.name,
      code: this.state.code,
      description: this.state.description,
      logo: this.state.logo,
      isActive: true,
      multiplier: this.state.multiplier,
      series: this.state.series
    };
    // console.log(data);
    e.preventDefault();
    let { newArr, valid } = validate(validationFields);
    newArr.map((el) => this.setState({ [el.errName]: el.message }));
    if (valid) {
      this.setState({ isLoading: true });
      if (this.state.pageType === "update") {
        this.props.brandmanageupdate(data, this.state.itemId);
      } else {
        this.props.brandmanagecreate(data);
      }
      validationFields = null;
      newArr = null;
    }
  }

  uploadFile = ({ target: { files } }) => {

    if (files && files.length > 0 && files[0].size && files[0].size > 20971520) //20mb
    {
      toastr.error(Translate.translate("", 'error.message.image_size'), 'Error', {
        displayDuration: 5000
      });
      files = [];
      return true;
    }


    let data = new FormData();
    data.append("file", files[0]);
    const token = localStorage.getItem("token");
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        if (percent < 100) {
          this.setState({ uploadPercentage: percent });
        }
      },
    };
    axios.defaults.headers = { "x-access-token": token };
    axios.post(apiUrl + "/api/brands/upload", data, options).then((res) => {
      this.setState({ logo: res.data.item.logo, uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 });
        }, 1000);
      });
    });
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
      this.state.serverError.forEach((el) => errorRender(nextProps, el));
      this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
      if (nextProps.brand_single && nextProps.brand_single.success) {
        nextProps.brand_single.success = null;
        this.setState({ getSingleLoader: false });
        if (nextProps.brand_single.item) {
          this.state.setFields.forEach((value) => {
            if (value.type === "text") {
              this.setState({ [value.stateName]: getValue(nextProps.brand_single.item, value.name) })
            }
          })
        }
      }
    }
    return true;
  }

  addSeriesName = () => {
    // console.log("name", this.state.seriesName);
    let name = this.state.seriesName;
    if (!this.state.seriesName) {
      this.setState({
        seriesError: "Series Value is required"
      });
      return true;
    } else {
      let isAlready = false;
      this.state.series.forEach((ele) => {
        // console.log(ele.value.toUpperCase(), ele.value.toLowerCase(), name);
        if ((ele.value.toUpperCase() === name) || (ele.value.toLowerCase() === name)) {
          isAlready = true;
        }
      });
      if (isAlready) {
        this.setState({
          seriesError: "Series is already present"
        });
        return true;
      }
    }

    let seriesList = [];
    if (this.state.seriesName) {
      seriesList.push({ label: this.state.seriesName, value: this.state.seriesName });
    }

    this.setState({
      series: [...this.state.series, ...seriesList],
      seriesError: ""
    }, () => {
      this.setState({
        seriesName: ""
      });
    });

  }

  removeSeries = (index) => {
    let removeSeriesFromList = this.state.series || [];
    removeSeriesFromList.forEach((ele, i) => {
      if (index === i) {
        removeSeriesFromList.splice(i, 1);
      }
    });
    this.setState({
      series: removeSeriesFromList
    });
  }

  render() {
    // console.log(this.state);
    const { uploadPercentage, pageType, isLoading, getSingleLoader } = this.state;
    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                  <div className="card-title">{pageType === "update" ? "Update Brands" : "Add Brands"}</div>
                </div>
                {getSingleLoader ? <Loader /> :
                  <div className="card-body">
                    <div className="row">

                      <div className="form-group required col-md-4">
                        <Inputfield
                          handleChange={this.handleChange}
                          type="text"
                          name="name"
                          value={this.state.name}
                          className="form-control"
                          id="name"
                          maxlength="100"
                          ariadescribedby="codeHelp"
                          placeholder="Name"
                          errMessage={this.state.nameError}
                        />
                      </div>

                      <div className="form-group required col-md-4">
                        <Inputfield
                          handleChange={this.handleChange}
                          type="text"
                          name="code"
                          maxlength="10"
                          value={this.state.code}
                          className="form-control"
                          id="code"
                          aria-describedby="codeHelp"
                          placeholder="Code"
                          errMessage={this.state.codeError}
                        />
                      </div>

                      <div className="form-group required col-md-4">
                        <InputNumberField
                          handleChange={this.handleChange}
                          type="number"
                          name="multiplier"
                          min="1"
                          value={this.state.multiplier}
                          className="form-control"
                          id="multiplier"
                          aria-describedby="codeHelp"
                          placeholder="Multiplier"
                          errMessage={this.state.multiplierError}
                        />
                      </div>

                      <div className="form-group required col-md-4">
                        <TextAreaInputField
                          onChange={this.onChange}
                          type="text"
                          name="description"
                          maxLength="5000"
                          value={this.state.description}
                          className="form-control"
                          placeholder="Description"
                          errMessage={this.state.descriptionError}
                        />
                      </div>

                      <div className="col-md-4">
                        {this.state.logo &&
                          this.state.logo.medium !== undefined ? (
                          <div className="upload-div">
                            <div className="media-content mt-4">
                            </div>
                            <div className="document-img">
                              <div
                                onClick={() => {
                                  this.setState({ logo: {} });
                                }}
                                className="close-btn"
                              >
                                {" "}
                                <i className="fa fa-times"></i>
                              </div>
                              <img
                                alt="img"
                                className="images"
                                src={uploadUrl + this.state.logo.medium}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="form-group  upload-div">
                            <label
                              htmlFor="exampleInputName1"
                              className="control-label"
                            >
                              Image
                            </label>
                            <small className="text-muted d-flex">Image extension such as .jpg, .jpeg, .png allow only and size limit is 20MB.</small>
                            <div className="upload-img">
                              <input
                                maxLength="5"
                                onChange={this.uploadFile}
                                // value={this.state.code}
                                name="logo"
                                type="file"
                                className="form-control"
                                accept=".jpg, .jpeg, .png"
                              />
                            </div>
                            <ErrorView message={this.state.logoError} />
                            <div className="progressbar-image mt-3">
                              {uploadPercentage > 0 && (
                                <ProgressBar
                                  now={uploadPercentage}
                                  active
                                  label={`${uploadPercentage}%`}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row series">
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label for="value" className="mb-0 d-block mb-1">Series</label>
                          <div className="d-flex align-items-center flex-wrap">
                            <Inputfield type="text" noLabel={true} errMessage={this.state.seriesError} className="form-control w-auto d-inline-block mr-2 min-180" placeholder="Series Name" id="seriesName" name="seriesName" value={this.state.seriesName} maxlength="30" handleChange={this.handleChange} />
                            <button className="btn btn-primary" type="button" onClick={this.addSeriesName}>Add Series</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-4 series-wrapper">
                          {
                            this.state.series && this.state.series.length > 0 ?
                              this.state.series.map((ele, i) =>
                                <div className="col-12 col-md-3" key={i}>
                                  <div className="series-card">
                                    <i className="fa fa-times" onClick={(e) => this.removeSeries(i)}></i>
                                    <span className="value-box">
                                      {ele.label}
                                    </span>
                                  </div>
                                </div>
                              ) : <p className="text-center">No Series Added</p>
                          }
                        </div>
                      </div>
                    </div>

                  </div>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-4 text-center">
              <Buttons
                onclick={this.onsubmit}
                type="submit"
                className="btn btn-primary mr-1"
                disable={isLoading || getSingleLoader} loading={isLoading}
                name={pageType === "update" ? "Update" : "Save"}
              />
              <Links
                to={routehelp.Brandmanagement}
                className="btn btn-outline-dark"
                name="Cancel"
              />
            </div>
          </div>

        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  // console.log(state.brand_single);
  return {
    brand_create_res: state.brand_create_res,
    brand_single: state.brand_single,
    brand_update: state.brand_update
  };
}

const mapDispatchToProps = (dispatch) => ({
  brandsingle: (data) => dispatch(brandsingle(data)),
  brandmanagecreate: (data) => dispatch(brandmanagecreate(data)),
  brandmanageupdate: (data, id) => dispatch(brandmanageupdate(data, id)),
});

Brands_manage_add = connect(
  mapStateToProps,
  mapDispatchToProps
)(Brands_manage_add);

export default Brands_manage_add;