import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { user, SrAsmsingle, srAsmUserAssignUpdate } from '../../actions';
import Select from 'react-select';
import toastr from 'reactjs-toastr';
import { errorRender, successRedriect, singleArrFunc } from '../../helpers/functions';

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Loader = lazy(() => import('../../components/loader'));
class SrAsmUsersAssignUpdate extends Component {
    state = {
        agent: '',
        userAgent: [],
        agentvalue: [],
        userAsm: [],
        asmvalue: [],
        userAsm2: [],
        asm2value: [],
        serverError: ["srAsmUserAssign_update", "user_response_search", "srAsm_single"],
        isLoading: false,
        getSingleLoader: false
    }


    componentDidMount() {
        let id = this.props.match.params.item_id;
        this.props.SrAsmsingle(id);
        let data = {
            type: ["AG", "ASM", "ASM2"]
        };
        data.sortBy = "name";
        data.sort = "asc";
        this.props.user(data);
        this.setState({ getSingleLoader: true });
    }

    componentWillUnmount() {
        this.setState(this.state);
    }

    ondropdownChange = (e, t) => this.setState({ [e]: t });

    onsubmit = (e) => {
        if ((this.state.agentvalue && this.state.agentvalue.length !== 0) || (this.state.asmvalue && this.state.asmvalue.length !== 0) || (this.state.asm2value && this.state.asm2value.length !== 0)) {
            let id = this.props.match.params.item_id;
            let data = {
                srAsm: this.state.agent.value,
                agent: singleArrFunc(this.state.agentvalue, "value"),
                asm: singleArrFunc(this.state.asmvalue, "value"),
                asm2: singleArrFunc(this.state.asm2value, "value"),
            }
            e.preventDefault();
            this.setState({ isLoading: true })
            this.props.srAsmUserAssignUpdate(data, id);
        } else {
            toastr.error('Please select minimum one user from Agent, ASM or ASM2', 'error');
        }


    }

    multiValueSelect = (value, name) => this.setState({ [name]: value })


    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el))
            successRedriect(nextProps, "srAsmUserAssign_update", "Sr. ASM", this.props, "SrAsmAssignment", "Updated");
            if (nextProps.user_response_search !== undefined && nextProps.user_response_search.success === true) {

                nextProps.user_response_search.list.forEach((item, index) => {
                    if (item.type === "AG") {
                        this.state.userAgent.push({
                            label: item.name,
                            value: item._id
                        })
                    } else if (item.type === "ASM") {
                        this.state.userAsm.push({
                            label: item.name,
                            value: item._id
                        })
                    } else if (item.type === "ASM2") {
                        this.state.userAsm2.push({
                            label: item.name,
                            value: item._id
                        })
                    }
                })
                nextProps.user_response_search.success = null;
            }

            if (nextProps.srAsm_single !== undefined && nextProps.srAsm_single.success === true) {
                this.setState({ getSingleLoader: false });
                let agent = [];
                let asm = [];
                let asm2 = [];
                nextProps.srAsm_single.agent.forEach((item) => {
                    agent.push({
                        label: item.name,
                        value: item._id
                    })
                })
                nextProps.srAsm_single.asm.forEach((item) => {
                    asm.push({
                        label: item.name,
                        value: item._id
                    })
                })
                nextProps.srAsm_single.asm2.forEach((item) => {
                    asm2.push({
                        label: item.name,
                        value: item._id
                    })
                });
                this.setState({
                    agentvalue: agent, asmvalue: asm, asm2value: asm2,
                    agent: {
                        value: nextProps.srAsm_single.srAsm._id,
                        label: nextProps.srAsm_single.srAsm.name
                    }
                });
                agent = null;
                asm = null;
                asm2 = null;
                nextProps.srAsm_single.success = null
            }
        }
        return true;
    }

    render() {
        const { isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Update Sr. Asm Users Assignment
                                    </div>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Userdropdown
                                                    isDisabled={true}
                                                    ondropdownChange={this.ondropdownChange}
                                                    name="agent"
                                                    value={this.state.agent}
                                                    className="form-control-square"
                                                    type="SASM"
                                                // clear={true}
                                                />
                                            </div>
                                            <div className=" form-group  col-12 col-lg-4">
                                                <label className="control-label">Agent</label>
                                                <Select closeMenuOnSelect={false} classNamePrefix="agent" placeholder='Select Agent' value={this.state.agentvalue} onChange={(e) => this.multiValueSelect(e, "agentvalue")} options={this.state.userAgent} isMulti={true} isSearchable={true} instanceId='agent-id' />
                                            </div>
                                            <div className=" form-group col-12 col-lg-4">
                                                <label className="control-label">Area Sales Manager</label>
                                                <Select closeMenuOnSelect={false} placeholder='Select ASM' classNamePrefix="agent_sales_manager" onChange={(e) => this.multiValueSelect(e, "asmvalue")} options={this.state.userAsm} isMulti={true} value={this.state.asmvalue} isSearchable={true} instanceId='area_agent_sales-id' />
                                            </div>
                                            <div className=" form-group col-12 col-lg-4">
                                                <label className="control-label">Area Sales Manager 2</label>
                                                <Select closeMenuOnSelect={false} placeholder='Select ASM 2' classNamePrefix="agent_sales_manager_2" name="asm2" onChange={(e) => this.multiValueSelect(e, "asm2value")} options={this.state.userAsm2} isMulti={true} value={this.state.asm2value} isSearchable={true} instanceId='area_agent_sales_2-id' />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name="Update" />
                            <Links to={routehelp.SrAsmAssignment} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        srAsm_single: state.srAsm_single,
        user_response_search: state.user_response_search,
        srasmassigncreate: state.srasmassigncreate,
        srAsmUserAssign_update: state.srAsmUserAssign_update

    };
}


const mapDispatchToProps = dispatch => ({
    user: data => dispatch(user(data)),
    SrAsmsingle: data => dispatch(SrAsmsingle(data)),
    srAsmUserAssignUpdate: (data, id) => dispatch(srAsmUserAssignUpdate(data, id))
});

SrAsmUsersAssignUpdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(SrAsmUsersAssignUpdate);

export default SrAsmUsersAssignUpdate;