import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { usermanagecreate, state_search, district_search, profilesingle, city_search, customermanagementlist, usersingle, usermanageupdate } from '../../actions';
import { errorRender, successRedriect, validate, getValue, inputCheck } from '../../helpers/functions';

const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
const SelectInputField = lazy(() => import('../../components/selectInputField'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));
const CityDropdown = lazy(() => import('../../components/address/citydropdown'))
const StateDropdown = lazy(() => import('../../components/address/stateDropdown'));
const CountryDropdown = lazy(() => import('../../components/address/countrydropdown'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const Loader = lazy(() => import('../../components/loader'));

class User_manage_add extends Component {
    state = {
        pageType: 'add',
        commissionFlag: null,
        itemId: '',
        name: '',
        code: '',
        email: '',
        phone: '',
        password: '',
        country: null,
        state: null,
        city: null,
        wholesaler: null,
        district: "",
        line1: "",
        postalCode: '',
        type: "",
        company: "",
        companyDetails: "",
        serverErrors: ["user_create_res", "user_update", "user_single"],
        forRedirect: [
            { name: "user_create_res", pageName: 'User', pageUrl: 'usermanagement', action: "Added" },
            { name: "user_update", pageName: 'User', pageUrl: 'usermanagement', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "code", stateName: "code", type: "text" },
            { name: "email", stateName: "email", type: "text" },
            { name: "phone", stateName: "phone", type: "text" },
            { name: "password", stateName: "password", type: "text" },
            { name: "type", stateName: "type", type: "text" },
            { name: "country", stateName: "country", type: "select" },
            { name: "state", stateName: "state", type: "select" },
            { name: "city", stateName: "city", type: "select" },
            { name: "wholesalerId", stateName: "wholesaler", type: "select" },
            { name: "address.line1", stateName: "line1", type: "text" },
            { name: "address.postalCode", stateName: "postalCode", type: "text" },
            { name: "commission.asParentCommission", stateName: "asParentCommission", type: "text" },
            { name: "commission.asChildCommission", stateName: "asChildCommission", type: "text" },
            { name: "commissionType", stateName: "commissionType", type: "text" }
        ],
        typeList: [
            { value: "", label: "Select a Type" },
            { value: "AG", label: "Agent" },
            { value: "ASM", label: "Area Sales Manager" },
            { value: "ASM2", label: "Area Sales Manager 2" },
            { value: "SASM", label: "Sr. AREA SALES MANAGER" },
            { value: "WHS", label: "WHOLESALER" }
        ],
        filter: { customerType: 'wholesaler', limit: 5000 },
        nameError: '',
        emailError: '',
        phoneError: '',
        passwrodError: '',
        countryError: '',
        stateError: '',
        districtError: '',
        cityError: '',
        line1Error: '',
        postalCodeError: '',
        codeError: "",
        typeError: '',
        wholesalerError: '',
        isLoading: false,
        getSingleLoader: false,
        asParentCommission: 0,
        asChildCommission: 0,
        commissionTypeList: [
            { value: "percentage", label: "Percentage" },
            { value: "flat", label: "Flat" }
        ],
        commissionType: "percentage"
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.usersingle(id);
        } else {
            this.props.profilesingle({});
        }

        this.setTheTypeList();
        this.setCommissionFlag();
    }

    componentWillUnmount() {
        this.setState(this.state);
    }

    setTheTypeList() {
        let companyID = localStorage.getItem('companyId');
        // console.log(companyID);

        // ayanika company only
        if (["625650a4110bbc574c145e78", "625549fc78121a6c9d14040a"].indexOf(companyID) > -1) {
            this.setState({
                typeList: [
                    { value: "", label: "Select a Type" },
                    { value: "ASM", label: "Partner" },
                    { value: "AG", label: "Franchisee" }
                ]
            });
        };

    };

    setCommissionFlag() {
        let commissionFlag = localStorage.getItem('commissionFlag');
        if (commissionFlag === "range") {
            this.setState({
                commissionFlag: false
            });
        } else if (commissionFlag === "user") {
            this.setState({
                commissionFlag: true
            });
        }
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value, "user");
        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "country") {
                if (this.state.country !== null) {
                    if (val.value !== this.state.country.value) {
                        this.setState({ state: null, city: null });
                        let data = {
                            country: val.value
                        };
                        this.props.state_search(data)
                    }
                } else {
                    let data = {
                        country: val.value
                    };
                    this.props.state_search(data)
                }
            }
            if (name === "state") {
                if (this.state.state !== null) {
                    if (val.value !== this.state.state.value) {
                        this.setState({ city: null });
                        let data = {
                            state: val.value
                        };
                        this.props.city_search(data);
                    }
                } else {
                    let data = {
                        state: val.value
                    };
                    this.props.city_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    onsubmit = (e) => {
        // console.log(this.state);
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 100, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: (this.state.code || ""), hasError: false, isRequired: this.state.companyDetails.isCodeEditable, isNumber: false, onlyChar: false, maxLength: 10, type: "text", message: "", errName: "codeError" },
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "email", message: "", errName: "emailError" },
            { name: 'phone', label: "Mobile", value: this.state.phone, hasError: false, isRequired: true, isNumber: true, onlyChar: false, minLength: 10, maxLength: 10, type: "text", message: "", errName: "phoneError" },
            { name: 'password', label: "Password", value: this.state.password, hasError: false, isRequired: this.state.pageType === "update" ? false : true, isNumber: false, maxLength: 16, minLength: 8, onlyChar: false, type: "password", message: "", errName: "passwrodError" },
            { name: 'type', label: "Type", value: this.state.type, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "typeError" },
            { name: 'wholesaler', label: "Wholesaler", value: this.state.wholesaler, hasError: false, isRequired: this.state.type === "WHS" ? false : false, isNumber: false, onlyChar: false, type: "select", message: "", errName: "wholesalerError" },
            { name: 'country', label: "Country", value: this.state.country, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "countryError" },
            { name: 'state', label: "State", value: this.state.state, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "stateError" },
            { name: 'city', label: "City", value: this.state.city, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "cityError" },
            { name: 'line1', label: "Address", value: this.state.line1, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "line1Error" },
            { name: 'postalCode', label: "Zip Code", value: this.state.postalCode, hasError: false, isRequired: true, isNumber: false, onlyChar: false, minLength: 8, maxLength: 8, type: "text", message: "", errName: "postalCodeError" }
        ];
        let data = {
            name: this.state.name,
            code: this.state.code,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            company: this.state.company,
            wholesalerId: this.state.type === "WHS" ? this.state.wholesaler ? this.state.wholesaler.value : undefined : undefined,
            address: {
                country: this.state.country && this.state.country.value,
                state: this.state.state && this.state.state.value,
                city: this.state.city && this.state.city.value,
                // district: this.state.district,
                line1: this.state.line1,
                postalCode: this.state.postalCode
            },
            type: this.state.type,
            commission: {
                asParentCommission: this.state.asParentCommission,
                asChildCommission: this.state.asChildCommission
            },
            commissionType: this.state.commissionType
        };
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.usermanageupdate(data, this.state.itemId);
            } else {
                this.props.usermanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            if (nextProps.user_single && nextProps.user_single.success === true) {
                nextProps.user_single.success = null;
                this.setState({ getSingleLoader: false });
                if (nextProps.user_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.user_single.item, value.name) })
                        } else if (value.type === "select" && nextProps.user_single[value.name]) {
                            let obj = {
                                label: nextProps.user_single[value.name].name,
                                value: nextProps.user_single[value.name]._id
                            }
                            this.setState({ [value.stateName]: obj });
                            this.ondropdownChange(obj, value.name);
                            obj = null
                        }

                        this.setState({
                            company: nextProps.user_single.item.company ? nextProps.user_single.item.company._id : null,
                            companyDetails: nextProps.user_single.item.company ? nextProps.user_single.item.company : null
                        });

                    });
                    nextProps.user_single.success = null;
                }

            }

            if (nextProps.profile_single && nextProps.profile_single.success === true && nextProps.profile_single.item) {
                nextProps.profile_single.success = null;
                this.setState({
                    company: nextProps.profile_single.item._id,
                    companyDetails: {
                        _id: nextProps.profile_single.item._id,
                        name: nextProps.profile_single.item.name,
                        isCodeEditable: nextProps.profile_single.isCodeEditable
                    }
                });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {
                                            pageType === "update" ? "Update User" : "Add User"
                                        }
                                    </div>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="nameHelp" placeholder="Name" />
                                                </div>

                                                <div className={this.state.companyDetails.isCodeEditable ? "form-group required col-12 col-lg-4" : "form-group  col-12 col-lg-4"}>
                                                    <Inputfield
                                                        errMessage={this.state.codeError}
                                                        handleChange={this.handleChange}
                                                        disabled={this.state.companyDetails.isCodeEditable === false ? true : false}
                                                        maxlength="10"
                                                        type="text"
                                                        name="code"
                                                        value={this.state.code}
                                                        className="form-control"
                                                        id="code"
                                                        ariadescribedby="codeHelp"
                                                        placeholder="Code" />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.emailError} handleChange={this.handleChange} maxlength="100" type="text" name="email" value={this.state.email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.phoneError} handleChange={this.handleChange} maxlength="10" type="text" name="phone" value={this.state.phone} className="form-control" id="phone" placeholder="Mobile" />
                                                </div>
                                                {/* {
                                                pageType === "add" ? */}
                                                <div className={pageType === "add" ? "form-group required col-12 col-lg-4" : "form-group  col-12 col-lg-4"}>
                                                    <Inputfield errMessage={this.state.passwrodError} handleChange={this.handleChange} type="password" name="password" value={this.state.password} className="form-control" placeholder="Password" maxlength="16" minlength="8" arialabel="Amount (to the nearest dollar)" />
                                                    <small className="text-info d-flex">Password must contain atleast one uppercase, one lowercase, one digit and one special character. Length of password must be between 8 to 16 charcter long.</small>
                                                </div>
                                                {/* : null */}
                                                {/* } */}

                                                <div className="form-group required col-12 col-lg-4">
                                                    <SelectInputField label="Type" errMessage={this.state.typeError} onChange={this.onChange} value={this.state.type} name="type" className="form-control" options={this.state.typeList} />
                                                </div>
                                                {this.state.type === "WHS" ?
                                                    <div className="form-group col-12 col-lg-4">
                                                        <CustomerDropDown
                                                            lable="Wholesaler"
                                                            value={this.state.wholesaler}
                                                            ondropdownChange={(e) => this.ondropdownChange(e, "wholesaler")}
                                                            placeholder="Search Wholesaler"
                                                            filterBy={this.state.filter}
                                                            errMessage={this.state.wholesalerError}
                                                        />
                                                    </div> : null}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        getSingleLoader ? null :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Add Address
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group required col-12 col-lg-4">
                                                    <CountryDropdown
                                                        value={this.state.country}
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "country")}
                                                        placeholder="Search Country"
                                                        errMessage={this.state.countryError}
                                                    />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <StateDropdown
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "state")}
                                                        value={this.state.state}
                                                        dontPreload={pageType === "update"}
                                                        placeholder="Search State"
                                                        errMessage={this.state.stateError}
                                                        country={this.state.country}
                                                    />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <CityDropdown
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "city")}
                                                        value={this.state.city}
                                                        dontPreload={pageType === "update"}
                                                        placeholder="Search City"
                                                        state={this.state.state}
                                                        country={this.state.country}
                                                        errMessage={this.state.cityError}
                                                    />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <TextAreaInputField errMessage={this.state.line1Error} onChange={this.onChange} name="line1" value={this.state.line1} className="form-control" rows="2" maxLength="5000" placeholder="Address Line-1" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.postalCodeError} handleChange={this.handleChange} type="text" name="postalCode" maxlength="8" value={this.state.postalCode} className="form-control" id="postalCode" placeholder="Zip Code" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                    {
                        this.state.commissionFlag ?
                            getSingleLoader ? null :
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-shadow mb-4">

                                            <div className="card-header">
                                                <div className="card-title" id="update_address">
                                                    Comission Details
                                                </div>
                                            </div>
                                            {/* {console.log(this.state.commission)} */}
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-12 col-lg-4">
                                                        <SelectInputField label="Commission Type" onChange={this.onChange} value={this.state.commissionType} name="commissionType" className="form-control" options={this.state.commissionTypeList} />
                                                    </div>

                                                    <div className="form-group col-12 col-lg-4">
                                                        <Inputfield type="number" name="asParentCommission" handleChange={this.handleChange} maxlength="20" value={this.state.asParentCommission} className="form-control" id="asParentCommission" placeholder="As Parent Commission" />
                                                    </div>

                                                    <div className="form-group col-12 col-lg-4">
                                                        <Inputfield type="number" name="asChildCommission" handleChange={this.handleChange} maxlength="20" value={this.state.asChildCommission} className="form-control" id="asChildCommission" placeholder="As Child Commission" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            :
                            null
                    }

                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.usermanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile_single: state.profile_single,
        user_create_res: state.user_create_res,
        customermanagement_res: state.customermanagement_res,
        user_single: state.user_single,
        user_update: state.user_update,
    };
}


const mapDispatchToProps = dispatch => ({
    profilesingle: data => dispatch(profilesingle(data)),
    usersingle: data => dispatch(usersingle(data)),
    usermanagecreate: data => dispatch(usermanagecreate(data)),
    state_search: data => dispatch(state_search(data)),
    district_search: data => dispatch(district_search(data)),
    city_search: data => dispatch(city_search(data)),
    customermanagementlist: data => dispatch(customermanagementlist(data)),
    usermanageupdate: (data, id) => dispatch(usermanageupdate(data, id)),
});

User_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(User_manage_add);

export default User_manage_add;