import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { inactiveUserslist, inactiveUsersExport } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, loaderUpdate, fileDownload } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import { Link } from 'react-router-dom';
import ButtonWithItag from '../../components/buttonwithTagi';

// const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Pagination = lazy(() => import('../../components/pagination/index'));
// const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const Entries = lazy(() => import('../../components/entries/entires'));
// const SearchInput = lazy(() => import('../../components/searchInput'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Loader = lazy(() => import('../../components/loader'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class InactiveUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            successRequest: [],
            serverErrors: ["inactive_users_res", 'inactiveUsersExport_res', "useractive_update"],
            isLoading: false,
            isFilter: false,
            isExportLoading: false,
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'email',
                    label: "Email",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'phone',
                    label: "Mobile",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'type',
                    label: "User Type",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'isActive',
                //     label: "Status",
                //     type: "checkbox",
                //     sortable: false,
                //     isShow: true,
                //     function: this.checkhandler
                // },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'actions',
                //     label: "Actions",
                //     type: "button",
                //     sortable: false,
                //     isShow: true,
                //     allactions: [{
                //         name: "edit",
                //         title: "Edit",
                //         classNameI: "ti-pencil",
                //         className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                //         function: redirect
                //     }, {
                //         name: "delete",
                //         title: "Delete",
                //         classNameI: "ti-trash",
                //         className: "btn btn-link text-danger action-btn p-0",
                //         function: this.deleteRow
                //     }]
                // }
            ]
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult()
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    loadResult = () => {
        // console.log(localStorage.getItem('filter-date'));
        let filterData = JSON.parse(localStorage.getItem('filter-date')) || {};

        if (this.state.isFilter === true) {
            currentPage = 1;
        }

        let data = {};

        if (filterData && filterData.filter && filterData.filter.createdAt && filterData.filter.createdAt.from) {
            filterData.filter.createdAt.from = new Date(filterData.filter.createdAt.from);
        }

        if (filterData && filterData.filter && filterData.filter.createdAt && filterData.filter.createdAt.to) {
            filterData.filter.createdAt.to = new Date(filterData.filter.createdAt.to);
        }

        data = { ...data, ...filterData };
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        // console.log(data);
        this.props.inactiveUserslist(data);
        this.setState({ isLoading: true, isFilter: false })
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    // resetfilter = () => {
    //     let data = {}
    //     data.filter = { name: undefined, mobile: undefined, type: undefined }
    //     data.sortBy = sortBy;
    //     data.sort = sort;
    //     data.skip = (currentPage - 1) * itemsPerPage;
    //     data.limit = itemsPerPage;
    //     this.props.inactiveUserslist(data);
    //     this.setState({ isFilter: false, isLoading: true, name: '', mobile: '', usertype: '' })
    // }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }


    // checkhandler = (e) => {
    //     this.setState({ [e.target.name]: e.target.checked });
    //     let id = e.target.name;
    //     let data = {
    //         isActive: e.target.checked
    //     }
    //     this.props.useractive(data, id)
    // }

    // deleteRow = (e, item) => {
    //     if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
    //         this.props.delete_user(item._id);
    //         e.preventDefault();
    //     }
    // }

    export = () => {
        this.setState({
            isExportLoading: true
        });
        let filterData = JSON.parse(localStorage.getItem('filter-date')).filter || {};

        let data = {};
        if (filterData && filterData.filter && filterData.filter.createdAt && filterData.filter.createdAt.from) {
            filterData.filter.createdAt.from = new Date(filterData.filter.createdAt.from);
        }
        if (filterData && filterData.filter && filterData.filter.createdAt && filterData.filter.createdAt.to) {
            filterData.filter.createdAt.to = new Date(filterData.filter.createdAt.to);
        }
        data.filter = filterData;
        this.props.inactiveUsersExport(data);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "inactive_users_res") });
            }
            this.state.serverErrors.forEach((el) =>
                errorRender(nextProps, el, el === "useractive_update"
                    ? this.loadResult : undefined)
            );

            if (nextProps && nextProps.inactiveUsersExport_res && nextProps.inactiveUsersExport_res !== undefined) {
                this.setState({ isExportLoading: false });
                fileDownload(nextProps, "inactiveUsersExport_res", "fileName")
            }

            this.state.successRequest.forEach((el) =>
                successRender(
                    nextProps,
                    el.name, this.loadResult,
                    el.msg
                ));
        }
        return true;
    }


    // onChangeforDebounce = ({ target: { value } }) => {
    //     if (!/^\s/.test(value)) {
    //         this.setState({ name: value }, () => this.callforDebounce(value))
    //     }
    // }

    callforDebounce = () => this.loadResult();

    render() {
        const { inactive_users_res } = this.props;
        // const { name } = this.state;
        const totResult = inactive_users_res !== undefined ? inactive_users_res.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="mr-3 mt-2 text-right">
                                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                </div>
                                <div className="card-header">
                                    <div className="card-title">
                                        Inactive Users
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" type="button" onclick={this.export} disabled={this.state.isExportLoading} className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Download all" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    {totResult === undefined ? <p className="text-center">No Data Found</p> :
                                                        this.state.isLoading ? <Loader /> : inactive_users_res !== undefined && inactive_users_res.count === 0 ?
                                                            <p className="text-center">No Data Found</p> :
                                                            inactive_users_res && inactive_users_res.count > 0 ?
                                                                <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                    sortByvalue={sortBy} sortvalue={sort} data={inactive_users_res}
                                                                    props={this.props} />
                                                                : "Something Went Wrong"}
                                                </div>
                                            </div>
                                        </div>
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title">Filter</h6>
                        <button type="button" className="close" onClick={this.toggleFilter} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input maxlength="20" type="text" name="name" value={this.state.name} onChange={this.onChange} className="form-control" id="name" placeholder="Name" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Mobile</label>
                                <div className="col-sm-9">
                                    <input maxLength="10" type="text" name="mobile" value={this.state.mobile} onChange={this.onChange} className="form-control" id="Mobile" placeholder="Mobile" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">User Type</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="usertype" value={this.state.usertype} onChange={this.onChange} id="usertype">
                                        <option value="">Select a Type</option>
                                        <option value="AG">AGENT</option>
                                        <option value="ASM">AREA SALES MANAGER</option>
                                        <option value="ASM2">AREA SALES MANAGER 2</option>
                                        <option value="SASM">Sr. AREA SALES MANAGER</option>
                                        <option value="WHS"> WHOLESALER</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div> */}
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        inactive_users_res: state.inactive_users_res,
        inactiveUsersExport_res: state.inactiveUsersExport_res,
    };
}

const mapDispatchToProps = dispatch => ({
    inactiveUserslist: data => dispatch(inactiveUserslist(data)),
    inactiveUsersExport: data => dispatch(inactiveUsersExport(data))
});

InactiveUsers = connect(
    mapStateToProps,
    mapDispatchToProps
)(InactiveUsers);

export default InactiveUsers;