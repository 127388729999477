import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatch_user_list } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../../components/errorView';

let DispatchUserDropdown = ({ ondropdownChange, value, placeholder, errMessage }) => {
    const dispatch = useDispatch();
    let dispatch_user_list_res = useSelector(state => state.dispatch_user_list_res)
    let [searchList, setSearchList] = React.useState([]);
    let [isData, setIsData] = React.useState();

    React.useEffect(() => {
        let data = {};
        dispatch(dispatch_user_list(data));
        setIsData(true);
    }, [])
    React.useEffect(() => {
        if (dispatch_user_list_res !== undefined && dispatch_user_list_res.list && dispatch_user_list_res.list.length > 0) {
            setSearchList(optionsList(dispatch_user_list_res.list))
        } else {
            setIsData(false);
        }
    }, [dispatch_user_list_res])
    return (
        <div>
            <label className="control-label">Agent</label>
            <SearchableDropDown
                value={value}
                noData={isData}
                onChange={ondropdownChange}
                options={searchList}
                prefixValue="agent"
                instanceId="agent-id"
                placeholder={placeholder !== undefined ? placeholder : "Agent"}
            />
            <ErrorView message={errMessage} />
        </div>
    )
}

export default DispatchUserDropdown;