import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
// import Loader from "../../components/loader";
import { Redirect } from 'react-router-dom';
import { usersdashboard, companyConfigSingle } from '../../actions';
import { routehelp } from '../../Routehelper';
import { formatAMPM } from "../../helpers/functions";

const Card = lazy(() => import('../../components/card'));
const Mis = lazy(() => import('../mis/mis'));
const now = new Date();
class FirstDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardData: [
                {
                    name: 'user',
                    isShow: true,
                    lable: 'Total Users',
                    redirectFlag: true,
                    redirectTo: `${routehelp.usermanagement}?dashboard=${true}`,
                    mainClass: "card mb-4 t-user",
                    iconClass: "d-icon-s fa fa-users",
                    match: "totalUsers"
                },
                {
                    name: 'product',
                    isShow: true,
                    lable: 'Total Products',
                    redirectFlag: true,
                    redirectTo: `${routehelp.productmanagement}?dashboard=${true}`,
                    mainClass: "card t-product mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Products',
                    match: "totalProducts"
                },
                {
                    name: 'order',
                    isShow: true,
                    lable: 'Total Orders',
                    redirectFlag: true,
                    redirectTo: `${routehelp.ordermanagement}?dashboard=${true}`,
                    mainClass: "card t-order mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Order',
                    match: "totalOrders"
                },
                {
                    name: 'customer',
                    isShow: true,
                    lable: 'Total Customers',
                    redirectFlag: true,
                    redirectTo: `${routehelp.customermanagement}?dashboard=${true}`,
                    mainClass: "card t-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Customers',
                    match: "totalCustomers"
                },
                {
                    name: 'brand',
                    isShow: false,
                    lable: 'Total Brands',
                    redirectFlag: true,
                    redirectTo: `${routehelp.Brandmanagement}?dashboard=${true}`,
                    mainClass: "card t-product mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Products',
                    match: "totalBrands"
                },
                {
                    name: 'inactiveUsers',
                    isShow: true,
                    lable: 'Inactive Users',
                    redirectFlag: true,
                    redirectTo: routehelp.inactiveUsers,
                    mainClass: "card t-inactive mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Users',
                    match: "inactiveUsers"
                },
                {
                    name: 'inactiveCustomers',
                    isShow: true,
                    lable: 'Inactive Customers',
                    redirectFlag: true,
                    redirectTo: routehelp.inactiveCustomers,
                    mainClass: "card t-inactive-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Users',
                    match: "inactiveCustomers"
                },
                {
                    name: 'top_10_products',
                    isShow: true,
                    lable: 'Top 10 Products',
                    redirectFlag: true,
                    redirectTo: routehelp.top_10_products,
                    mainClass: "card t-top-products mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Products',
                    match: "top10Products"
                },
                {
                    name: 'least_10_products',
                    isShow: true,
                    lable: 'Least 10 Products',
                    redirectFlag: true,
                    redirectTo: routehelp.least_10_products,
                    mainClass: "card t-least-products mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Products',
                    match: "least10Products"
                },
                {
                    name: 'top_10_orders',
                    isShow: true,
                    lable: 'Top 10 Orders',
                    redirectFlag: true,
                    redirectTo: routehelp.top_10_orders,
                    mainClass: "card t-orders mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Order',
                    match: "top10Orders"
                },
                {
                    name: 'least_10_orders',
                    isShow: true,
                    lable: 'Least 10 Orders',
                    redirectFlag: true,
                    redirectTo: routehelp.least_10_orders,
                    mainClass: "card t-least-orders mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Order',
                    match: "least10Orders"
                },
                {
                    name: 'top_10_customers',
                    isShow: true, lable: 'Top 10 Customers',
                    redirectFlag: true,
                    redirectTo: routehelp.top_10_customers,
                    mainClass: "card t-top-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Users',
                    match: "top10Customers"
                },
                {
                    name: 'least_10_customers',
                    isShow: true,
                    lable: 'Least 10 Customers',
                    redirectFlag: true,
                    redirectTo: routehelp.least_10_customers,
                    mainClass: "card t-least-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Users',
                    match: "least10Customers"
                },
                {
                    name: 'zero_stock_notification',
                    isShow: true,
                    lable: 'Zero Stock',
                    redirectFlag: true,
                    redirectTo: routehelp.zero_stock,
                    mainClass: "card t-least-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Products',
                    match: "zeroStock"
                },
                {
                    name: 'customer_visit',
                    isShow: true,
                    lable: 'Customers Visits',
                    redirectFlag: true,
                    redirectTo: `${routehelp.customerVisities}?dashboard=${true}`,
                    mainClass: "card t-order mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Order',
                    match: "customersVisits"
                },
                {
                    name: 'readyStock',
                    isShow: true,
                    lable: 'Ready Stock',
                    redirectFlag: false,
                    redirectTo: `${routehelp.ordermanagement}?dashboard=${true}`,
                    mainClass: "card t-inactive-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Order',
                    match: "readyStock"
                },
                {
                    name: 'sentStock',
                    isShow: true,
                    lable: 'Sent to Customer',
                    redirectFlag: false,
                    redirectTo: `${routehelp.ordermanagement}?dashboard=${true}`,
                    mainClass: "card t-inactive-customer mb-4",
                    iconClass: 'd-icon-s tai-ico-Total-Order',
                    match: "sentStock"
                },
            ],
            monthArray: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            dayArray: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
            endDate: new Date(),
            isLoading: true,
            productFilter: false,
            webFeature: {}
            // brand: null
        }
    }

    setTime = () => {
        let currentdate = new Date();
        let timeString = formatAMPM(currentdate);
        let date = currentdate.getDate();
        let month = currentdate.getMonth();
        let year = currentdate.getFullYear();
        let day = currentdate.getDay();
        this.setState({ date: date, month: month, year: year, day: day, timeString: timeString });
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.props.companyConfigSingle({});
        this.setTime();
        this.callOtherTableData({}, false);
        window.setInterval(function () {
            this.setTime();
        }.bind(this), 1000);
    }

    componentWillUnmount() {
        window.clearInterval(this.setTime);
    }

    async updateFilter(startDate, endDate, aggent_array) {
        this.callOtherTableData(
            {
                filter: {
                    createdAt: {
                        "from": startDate,
                        "to": endDate,
                    }
                },
                agentNames: aggent_array || []
            }, true);
    }

    async callOtherTableData(filterObj, flag = false) {
        let data = {};
        if (flag) {
            data = filterObj;
        } else {
            data = {
                filter: {
                    createdAt: {
                        "from": this.state.startDate,
                        "to": this.state.endDate
                    }
                }
            }
        }

        localStorage.setItem('filter-date', JSON.stringify(data));
        this.props.usersdashboard(data);
    }

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            this.setState({
                isLoading: false
            });

            if (nextProps.companyConfigSingle_res && nextProps.companyConfigSingle_res.success === true && nextProps.companyConfigSingle_res.item && nextProps.companyConfigSingle_res.item.webFeature) {
                this.setState({
                    webFeature: nextProps.companyConfigSingle_res.item.webFeature
                }, () => {
                    let cardDetails = this.state.cardData || [];
                    cardDetails.forEach((ele, index) => {
                        if (this.state.webFeature && this.state.webFeature[ele.match]) {
                            // console.log(index, this.state.webFeature[ele.match], ele.match);
                            ele.isShow = true;
                        } else {
                            ele.isShow = false;
                        }
                    });
                    this.setState({
                        cardData: cardDetails
                    });
                });
                // nextProps.companyConfigSingle_res.success = null;
            }
        }
        return true;
    }

    render() {
        const { usersdashboard_res } = this.props;
        // console.log(usersdashboard_res);
        const type = localStorage.getItem('type');
        const name = localStorage.getItem('name');

        if (type === "CO") {
            return (
                <main className="main-content">
                    <div className="page-title">
                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="mb-0"> Hi {name}, Welcome! </h4>
                                <p className="mb-0 pl-0 pt-1 pb-0">
                                    <small>{this.state.date} {this.state.monthArray[this.state.month]} {this.state.year}, {this.state.dayArray[this.state.day]} {this.state.timeString}</small>
                                </p>
                            </div>
                            <div className="col text-right">
                                <img src="assets/img/admin-img.png" className="admin-img" alt="" width="150" />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {
                            this.state.isLoading ? "" :
                                <div className="row admin-card">
                                    {
                                        this.state.cardData.map((el) => {
                                            return (
                                                <Suspense fallback="" key={el.name}>
                                                    <Card elementData={el} mainData={usersdashboard_res} />
                                                </Suspense>
                                            )
                                        })}
                                </div>
                        }

                        <Suspense fallback="">
                            <Mis filterUpdate={this.updateFilter.bind(this)} webFeature={this.state.webFeature} name={name} />
                        </Suspense>

                    </div>

                </main>
            )
        }
        else {
            return <Redirect to={routehelp.logout} />
        }
    }
}

function mapStateToProps(state) {
    return {
        usersdashboard_res: state.usersdashboard_res,
        companyConfigSingle_res: state.companyConfigSingle_res
    };
}

const mapDispatchToProps = dispatch => ({
    companyConfigSingle: data => dispatch(companyConfigSingle(data)),
    usersdashboard: data => dispatch(usersdashboard(data)),
});

FirstDashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(FirstDashboard);

export default FirstDashboard;