import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ratesingle } from '../../actions';
import { rateupdate } from '../../actions';
import { Redirect } from 'react-router-dom';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import Translate from '../../libs/translation';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';

class RateUpdate extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.state = {
            exMillPrice: '',
            whlPrice: '',
            error: {
                exMillPriceError: '',
                whsPriceError: ''
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.rate_single && props.rate_single.success === true) {
            this.setState({
                exMillPrice:
                    props.rate_single !== undefined && props.rate_single.item
                        ? props.rate_single.item.exMillPrice
                        : '',
                whlPrice:
                    props.rate_single !== undefined && props.rate_single.item
                        ? props.rate_single.item.whsPrice
                        : '',
            });
            props.rate_single.success = null;
        }
        return false;
    }
    validate = () => {
        let exMillPriceError = '';
        let whsPriceError = '';
        if (this.state.exMillPrice.length === 0) {
            exMillPriceError = 'exMillPrice Is Required';
        }
        if (this.state.whlPrice.length === 0) {
            whsPriceError = 'Code Is Required';
        } else if (this.state.exMillPrice > this.state.whlPrice) {
            whsPriceError = 'WholeSale Should be same or greater then Ex mill Price';
        }
        if (exMillPriceError || whsPriceError) {
            this.setState({ exMillPriceError, whsPriceError });
            return false;
        }
        return true;
    };

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (nextProps.rate_update !== undefined && nextProps.rate_update.success === false && this.props !== nextProps) {
            nextProps.rate_update.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.msg), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.rate_update.success = null;
        }
        return true;
    }


    handleChange(name, e) {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        this.props.ratesingle(id);
    }


    onsubmit(e) {
        let id = this.props.match.params.item_id;
        let data = {
            exMillPrice: this.state.exMillPrice,
            whlPrice: this.state.whlPrice,
        }
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            // this.setState(this.state.error);
            this.props.rateupdate(data, id);
        }

    }
    render() {
        let { rate_update } = this.props;
        if (rate_update !== undefined) {
            if (rate_update.message) {
                toastr.error(rate_update.message, 'error');
                rate_update.message = null
            }
            if (rate_update.success === true) {
                toastr.success('Rate Has been Added Successfully', 'success');
                rate_update.success = null;
                return <Redirect to={routehelp.RateMapping} />
            }
        }
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Update Rate
                            </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form>
                                                <div className="form-group required">
                                                    <label htmlFor="exampleInputName1" className="control-label">Ex Mill Rate</label>
                                                    <Inputfield maxlength="20" handleChange={this.handleChange} type="text" name="exMillPrice" value={this.state.exMillPrice} className="form-control" id="exMillPrice" ariadescribedby="codeHelp" placeholder="Ex Mill Rate" />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.exMillPriceError}{' '}
                                                    </small>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-6">
                                            <form>
                                                <div className="form-group required">
                                                    <label htmlFor="exampleInputcode" className="control-label">WHS Rate</label>
                                                    <Inputfield maxlength="10" handleChange={this.handleChange} type="code" name="whlPrice" value={this.state.whlPrice} className="form-control" id="whlPrice" aria-describedby="codeHelp" placeholder="WHS Rate" />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.whsPriceError}{' '}
                                                    </small>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Update" />
                            <Links to={routehelp.RateMapping} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        rate_single: state.rate_single,
        rate_update: state.rate_update
    };
}


const mapDispatchToProps = dispatch => ({
    ratesingle: data => dispatch(ratesingle(data)),
    rateupdate: (data, id) => dispatch(rateupdate(data, id))
});

RateUpdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(RateUpdate);

export default RateUpdate;