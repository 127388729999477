import React, { Component } from 'react';
import ButtonWithItag from '../components/buttonwithTagi';
import { getValue } from '../../src/helpers/functions';
require('react-datetime');
var config = require('../config');
var dateFormat = require('dateformat');


let sortBy = '';
let sort = '';
class DataTable extends Component {
    render() {
        let { data, headers, sortByCol, sortvalue, sortByvalue, mappingObj, props } = this.props;
        sort = sortvalue;
        sortBy = sortByvalue;
        return (
            <table id="bs4-table" className="table table-bordered dataTable" cellSpacing="0">
                <thead>

                    <tr role="row">
                        {headers && headers.length > 0 ? headers.map(
                            // eslint-disable-next-line
                            (item, index) => {
                                if (item.isShow === true) {
                                    return (
                                        item.label === "isChecked" ?
                                        <th 
                                        key={index}
                                        tabIndex="0"
                                        aria-controls="zero_config"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Name: activate to sort column descending"
                                        >
                                            <input type="checkbox" onChange={(ev) => item.function(ev)}   checked={item.checked()}  />
                                        </th> :
                                        <th
                                            key={index}
                                            onClick={item.sortable ? () => { sortByCol(item.name, item.sortable) } : null}
                                            className={
                                                item.sortable ? sort === '' ? 'sorting' : sort === 'asc' &&
                                                    sortBy === item.name
                                                    ? 'sorting_asc'
                                                    : sort === 'desc' &&
                                                        sortBy === item.name
                                                        ? 'sorting_desc'
                                                        : 'sorting'
                                                    : 'text-center'
                                            }
                                            tabIndex="0"
                                            aria-controls="zero_config"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="Name: activate to sort column descending"
                                        >
                                            {item.label}
                                        </th>
                                    );
                                }
                            }
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    {
                        data !== undefined && data.list && data.list.length > 0 ? data.list.map((item, index) => (
                            <tr key={index} role="row" className="odd notification-list">
                                {
                                    headers && headers.length > 0 ? headers.map((key, idx) => {
                                        if (key.type === "date") {
                                            return <td key={idx}>{item[key.name] ? dateFormat(new Date(item[key.name]), 'mmm d, yyyy') : key.replaceMentText ? key.replaceMentText : "N/A"}</td>
                                        } else if (key.type === "date_time") {
                                            return <td key={idx}>{getValue(item, key.name) ? dateFormat(new Date(getValue(item, key.name)), 'mmm d, yyyy h:MM TT') : key.replaceMentText ? key.replaceMentText : "N/A"}</td>
                                        } else if (key.type === "boolean") {
                                            return <td key={idx}>{mappingObj[item[key.name]]}</td>
                                        } else if (key.type === "multiCheckbox"){
                                            return <td key={idx}> 
                                                <input type="checkbox" checked={item.checked} onChange={() => key.handleCheckboxChange(item._id)}/>
                                            </td>
                                        }
                                        else if (key.type === "checkbox") {
                                            return <td key={idx} className="text-center">
                                                {/* defaultChecked={item[key.name]} */}
                                                <input className="tgl tgl-light tgl-warning" name={item._id} id={item._id} type={key.type} onChange={(ev) => key.function(ev, item)} checked={item[key.name]} />
                                                <label className="tgl-btn m-0" htmlFor={item._id}></label>
                                            </td>
                                        } else if (key.type === "button") {
                                            return <td key={idx} className="text-center">
                                                {key.allactions.map((btnVal, idx) =>
                                                    <ButtonWithItag 
                                                        key={idx} 
                                                        datatoggle={btnVal.datatoggle}
                                                        datatarget={btnVal.datatarget}
                                                        dataBackdrop={btnVal.dataBackdrop || "static"}
                                                        dataKeyboard={btnVal.dataKeyboard || false}
                                                        dataplacement={key.dataplacement}
                                                        title={btnVal.title}
                                                        data-original-title={key.dataoriginaltitle}
                                                        onclick={(ev) => btnVal.function(ev, item, props)}
                                                        type={key.type}
                                                        classNameI={btnVal.classNameI}
                                                        className={btnVal.className} />
                                                )}
                                            </td>
                                        } else if (key.type === "invoice") {
                                            return <td key={idx} className="text-center">
                                                {/* {console.log(item.isErpConfirm)} */}
                                                {
                                                    key.data.map((k, idx) =>
                                                        k.type === "href" ?
                                                            <a href={config.apiUrl + "/api/invoice/" + item._id} target={k.target}><i className={k.classNameI} id={idx}></i></a>
                                                            :
                                                            k.type === "button" && ["confirm", "reject"].indexOf(k.name) > -1 && !item.isErpConfirm && !item.isRejected ?
                                                                <ButtonWithItag key={idx} datatoggle={k.datatoggle} title={k.title}
                                                                    onclick={(ev) => k.function(item)} type={k.type}
                                                                    classNameI={k.classNameI} className={k.className} /> :
                                                                null
                                                    )
                                                }
                                            </td>
                                        } else if (key.type === "loop") {
                                            return <td key={idx} className="text-center">
                                                {
                                                    item[key.name] && typeof item[key.name] === 'object' && item[key.name].length > 0 ? item[key.name].map((ele, i) =>
                                                        <div key={i}>
                                                            {/* {console.log(key.keyName, ele[key.keyName])} */}
                                                            {
                                                                key.isLink ? <a href="true" className="text-primary" onClick={(ev) => key.function(ev, ele)}><span>{key.keyName ? ele[key.keyName] : (ele.name || "NA")}</span></a> :
                                                                    <span>{key.keyName ? ele[key.keyName] : (ele.name || "NA")}</span>
                                                            }
                                                            <br />
                                                            <br />
                                                        </div>
                                                    ) : null
                                                }
                                            </td>
                                        } else if (key.type === "number") {
                                            return <td key={idx}>{item[key.name]}</td>
                                        } else if (key.type === "text__with_icon") {
                                            if (key.extraString) {
                                                return (
                                                    <td key={idx} title={getValue(item, key.name) ? `${getValue(item, key.name)} ${key.extraString}` : key.replaceMentText ? `${key.replaceMentText} ${key.extraString}` : "N/A"}>
                                                        {
                                                            getValue(item, key.name) ? `${getValue(item, key.name)} ${key.extraString}`.substring(0, 35) : key.replaceMentText ? `${key.replaceMentText} ${key.extraString}`.substring(0, 35) : "N/A"
                                                        }
                                                        {
                                                            key.allactions.map((btnVal, idx) =>
                                                                <ButtonWithItag key={idx} datatoggle={btnVal.datatoggle}
                                                                    datatarget={btnVal.datatarget}
                                                                    dataplacement={key.dataplacement}
                                                                    title={key.title}
                                                                    data-original-title={key.dataoriginaltitle}
                                                                    onclick={(ev) => btnVal.function(ev, item, props)}
                                                                    type={key.type}
                                                                    classNameI={btnVal.classNameI}
                                                                    className={btnVal.className} />
                                                            )
                                                        }
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={idx} title={getValue(item, key.name) ? `${getValue(item, key.name)}` : key.replaceMentText ? key.replaceMentText : "N/A"}>
                                                        {
                                                            getValue(item, key.name) ? `${getValue(item, key.name)}`.substring(0, 35) : key.replaceMentText ? key.replaceMentText.substring(0, 35) : "N/A"
                                                        }
                                                        {
                                                            key.allactions.map((btnVal, idx) =>
                                                                <ButtonWithItag key={idx} datatoggle={btnVal.datatoggle}
                                                                    datatarget={btnVal.datatarget}
                                                                    dataplacement={key.dataplacement}
                                                                    title={key.title}
                                                                    data-original-title={key.dataoriginaltitle}
                                                                    onclick={(ev) => btnVal.function(ev, item, props)}
                                                                    type={key.type}
                                                                    classNameI={btnVal.classNameI}
                                                                    className={btnVal.className} />
                                                            )
                                                        }
                                                    </td>
                                                )
                                            }
                                        } else if (key.type === "text-label") {
                                            return <td key={idx}>
                                                {
                                                    `${getValue(item, key.name) ? getValue(item, key.name) : 0} ${key.textlabel} ${getValue(item, key.name) > -1 ? key.otherType === 'date_time' ? dateFormat(new Date(getValue(item, key.othername)), 'mmm d, yyyy h:MM TT') : getValue(item, key.othername) : "NA"}`
                                                }
                                            </td>
                                        } else {
                                            if (key.extraString) {
                                                return <td key={idx} title={getValue(item, key.name) ? `${getValue(item, key.name)} ${key.extraString}` : key.replaceMentText ? `${key.replaceMentText} ${key.extraString}` : "N/A"}>
                                                    {getValue(item, key.name) ? `${getValue(item, key.name)} ${key.extraString}`.substring(0, 35) : key.replaceMentText ? `${key.replaceMentText} ${key.extraString}`.substring(0, 35) : "N/A"}
                                                </td>
                                            } else {
                                                return <td key={idx} title={getValue(item, key.name) ? `${getValue(item, key.name)}` : key.replaceMentText ? key.replaceMentText : "N/A"}>{getValue(item, key.name) ? `${getValue(item, key.name)}`.substring(0, 35) : key.replaceMentText ? key.replaceMentText.substring(0, 35) : "N/A"}</td>
                                            }
                                        }
                                    }) : null}
                            </tr>
                        )
                        ) :
                            <tr>
                                <td colSpan={headers.length} className="text-center">No Record Found</td>
                            </tr>
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable;