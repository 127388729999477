const routehelp = {
    default: "/",
    login: "/login",
    signup: "/signup",
    logout: "/logout",
    profile: "/profile",
    forgotpassword: "/forgotpassword",
    maindashboard: "/maindashboard",
    packages: "/packages",
    primarydashboard: "/primarydashboard",
    otp: '/otp',
    usermanagement: "/usermanagement",
    usermanagementadd: "/usermanagement/add",
    usermanagementupdate: "/usermanagement/update/",
    zonemanagement: "/zonemanagement",
    zonemanagementadd: "/zonemanagement/add",
    zonemanagementupdate: "/zonemanagement/update/",
    Regionmanagement: "/regionmanagement",
    Regionmanagementadd: "/regionmanagement/add",
    regionmanagementupdate: "/regionmanagement/update/",
    Territorymanagement: "/territorymanagement",
    Territorymanagementadd: "/territorymanagement/add",
    Territorymanagementupdate: "/territorymanagement/update/",
    Beatsmanagement: "/beatsmanagement",
    Beatsmanagementadd: "/beatsmanagement/add",
    Beatsmanagementupdate: "/beatsmanagement/update/",
    Brandmanagement: "/brandmanagement",
    Brandmanagementadd: "/brandmanagement/add",
    Brandmanagementupdate: "/brandmanagement/update/",
    customermanagement: "/customermanagement",
    Customeradd: "/customermanagement/add",
    customermanagementupdate: "/customermanagement/update/",
    productmanagement: "/productmanagement",
    productmanagementadd: "/productmanagement/add",
    productmanagementupdate: "/productmanagement/update/",
    stockmanagement: "/stockmanagement",
    ordermanagement: "/ordermanagement",
    ordermanagementadd: '/ordermanagement/add',
    ordermanagementUpdate: '/ordermanagement/update/',
    retailerOrdermanagement: "/retailerorder",
    Profile: '/profile',
    changePassword: '/changepassword',
    routeplanning: '/routeplanning',
    addroute: '/addroute',
    updateroute: '/routeupdate/update/',
    outstanding: '/outstanding',
    dispatchDetails: '/dispatchDetails',
    mis: "/MIS",
    indirectcustomer: '/indirectCustomers',
    indirectCustomeradd: '/indirectCustomer/add',
    IndirectCustomermanagementUpdate: '/indirectCustomers/update/',
    RateMapping: '/rateMapping',
    rateAdd: "/rateMapping/add",
    rateUpdate: '/rateMapping/update/',
    SrAsmAssignment: "/senior-asm-assignment",
    SrAsmAssignmentAdd: '/senior-asm-assignment/add',
    SrAsmAssignmentUpdate: '/senior-asm-assignment/update/',
    schememanagement: '/scheme',
    schememanagementadd: '/scheme/add',
    schememanagementUpdate: '/scheme/update/',
    transporter: '/transporter',
    transporterAdd: "/transporter/add",
    transporterupdate: "/transporter/update/",
    retail: "/retailer",
    retailAdd: "/retailer/add",
    retailUpdate: "/retailer/update/",
    trending: "/trend",
    trendAdd: "/trend/add",
    trendUpdate: "/trend/update/",
    catagory: "/category",
    catagoryAdd: "/category/add",
    catagoryUpdate: "/category/update/",
    eCatalogue: "/e-catalogue",
    eCatalogueAdd: "/e-catalogue/add",
    catalogueupdate: "/e-catalogue/update/",
    setting: '/setting',
    misReportSetting: '/misreportsetting',
    companyAdd: '/company/add',
    companyUpdate: "/company/update/",
    discountMaster: "/discountMaster",
    discountMasterAdd: "/discountMaster/add",
    discountMasterUpdate: "/discountMaster/update/",
    agencyMaster: "/agency",
    agencyAdd: "/agency/add",
    agencyUpdate: "/agency/update/",
    allowanceCatagory: "/allowanceCatagory",
    allowanceCatagoryAdd: "/allowanceCatagory/add",
    allowanceCatagoryUpdate: "/allowanceCatagory/update/",
    allowanceSubCatagory: "/allowanceSubCatagory",
    allowanceSubCatagoryAdd: "/allowanceSubCatagory/add",
    allowanceSubCatagoryUpdate: "/allowanceSubCatagory/update/",
    touringVoucher: "/touringVoucher",
    expenseApproval: "/expenseApproval",
    advanceApproval: "/advanceApproval",
    uomMaster: '/UOM_Master',
    uomAdd: '/UOM_Master/add',
    uomUpdate: '/UOM_Master/update/',
    inactiveUsers: '/inactive-users',
    inactiveCustomers: '/inactive-customers',
    top_10_products: '/top-10-products',
    least_10_products: '/least-10-products',
    top_10_customers: '/top-10-customers',
    least_10_customers: '/least-10-customers',
    top_10_orders: '/top-10-orders',
    least_10_orders: '/least-10-orders',
    logs: '/logical-logs',
    config: '/company-config',
    zero_stock: '/zero-stock',
    order_create: '/order-create',
    emailConfig: '/email-config',
    stockEdit: '/stockmanagement/update/',
    userHierarchyList: '/user-hierarchy',
    userHierarchyAdd: '/user-hierarchy/add',
    userHierarchyUpdate: '/user-hierarchy/update/',
    commissionList: '/commission',
    orderFailed: '/order-failed',
    commissionRange: '/commission-range',
    commissionRangeUpdate: '/commission-range/update/',
    commissionRangeAdd: '/commission-range/add',
    b2bPrivacy: '/b2b/privacy',
    b2cPrivacy: '/b2c/privacy',
    payment: '/payment',
    customerVisities: '/customer-visities',
    version: '/version',
    orderTemplateList: '/order-template',
    orderTemplateListAdd: '/order-template/add',
    orderTemplateListUpdate: '/order-template/update/',
    feedback: '/feedback',
}

export { routehelp };