/* eslint-disable */
import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
            <footer className="app-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8">
                            <p className="grey fontsize_12">Rydot Infotech Limited © {new Date().getFullYear()}. All rights reserved. Designed by &nbsp;
                             <a href="https://www.rydotinfotech.com" target="blank" className="highlight"  >Rydot Infotech Limited</a>
                            </p>
                        </div>
                        <div className="col-4">
                                                    <a href="#" className="float-right back-top">
                                <i className=" ti-arrow-circle-up"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;