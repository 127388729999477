import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { category_search } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../../components/errorView';

let CategoryDropdown = ({ ondropdownChange, value, placeholder, errMessage, isLabel }) => {

  const dispatch = useDispatch();
  let category_response_search = useSelector(state => state.category_response_search)
  let [searchList, setSearchList] = React.useState([]);
  let [isData, setIsData] = React.useState();


  React.useEffect(() => {
    let data = {};
    dispatch(category_search(data));
    setIsData(true);
  }, [])

  React.useEffect(() => {
    if (category_response_search !== undefined && category_response_search.list && category_response_search.list.length > 0) {
      setSearchList(optionsList(category_response_search.list))
    } else {
      setIsData(false);
    }
  }, [category_response_search])

  return (
    <div>
      {
        isLabel ?
          <label className="control-label">Category</label> : null
      }
      <SearchableDropDown
        value={value}
        noData={isData}
        onChange={ondropdownChange}
        options={searchList}
        instanceId="category-id"
        prefixValue="category"
        placeholder={placeholder !== undefined ? placeholder : "Category"}
      />
      <ErrorView message={errMessage} />
    </div>
  );

}

export default CategoryDropdown;