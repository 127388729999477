import React, { Component } from 'react';
import { connect } from 'react-redux';
import { discountList } from '../../actions';
import { dicountDelete } from '../../actions';
import { routehelp } from '../../Routehelper';
import Pagination from '../../components/pagination/index';
import Entries from '../../components/entries/entires';
import LinkWithItag from '../../components/linkwithItag';
import ButtonWithItag from '../../components/buttonwithTagi';
import LinksWithI from '../../components/linkwithItag';
import Translate from '../../libs/translation';
import toastr from 'reactjs-toastr';
import Loader from '../../components/loader';
import 'reactjs-toastr/lib/toast.css';
require('react-datetime');
var dateFormat = require('dateformat');

let itemsPerPage = 5;
let currentPage = 1;
let sortBy = '';
let sort = '';
class DiscountMaster extends Component {
    constructor(props) {
        super(props)
        this.loadResult = this.loadResult.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.handleChangeiItemsPerPage = this.handleChangeiItemsPerPage.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.handleKeyPress = this.handleKeyPress.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.checkhandler = this.checkhandler.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.resetfilter = this.resetfilter.bind(this);
        this.state = {
            check: '',
            name: '',
            code: '',
            isLoading: true
        }
    }

    async componentDidMount() {
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1
        itemsPerPage = 5
    }

    loadResult() {
        let data = {}
        data.filter = { name: this.state.name !== "" ? this.state.name : undefined, code: this.state.code !== "" ? this.state.code : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.discountList(data);
        this.setState({ isLoading: false })
    }

    resetfilter() {
        let data = {}
        data.filter = { name: undefined, code: undefined }
        this.props.discountList(data);
    }

    sortBy(key) {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext() {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious() {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage(page) {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage(items) {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    checkhandler(e) {
        this.setState({ [e.target.name]: e.target.checked })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    deleteRow(e, item_id) {
        this.props.dicountDelete(item_id);
        e.preventDefault();
    }

    shouldComponentUpdate(nextProps) {
        let req = {};

        if (nextProps.discount_delete !== undefined) {
            if (nextProps.discount_delete.success === true) {
                toastr.success('Discount has been Deleted Successfully', 'success');
                nextProps.discount_delete.success = null;
                this.loadResult();
            }
        }

        if (nextProps.discountList_res !== undefined) {
            if (nextProps.discountList_res.success === true) {
                nextProps.discountList_res.success = null;
                this.setState({ isLoading: true });
            }
        }

        if (nextProps.discount_delete !== undefined && nextProps.discount_delete.success === false && this.props !== nextProps) {
            nextProps.discount_delete.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.msg), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.discount_delete.success = null;
        }

        return true;
    }

    render() {
        let { discountList_res } = this.props;
        const totResult = discountList_res !== undefined ? discountList_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Discount Master
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 mb-3">
                                                <LinkWithItag to={routehelp.discountMasterAdd} className="btn btn-primary" role="button" classNameI="ti-plus pr-2" name="Add New" />
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="bs4-table_filter" className="dataTables_filter">
                                                    {/* <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Click to Upload all" />
                                                        <button className="btn btn-primary navbar-toggler right-sidebar-toggler" type="button" data-original-title="Click for Filter option" data-placement="top" data-toggle="tooltip">
                                                            <i className="ti-filter"></i>
                                                        </button> */}
                                                    {/* <ButtonWithItag classNameI="ti-filter" type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="" data-original-title="Click for Filter option" /> */}
                                                    {/* <ButtonWithItag classNameI="fa tai-ico-Export" type="button" className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Click to download all" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? "No Record Found" :
                                            !this.state.isLoading ? <Loader /> : discountList_res !== undefined && discountList_res.count === 0 ? "No Data Found" : discountList_res && discountList_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <table id="bs4-table" className="table table-bordered table-striped dataTable" cellSpacing="0">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th onClick={() => this.sortBy('name')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Name </th>
                                                                    <th onClick={() => this.sortBy('code')}
                                                                        className={
                                                                            sort === ''
                                                                                ? 'sorting'
                                                                                : sort === 'asc'
                                                                                    ? 'sorting_asc'
                                                                                    : 'sorting_desc'
                                                                        }>Description </th>
                                                                    <th onClick={() => this.sortBy('createdAt')}
                                                                        className={
                                                                            sort === ''
                                                                                ? 'sorting'
                                                                                : sort === 'asc'
                                                                                    ? 'sorting_asc'
                                                                                    : 'sorting_desc'
                                                                        } >Created At</th>
                                                                    {/* <th className="ing">Address</th>                                                               */}
                                                                    {/* <th className="sorting">Status</th> */}
                                                                    <th >Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {discountList_res !== undefined && discountList_res.list && discountList_res.list.map((item, index) =>
                                                                    <tr key={index} role="row" className="odd notification-list">
                                                                        <td>{item.name}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{dateFormat(new Date(item.createdAt), 'mmm d, yyyy')}</td>
                                                                        {/* <td className="text-center">
                                                                                <input className="tgl tgl-light tgl-warning" name={item._id} id={item._id} type="checkbox" defaultChecked={item.isActive} onChange={this.checkhandler} />
                                                                                <label className="tgl-btn m-0" htmlFor={item._id}></label>
                                                                            </td> */}
                                                                        <td className="text-center">
                                                                            <LinksWithI to={routehelp.discountMasterUpdate + item._id} item_id={item._id} type="button" classNameI="ti-pencil" className="btn btn-primary action-btn mr-1" />
                                                                            <ButtonWithItag onclick={ev => { if (window.confirm(`Are you sure you wish to delete "${item.name} ?"`)) this.deleteRow(ev, item._id); }} type="button" classNameI="ti-trash" className="btn btn-danger action-btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" />
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ? discountList_res !== undefined && discountList_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            /* <aside className="right-sidebar p-0 scrollbarcustom ">
                <div className="modal-header bg-primary rounded-0">
                    <h6 className="modal-title"> Filter</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                    </button>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="name" name="name" onChange={this.onChange} value={this.state.name} placeholder="Name" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="Mobile" className="col-sm-3 col-form-label">Code</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" name="code" value={this.state.code} onChange={this.onChange} id="code" placeholder="Code" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="Mobile" className="col-sm-3 col-form-label">Create at</label>
                            <div className="col-sm-9">
                                <div className="date filterdate float-left" id="datepicker-bottom-left">
                                    <input type="date" className="form-control m-input" placeholder="Start Date" />
                                    <span className="input-group-addon">
                                        <i className="fa fa-calendar"></i>
                                    </span>
                                </div>
                                <div className="todate">TO</div>
                                <div className="date filterdate float-left" id="datepicker-bottom-left">
                                    <input type="date" className="form-control m-input" placeholder="End Date" />
                                    <span className="input-group-addon">
                                        <i className="fa fa-calendar"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-6 mb-4 offset-md-3 text-center">
                    <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                    <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark navbar-toggler right-sidebar-toggler pointer">Reset</button>
                </div>
            </aside> */

        )
    }

}

function mapStateToProps(state) {
    return {
        discountList_res: state.discountList_res,
        discount_delete: state.discount_delete
    };
}

const mapDispatchToProps = dispatch => ({
    discountList: data => dispatch(discountList(data)),
    dicountDelete: item_id => dispatch(dicountDelete(item_id))
});

DiscountMaster = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscountMaster);

export default DiscountMaster;