/* eslint-disable */
import React, { Component, Fragment, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { uommanagementlist, orderCreate, orderTemplate_list, companyConfigSingle, orderTemplate_get, productmanagementlist } from '../../actions';
import { validate, errorRender, successRedriect, getValue, formatOfDecimal} from '../../helpers/functions';
import Select from 'react-select';
import 'reactjs-toastr/lib/toast.css';
require('react-datetime');

const ProductDropdown = lazy(() => import('../productManagement/productDropdown'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const SelectInputField = lazy(() => import('../../components/selectInputField'));
const SingleBrandDropDown = lazy(() => import('../../container/brandsManagement/singleBrandDropDown'));
const CustomerDropDown = lazy(() => import('../../container/customerManagement/customerDropdown'));
const Wholesalerdropdown = lazy(() => import('../../container/indirectCustomer/wholesalerDropdwon'));
const Transporterdropdown = lazy(() => import("../transporterManagement/transporterDropdwon"));

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));

class OrderCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orderTemplate: "",
            uomDisplay: {},
            webFeature: {},
            orderTemplateList: [],
            isMultiple: false,
            agent: "",
            type: "",
            typeError: "",
            utype: "",
            customerTypeOptions: [
                { label: "Select a Customer Type", value: "" },
                { label: "Retailer", value: "retailer" },
                { label: "Wholesaler", value: "wholesaler" }
            ],
            brandError: "",
            brand: "",
            product: "",
            products: [],
            productError: "",
            seriesList: this.setDefaultSeriesList(),
            series: "",
            uom_name: false,
            selectedButtonIndex: null,
            shade: [],
            totalAmount: 0,
            price: 0,
            customer: "",
            indirectCustomer: "",
            filterCus: {},
            transporter: "",
            filterTrans: {},
            isLoading: false,
            getSingleLoader: false,
            filterProduct: {},
            note: "",
            uomError: "",
            userError: "",
            customerError: "",
            wholesalerError: "",
            transporterError: "",
            seriesError: "",
            unitCodes: [],
            multiplier: 1,
            subTotal: 0,
            serverErrors: ["order_create"],
            forRedirect: [
                { name: "order_create", pageName: 'Order', pageUrl: 'ordermanagement', action: "created" }
            ],
            paymentTerms: "",
            deliveryStation: "",
            dispatchDate: "",
            multipleProducts: [],
            alertMessage: "",
            productTotal: 0,
            maxDate: new Date(),
            displaySeries: {
                wholesaler: "WHS",
                retailer: "RTL",
                ASM: "ASM",
                AG: "AG",
                SASM: "SASM",
                ASM2: "ASM2"
            },
            filterIndirectCus: {},
            setFields: [
                { name: "name", type: "text" },
                { name: "brand", type: "select" },
                { name: "totalQuantity", type: "text" },
                { name: "totalAmount", type: "text" },
                { name: "note", type: "text" },
                { name: "products", type: "array" }
            ],
            stockList: [],
            templateQty: 1,
            isThirdPartyOrder: false
        }
    }

    componentDidMount() {
        this.loadResult();
        let date = new Date();
        let newDate = new Date(date.setMonth(date.getMonth() + 2));
        let mmddyyyy = (newDate.toISOString().split('T')[0]).split("-").reverse();
        // console.log(mmddyyyy);
        this.setState({
            maxDate: `${mmddyyyy[1]}-${mmddyyyy[0]}-${mmddyyyy[2]}`
        });
    }

    loadResult = () => {
        let data = {};
        data.sortBy = "createdAt";
        data.sort = "desc";
        data.skip = 0;
        data.limit = 1000000;
        this.props.orderTemplate_list(data);
        this.props.uommanagementlist({ limit:1000 });
        this.props.companyConfigSingle({});
        this.setState({ isLoading: true });
    }

    QTYfun = (item, name, index, qty = 0, unitName) => {

        let shadeUpdate = this.state.shade;

        if (name === "add") {

            if (shadeUpdate && shadeUpdate.length > 0) {
                shadeUpdate.forEach((ele, i) => {
                    if (!ele.selectedQTY) {
                        ele.selectedQTY = 0;
                    }

                    if (typeof ele.quantity === "string" && ele.quantity !== "0") {
                        ele.quantity = Number(ele.quantity);
                    }

                    if (typeof ele.quantity === "string" && ele.quantity === "0") {
                        ele.quantity = 0;
                    }

                    if (i === index) {
                        ele.uom.forEach((el) => {
                            if (el.unitName === unitName) {
                                if (ele.selectedQTY < ele.quantity) {
                                    if (typeof ele.selectedQTY === "string") {
                                        ele.selectedQTY = Number(ele.selectedQTY);
                                        el.selectedQTY = Number(el.selectedQTY);
                                        
                                    }
                                    el.selectedQTY++;
                                    ele.selectedQTY++;

                                } else {
                                    ele.selectedQTY = ele.quantity;
                                    alert(`Selected Qty is greather then max qty. Please check max Qty of shade name: ${item.name} and select accordingly`);
                                    return true;
                                }
                            }
                        })
                    };

                });
            };

        };

        if (name === "minus") {
            if (shadeUpdate && shadeUpdate.length > 0) {
                shadeUpdate.forEach((ele, i) => {

                    if (!ele.selectedQTY) {
                        ele.selectedQTY = 0;
                    }
                    if (i === index) {
                        ele.uom.forEach((el) => {
                            if (el.unitName === unitName && el.selectedQTY - 1 >= 0 ) {
                                if (typeof ele.selectedQTY === "string") {
                                    ele.selectedQTY = Number(ele.selectedQTY);
                                    el.selectedQTY = Number(el.selectedQTY);
                                }
                                ele.selectedQTY--;
                                el.selectedQTY--; 
                            }
                        })
                    };
                });
            };
        };

        if (name === "change") {

            // if (typeof qty === "string" && qty !== "0") {
            //     qty = Number(qty);
            // }

            // if (typeof qty === "string" && qty === "0") {
            //     qty = 0;
            // }

            if (typeof item.quantity === "string" && item.quantity !== "0") {
                item.quantity = Number(item.quantity);
            }

            if (qty < 0) {
                alert("Negative value is not allowed");
                qty = 0;
            }

            // if (qty > item.quantity) {
            //     // qty = item.quantity;
            //     alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${item.name} and select accordingly`);
            //     return true;
            // }

            if (shadeUpdate && shadeUpdate.length > 0) {
                shadeUpdate.forEach((ele, i) => {

                    if (i === index) {
                        ele.uom.forEach((el) => {
                            if (el.unitName === unitName) {
                                  if (qty > el.selectedQTY) {
                                    if (qty < item.quantity || Number(qty) === item.quantity) {
                                        if ((ele.selectedQTY + (Number(qty) - el.selectedQTY)) > item.quantity) {
                                            alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${item.name} and select accordingly`);
                                            return true;
                                        } else {
                                            ele.selectedQTY =  ele.selectedQTY + (Number(qty) - el.selectedQTY);
                                            el.selectedQTY = qty;
                                        }
                                    } else {
                                        alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${item.name} and select accordingly`);
                                        return true;
                                    }
                                } else if (qty < el.selectedQTY) {
                                    if (qty < item.quantity) {
                                        ele.selectedQTY =  ele.selectedQTY - (el.selectedQTY - Number(qty));
                                        el.selectedQTY = qty;
                                    } 
                                } else {
                                    ele.selectedQTY =  ele.selectedQTY - Number(qty);
                                    el.selectedQTY = qty;
                                };
                            };
                        });

                    };

                });
            };

            // console.log(qty);
            // console.log(item.selectedQTY);
        }

        // console.log("shadeupdate", shadeUpdate);
        // console.log("uom", this.state.unitCodes);

        this.setState({
            shade: shadeUpdate
        });

        this.calculation(shadeUpdate);
        // console.log("item", item);
    }

    calculation(shadeList = []) {

        // console.log("selectUOM", this.state.uom_data);
        // console.log("productP", this.state.price);

        // console.log("==============called calfun==============");
        // console.log("Shade List", shadeList);

        if (shadeList && shadeList.length > 0) {

            let total = 0;
            let totalQuantity = 0;
            let mainSubTotal = 0;

            shadeList.forEach((ele) => {
                if (Number(ele.quantity) > 0) {
                    if (Number(ele.selectedQTY) <= Number(ele.quantity)) {
                        if (this.state.unitCodes && this.state.unitCodes.length > 0) {
                            this.state.unitCodes.forEach((code) => {
                                if (code.name === ele.name) {
                                    code.unitCodes.forEach((e) => {
                                        let subTotal = 0;
                                        ele.uom.forEach((ite)=>{
                                            if (e.unitName === ite.unitName && ite.selectedQTY > 0) {
                                                // console.log("selected UOM", this.state.uom_data);
                                                // console.log("convFact", e.convFact);
                                                totalQuantity = Number(totalQuantity) + Number(ele.selectedQTY);
                                                if (this.state.type === "wholesaler") {
                                                    subTotal = parseFloat(e.convFact || 1) * parseFloat(ite.selectedQTY) * parseFloat(this.state.price) * parseFloat(this.state.multiplier || 1);
                                                } else {
                                                    subTotal = parseFloat(e.convFact || 1) * parseFloat(ite.selectedQTY) * parseFloat(this.state.price);
                                                }
                                                total = parseFloat(total) + parseFloat(subTotal);
                                                mainSubTotal = parseFloat(mainSubTotal) + parseFloat(subTotal);
                                            }
                                        })
                                    });
                                }
                            });
                        }
                    }
                }
            });

            // console.log("product price", this.state.price);
            // console.log("uom", this.state.uom_data);
            // console.log("type", this.state.utype, "multiplier", this.state.multiplier);
            // console.log("totalPqty", totalQuantity)
            // console.log("subTotal", mainSubTotal);
            // console.log("total", total);
            // console.log("unitcodes", this.state.unitCodes);

            // console.log("================end==============");

            this.setState({
                totalQuantity: totalQuantity,
                // totalAmount: total,
                productTotal: parseFloat(total || 0).toFixed(2),
                subTotal: parseFloat(mainSubTotal).toFixed(2)
            });

        }
    }

    customerType = (t) => {
        if (t.type === "AG") {
            return "retailer";
        } else if (t.type === "ASM" || t.type === "WHS") {
            return "wholesaler";
        } else {
           return "";
        }
    };
	
    ondropdownChangeagent = (e, t) => {
        this.setState({
            [e]: t, utype: t.type, filterCus: { filter: { agent: t.value }, skip: 0, limit: 1000000, customerType: this.customerType(t) },
            customer: "", indirectCustomer: "", filterTrans: {}, transporter: "", filterIndirectCus: { filter: { agent: t.value }, skip: 0, limit: 1000000 }
        });
        if (t.type === "AG") {
            this.setState({ type: "retailer" });
        } else if (t.type === "ASM") {
            this.setState({ type: "wholesaler" });
        } else if (t.type === "WHS") {
            this.setState({ type: "wholesaler" });
        } else {
            this.setState({ type: "" });
        }

        this.blankAllData("user");
    }

    dropdownValueChange = (value, name) => {
        // console.log(name, value);
        if (name === "brand") {
            this.setState({
                [name]: value,
                product: null,
                shade: [],
                totalAmount: 0,
                totalQuantity: 0,
                productTotal: 0,
                subTotal: 0,
                price: 0,
                filterProduct: {
                    brand: value.value
                },
                multiplier: value.multiplier
            }, () => {
                // this.setSeriesData()
            });
        } else if (name === "product") {
            // console.log(name, value.items.attributes);
            let tempArr = [value.items];

            // console.log("shade", this.state.shade);

            if (value && value.items && value.items.stockAttributes && value.items.stockAttributes.length > 0) {
                value.items.stockAttributes = value.items.stockAttributes.map((ele) => {
                    ele.selectedQTY = 0;
                    ele.stockType = "main";
                    return ele;
                });
            }
            if (value && value.items && value.items.forwardStockAttributes && value.items.forwardStockAttributes.length > 0) {
                value.items.forwardStockAttributes = value.items.forwardStockAttributes.map((ele) => {
                    ele.selectedQTY = 0;
                    ele.stockType = "forward";
                    return ele;
                });
            }
            if (value && value.items && value.items.attributes && value.items.attributes.length > 0) {
                value.items.attributes = value.items.attributes.map((attribute) => {
                    attribute.unitCodes = attribute.unitCodes.map((unit) => {  
                        unit.selectedQTY = 0;
                        return unit;
                    });
                    return attribute;
                });
            }           

            let shadeData = [];
            value.items.attributes.forEach((item)=>{
                const stockQty = value.items.stockAttributes.find((ite)=>ite.name === item.name);
                const forwardStockQty = value.items.forwardStockAttributes.find((ite)=>ite.name === item.name);
                if (stockQty.quantity > 0) {
                    return shadeData.push(Object.assign(stockQty, {uom : item.unitCodes}));
                } else if (forwardStockQty.quantity > 0) {
                    return shadeData.push(Object.assign(forwardStockQty, {uom : item.unitCodes}));
                } else {
                    return shadeData.push(Object.assign(stockQty, {uom : item.unitCodes}));
                };})

            // console.log(uomFilterData);
            this.setState({
                // totalAmount: 0,
                productTotal: 0,
                totalQuantity: 0,
                subTotal: 0,
                products: tempArr,
                product: value,
                price: value.items.price,
                unitCodes: value.items.attributes || [],
                shade: shadeData || [],
            }, () => {
                // console.log("before", this.state.shade);

                const sorter = (a, b) => {
                    // console.log(a, b);
                    const isNumber = (v) => (+v).toString() === v;
                    const aPart = a.name.match(/\d+|\D+/g);
                    const bPart = b.name.match(/\d+|\D+/g);
                    let i = 0; let len = Math.min(aPart.length, bPart.length);
                    while (i < len && aPart[i] === bPart[i]) { i++; };
                    if (i === len) {
                        return aPart.length - bPart.length;
                    };
                    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
                        return aPart[i] - bPart[i];
                    };
                    return aPart[i].localeCompare(bPart[i]);
                };

                if (this.state.shade && this.state.shade.length > 0) {
                    this.state.shade.sort(sorter);
                };

                // console.log("after", this.state.shade);
                // console.log(uomFilterData);

                this.setState({
                    shade: this.state.shade
                });
            });
        } else if (name === "shade") {
            this.setState({ [name]: value });
            this.calculation(this.state.shade || []);
        } else if (name === "type") {
            this.setState({ [name]: value, customer: '',  filterCus: {} }, () => {

                if (!this.state.agent) {
                    if (this.state.type === "wholesaler") {
                        this.setState({
                            filterCus: { customerType: "wholesaler", filter: {}, skip: 0, limit: 1000000 },
                            filterIndirectCus: { filter: { customerType: "Indirect" }, skip: 0, limit: 1000000 }
                        });
                    } else {
                        this.setState({
                            filterCus: { customerType: "retailer", filter: {}, skip: 0, limit: 1000000 },
                        });
                    }
                }

            });

        } else if (name === "customer") {
            this.setState({ [name]: value });
            if (name === "customer") {
                this.setState({
                    filterTrans: { customer: value.value }
                }, () => {
                    // console.log("customer", this.state.customer);
                    if (this.state.customer) {
                        this.setState({
                            filterIndirectCus: { filter: { wholesaler: value.value, agent: this.state.agent.value }, skip: 0, limit: 100000 }
                        });
                    }
                });
            }
        } else if (name === "orderTemplate") {
            this.setState({ [name]: value, templateQty: 1, alertMessage: "" });
            this.props.orderTemplate_get(value.value || undefined);
        } else {
            this.setState({ [name]: value });
        }
    }

    onClickButton = (index) => {
        if (this.state.selectedButtonIndex === index) {
            this.setState({ selectedButtonIndex: null, uom_name: false });
        } else {
            
            this.setState({ selectedButtonIndex: index, uom_name: true });
        }
    }

    blankAllData = (type) => {
        if (type === "user") {
            this.setState({
                brand: "",
                product: "",
                products: [],
                series: "",
                shade: [],
                totalAmount: 0,
                totalQuantity: 0,
                productTotal: 0,
                price: 0,
                customer: "",
                indirectCustomer: "",
                transporter: "",
                filterTrans: {},
                isLoading: false,
                getSingleLoader: false,
                filterProduct: {},
                note: "",
                unitCodes: [],
                multiplier: 1,
                subTotal: 0,
                series: ""
            });
        }

        if (type === "multiple") {
            this.setState({
                product: "",
                products: [],
                shade: [],
                // totalAmount: 0,
                productTotal: 0,
                price: 0,
                unitCodes: [],
                // multiplier: 1,
                subTotal: 0,
                totalQuantity: 0
            });
        }
    };

    checkBoxChange = (name, e) => this.setState({ [name]: e.target.checked });

    onsubmit = (e) => {
        if (this.state.isMultiple && this.state.multipleProducts && this.state.multipleProducts.length > 0) {

            let usingOrderTemplate = this.state.webFeature && this.state.webFeature.orderTemplate && this.state.orderTemplate ?
                this.state.webFeature.orderTemplate : false;

            let productListError = [];
            if (usingOrderTemplate) {
                //check all qty is below max qty or not
                let newProducts = this.state.multipleProducts || [];
                if (newProducts && newProducts.length > 0) {
                    newProducts.forEach((ele, i) => {
                        if (ele[0].productName) {
                            if (ele[0].attributes && ele[0].attributes.length > 0) {
                                ele[0].attributes.forEach((att) => {
                                    if (att.name) {
                                        if (Number(att.shadeQuantity) > Number(att.maxQty)) {
                                            // alert(`Maximum quantity is ${att.maxQty}`);
                                            productListError.push(`${ele[0].productName} [${att.name}] : ${att.maxQty}`);
                                        }
                                    };
                                });
                            }
                        }
                    });
                };
            }

            if (productListError && productListError.length === 0) {
                let savedProductsDetails = [];
                if (this.state.multipleProducts && this.state.multipleProducts.length > 0) {
                    this.state.multipleProducts.forEach((ele) => {
                        savedProductsDetails.push(ele[0]);
                    });
                }
                let finalProductArray = savedProductsDetails;
                if (finalProductArray && finalProductArray.length > 0) {
                    let duplicateP = finalProductArray.map((ele) => { return { name: ele.productName } });
                    let isAllow = this.checkIfArrayIsUnique(duplicateP);
                    if (!isAllow) {
                        let data = this.callFinalJson(finalProductArray);
                        data.totalAmount = 0;
                        data.totalQuantity = 0;
                        if (data.products && data.products.length > 0) {
                            data.products.forEach((ele) => {
                                data.totalAmount += Number(ele.subTotal);
                                data.totalQuantity += Number(ele.totalProductQty);
                            });
                        }
                        if (data.totalQuantity > 0) {
                            data.totalSaleAmount = parseFloat(data.totalAmount).toFixed(2);
                            data.totalPaidAmount = parseFloat(data.totalAmount).toFixed(2);
                            // console.log("payload", data);
                            this.setState({
                                isLoading: true,
                                getSingleLoader: false
                            });
                            this.orderCreate(data, e);
                        } else {

                            this.setState({
                                alertMessage: "Please select a minimum 1 Quantity to add the new product for Place Order."
                            });

                            // alert("Please select a minimum 1 Quantity to add the new product for Place Order.");
                        }

                    } else {
                        this.setState({
                            alertMessage: "Please check your added products details. Duplicate products are not allowed. Please remove it and try again to place the order."
                        });
                    }

                } else {
                    this.setState({
                        alertMessage: "Please add any product to Place Order."
                    });
                }
            } else {
                this.setState({
                    alertMessage: `Please check stock of ${productListError.join(", ")}.`
                });
            }
        } else {
            this.setState({
                alertMessage: "Please add any product to Place Order."
            });
        }
    };

    setDefaultSeriesList = () => {
        return {
            AG: [
                { label: "Please select series", value: "" },
                { label: "DTR", value: "DTR" },
                { label: "CDTR", value: "CDTR" }
            ],
            ASM: [
                { label: "Please select series", value: "" },
                { label: "WHS", value: "WHS" },
                { label: "WHB", value: "WHB" },
                { label: "CWHS", value: "CWHS" }
            ],
            ASM2: [
                { label: "Please select series", value: "" },
                { label: "WHS", value: "WHS" },
                { label: "WHB", value: "WHB" },
                { label: "CWHS", value: "CWHS" }
            ],
            RTL: [
                { label: "Please select series", value: "" },
                { label: "DTR", value: "DTR" },
                { label: "CDTR", value: "CDTR" }
            ],
            WHS: [
                { label: "Please select series", value: "" },
                { label: "WHS", value: "WHS" },
                { label: "WHB", value: "WHB" },
                { label: "CWHS", value: "CWHS" }
            ],
            SASM: [
                { label: "Please select series", value: "" },
                { label: "SASM", value: "SASM" }
            ],
            "": [
                { label: "Please select series", value: "" }
            ]
        }
    };

    // setSeriesData = () => {
    //     if (this.state.brand && this.state.brand.series && this.state.brand.series.length > 0) {
    //         this.state.seriesList[this.state.displaySeries[this.state.type]] = this.state.seriesList[this.state.displaySeries[this.state.type]].concat(this.state.brand.series);
    //         this.setState({
    //             seriesList: this.state.seriesList
    //         }, () => {
    //         });
    //     } else {
    //         this.setState({
    //             seriesList: this.setDefaultSeriesList()
    //         }, () => {
    //         });
    //     }
    // }

    checkIfArrayIsUnique = (myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            for (var j = 0; j < myArray.length; j++) {
                if (i !== j) {
                    // && myArray[i].uom === myArray[j].uom
                    if (myArray[i].name === myArray[j].name) {
                        return true; // means there are duplicate values
                    }
                }
            }
        }
        return false; // means there are no duplicate values.
    }

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            this.setState({ getSingleLoader: false });
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

            if (nextProps.uommanagement_res && nextProps.uommanagement_res.count > 0) {
                let displayUOM = {};
                nextProps.uommanagement_res.list.forEach((ele) => {
                    // console.log(ele);
                    displayUOM[ele.code] = ele.name;
                });
                this.setState({
                    uomDisplay: displayUOM
                });
                // console.log(disPlayUOM);
            };

            if (nextProps.companyConfigSingle_res && nextProps.companyConfigSingle_res.success === true) {
                if (nextProps.companyConfigSingle_res.item) {
                    this.setState({
                        webFeature: nextProps.companyConfigSingle_res.item.webFeature
                    });
                }
            };

            if (nextProps.orderTemplate_list_res && nextProps.orderTemplate_list_res.count > 0) {
                let list = nextProps.orderTemplate_list_res.list.map((ele) => {
                    return {
                        value: ele._id,
                        label: ele.name
                    }
                });
                this.setState({
                    orderTemplateList: list || []
                });
            };

            if (nextProps.orderTemplate_get_res && nextProps.orderTemplate_get_res.success) {
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.name]: getValue(nextProps.orderTemplate_get_res.item, value.name) })
                    } else if (value.type === "select") {
                        this.setState({
                            [value.name]: {
                                value: getValue(nextProps.orderTemplate_get_res.item, value.name)._id,
                                label: getValue(nextProps.orderTemplate_get_res.item, value.name).name
                            }
                        }, () => {
                            if (value.name === "brand") {
                                this.setState({
                                    filterProduct: getValue(nextProps.orderTemplate_get_res.item, value.name)._id
                                }, () => {
                                    this.props.productmanagementlist({ filter: { brand: this.state.brand && this.state.brand.value ? this.state.brand.value : undefined }, limit: 100000, skip: 0 });
                                });
                            };
                        });
                    } else if (value.type === "array") {
                        if (value.name === "products") {
                            this.setState({
                                isMultiple: true,
                                multipleProducts: this.convertMultipleProducts(nextProps.orderTemplate_get_res.item.products || [])
                            });
                        }
                    }
                });
                nextProps.orderTemplate_get_res.success = null;
            };

            if (nextProps.productmanagement_res && nextProps.productmanagement_res.count > 0) {
                // console.log(nextProps.productmanagement_res.list);
                this.setState({
                    stockList: nextProps.productmanagement_res.list || []
                }, () => {
                    this.setMaxQty();
                });
            };

        };

        return true;
    }

    onChangeFun = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value }, () => {
                if (name === 'templateQty') {
                    this.multiPlyQtyByTemplateQty()
                }
            });
        }

        // this.setState({ [e.target.name]: e.target.value });
    }

    multiPlyQtyByTemplateQty() {
        // console.log(this.state.multipleProducts);
        let updateProductQty = this.state.multipleProducts;
        // console.log(updateProductQty);
        if (updateProductQty && updateProductQty.length > 0) {
            updateProductQty.forEach((ele, idx) => {
                if (ele && ele.length > 0 && ele[0].attributes && ele[0].attributes.length > 0) {
                    ele[0].attributes.forEach((att) => {
                        // console.log(att.quantity, att.name, this.state.templateQty);
                        att.shadeQuantity = att.quantity * (this.state.templateQty || 1)
                        // console.log(att.shadeQuantity);
                        this.editRecord(ele, att, att.shadeQuantity, idx);
                    });
                }
            });
        };
        // console.log(updateProductQty);
        // this.setState({
        //     multipleProducts: updateProductQty
        // });

    }

    addMoreItem = () => {

        let valid = this.callCheckValidations();

        if (valid && this.state.totalQuantity > 0) {

            let productsArr = this.callSingleProsuctDetails();
            let data = this.callFinalJson(productsArr);

            if (this.state.type === "wholesaler") {
                data.wholesaler = data.customer;
                data.customer = this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined;
            }
            let finalData = this.state.multipleProducts;
            finalData.push(data.products);


            let ptotal = 0;

            if (data.products && data.products.length > 0) {
                data.products.forEach((ele) => {
                    ptotal += ele.totalAmount;
                });
            }
            const filteredData = finalData.map(productArray => 
                productArray.map(product => ({
                    ...product,
                    attributes: product.attributes.filter(attribute => attribute.quantity !== 0)
                }))
            );
            const uniqueQuantityUnits = new Set();
        
            filteredData.forEach(products => {
                products[0].attributes.forEach(attribute => {
                  uniqueQuantityUnits.add(attribute.unitCode.unitName);
                });
            });
            

            this.setState({
                quantityUnits: Array.from(uniqueQuantityUnits),
                isMultiple: true,
                multipleProducts: filteredData,
                productTotal: parseFloat(ptotal).toFixed(2)
            }, () => {
                this.countFinalTotal();
            });

            this.blankAllData("multiple");

            // console.log("Multiple Products", this.state.multipleProducts);

            this.setState({
                alertMessage: ""
            });

        } else if (this.state.totalQuantity === 0) {

            this.setState({
                alertMessage: "Please select a minimum 1 Quantity to add the new product for Place Order."
            });

            // alert("Please select a minimum 1 Quantity to add the new product for Place Order.");
        }

    }

    countFinalTotal = () => {
        let total = 0;
        if (this.state.multipleProducts && this.state.multipleProducts.length > 0) {
            this.state.multipleProducts.forEach((ele) => {
                // console.log(ele);
                total += ele[0].subTotal
            });
        }
        // console.log("total", total);
        this.setState({
            totalAmount: total
        });
        // console.log(this.state.totalAmount);
    }

    callSingleProsuctDetails = () => {

        let productsArr = [{
            product: this.state.product && this.state.product.value ? this.state.product.value : undefined,
            productName: this.state.product && this.state.product.label ? this.state.product.label : undefined,
            productErpCode: "",
            productPrice: this.state.price,
            attributes: [],
            subTotal: 0,
            totalProductQty: 0,
            subTotalQuantity:0,
            totalAmount: 0
        }];

        // console.log("shade", this.state.shade);
        // console.log("uom List", this.state.uom);
        // console.log("unitCodes", this.state.unitCodes);

        if (this.state.shade && this.state.shade.length > 0) {
            this.state.shade.forEach((sh) => {
                if (this.state.unitCodes && this.state.unitCodes.length > 0) {
                    // console.log('c-1');
                    this.state.unitCodes.forEach((code) => {
                        if (JSON.stringify(sh.name) === JSON.stringify(code.name) && Number(sh.selectedQTY) > 0) {
                            // console.log('c-2');
                            if (code && code.unitCodes.length > 0) {
                                // console.log('c-3');
                                code.unitCodes.forEach((cd) => {
                                    sh.uom.forEach((ite) => {
                                        if (JSON.stringify(cd.unitName) === JSON.stringify(ite.unitName) && ite.selectedQTY > 0) {
                                            // console.log('c-4');
                                            productsArr[0].subTotal = 0;
                                            productsArr[0].attributes.push({
                                                unitCode: { _id: cd._id, unitCode : cd.unitCode, unitName: cd.unitName,  convFact: cd.convFact ? cd.convFact : 1, shade: sh.name },
                                                convFact: cd.convFact || 1,
                                                productPrice: this.state.price,
                                                quantity: formatOfDecimal(ite.selectedQTY),
                                                shadeQuantity: formatOfDecimal(ite.selectedQTY),
                                                stockType: sh.stockType,
                                                shade: sh.name,
                                                code: sh.name,
                                                name: sh.name,
                                                price: 0,
                                                quantityUnit: ite.unitName || "NA"
                                            });

                                            if (this.state.type === "wholesaler") {
                                                productsArr[0].subTotal = parseFloat(cd.convFact || 1) * parseFloat(ite.selectedQTY) * parseFloat(this.state.price) * (this.state.multiplier || 1);
                                                // console.log("1", productsArr[0].subTotal);
                                            } else {
                                                productsArr[0].subTotal = parseFloat(cd.convFact || 1) * parseFloat(ite.selectedQTY) * parseFloat(this.state.price);
                                                // console.log("2", productsArr[0].subTotal);
                                            }
                                            productsArr[0].totalAmount = parseFloat(productsArr[0].totalAmount) + parseFloat(productsArr[0].subTotal);
                                            // productsArr[0].measurementUnit = measureData;
                                            // console.log("totalAMount", productsArr[0].totalAmount);
                                        }
                                    });
                                });
                            };
                            productsArr[0].totalProductQty += Number(sh.selectedQTY);
                            productsArr[0].subTotalQuantity += Number(sh.selectedQTY);
                        }
                    });
                }
            });
        }

        productsArr[0].subTotal = formatOfDecimal(productsArr[0].totalAmount);
        productsArr[0].subTotalQuantity = formatOfDecimal(productsArr[0].subTotalQuantity);
        productsArr[0].totalAmount = formatOfDecimal(productsArr[0].totalAmount);
        productsArr[0].totalProductQty = formatOfDecimal(productsArr[0].totalProductQty);
        return productsArr;
    }

    callFinalJson = (productsArr) => {
        if (productsArr && productsArr.length > 0) {
            productsArr.forEach((ele) => {
                if (ele && ele.attributes && ele.attributes.length > 0) {
                    ele.attributes.forEach((att) => {
                        att.quantity = att.shadeQuantity;
                    });
                }
            })
        };
        const data = {
            templateQty: this.state.templateQty,
            orderTemplate: this.state.orderTemplate && this.state.orderTemplate.value ? this.state.orderTemplate.value : undefined,
            brand: this.state.brand && this.state.brand.value ? this.state.brand.value : undefined,
            brandMultiplier: this.state.multiplier ? this.state.multiplier : 1,
            agent: this.state.agent && this.state.agent.value ? this.state.agent.value : undefined,
            type: this.state.type,
            transporter: this.state.transporter && this.state.transporter.value ? this.state.transporter.value : undefined,
            series: this.state.series || undefined,
            requestFrom: "B2B",
            totalQuantity: this.state.totalQuantity,
            totalAmount: parseFloat(this.state.totalAmount).toFixed(2),
            tax: 0,
            totalSaleAmount: parseFloat(this.state.totalAmount).toFixed(2),
            totalPaidAmount: parseFloat(this.state.totalAmount).toFixed(2),
            totalPendingAmount: 0,
            note: this.state.note,
            products: productsArr,
            latitude: "",
            logitude: "",
            customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined,
            company: JSON.parse(JSON.stringify(localStorage.getItem('userId'))),
            paymentTerms: this.state.paymentTerms,
            deliveryStation: this.state.deliveryStation,
            dispatchDate: this.state.dispatchDate,
            // wholesaler: this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined
            isThirdPartyOrder: this.state.isThirdPartyOrder
        }

        return data;
    }

    callCheckValidations = () => {
        let validationFields = [
            { name: 'customer', label: "Customer", value: this.state.customer, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 500, type: "text", message: "", errName: "customerError" },
            { name: 'user', label: "User", value: this.state.agent, hasError: false, isRequired: false, isNumber: false, onlyChar: true, maxLength: 500, type: "text", message: "", errName: "userError" },
            { name: 'transporter', label: "Transporter", value: this.state.transporter, hasError: false, isRequired: false, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "transporterError" },
            { name: 'series', label: "Series", value: this.state.series, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "seriesError" },
            { name: 'brand', label: "Brand", value: this.state.brand, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "brandError" },
            { name: 'type', label: "Customer Type", value: this.state.type, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "typeError" },
            { name: 'product', label: "Product", value: this.state.product, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "productError" },
            { name: 'indirectCustomer', label: "Indirect Customer", value: this.state.indirectCustomer, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "wholesalerError" }
        ];

        if (this.state.type !== "wholesaler") {
            validationFields.splice(7, 1);
        };

        if (this.state.type === "wholesaler") {
            validationFields.splice(0, 1);
        };

        // console.log(validationFields);

        let { newArr, valid } = validate(validationFields);

        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        return valid;
    }

    orderCreate = (data, e) => {

        if (this.state.type === "wholesaler") {
            data.wholesaler = data.customer;
            data.customer = this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined;
        }

        e.preventDefault();

        // console.log("final Data", data);

        if (data.totalQuantity > 0) {
            this.props.orderCreate(data);
        } else if (data.totalQuantity === 0) {
            this.setState({
                alertMessage: "Please select the qty of any shade to place the order."
            });

            // alert("Please select the qty of any shade to place the order.");
            // console.log("pls select the qty of any shade");
        }
    }

    deleteDuplicate = (e, index) => {

        e.preventDefault();
        let multipleProducts = this.state.multipleProducts;
        if (multipleProducts && multipleProducts.length > 0) {
            multipleProducts.forEach((ele, ind) => {
                if (index === ind) {
                    multipleProducts.splice(ind, 1);
                }
            });
        }
        const uniqueQuantityUnits = new Set();
        multipleProducts.forEach(products => {
                products[0].attributes.forEach(attribute => {
                  uniqueQuantityUnits.add(attribute.unitCode.unitName);
                });
            });

        this.setState({
            quantityUnits: Array.from(uniqueQuantityUnits),
            multipleProducts: multipleProducts
        }, () => {
            this.countFinalTotal()
        });

        // console.log("multiple", multipleProducts);
    }

    setMaxQty() {
        if (this.state.multipleProducts && this.state.multipleProducts.length > 0) {
            // console.log(this.state.multipleProducts, this.state.stockList);
            if (this.state.stockList && this.state.stockList.length > 0) {
                let productsList = this.state.multipleProducts || [];
                this.state.stockList.forEach((ele) => {
                    productsList.forEach((product) => {
                        if (ele.name === product[0].productName) {
                            if (ele.stockAttributes && ele.stockAttributes.length > 0 && product[0].attributes &&
                                product[0].attributes.length > 0) {
                                ele.stockAttributes.forEach((stock) => {
                                    product[0].attributes.forEach((atttr) => {
                                        if (atttr.name === stock.name) {
                                            atttr.maxQty = stock.quantity || 0;
                                        }
                                    });
                                });
                            }
                            // console.log(ele.name , product[0].productName)
                        }
                    });
                });

                // console.log(productsList);

                this.setState({
                    multipleProducts: productsList || []
                });

            }
        }
    };

    convertMultipleProducts(data) {
        let updateFromat = [];
        if (data && data.length > 0) {
            data.forEach((ele) => {
                ele.totalProductQty = 0;
                // console.log(ele);
                if (ele.attributes && ele.attributes.length > 0) {
                    ele.attributes.forEach((code) => {
                        ele.totalProductQty += Number(code.quantity);
                        code.shadeQuantity = Number(code.quantity);
                        code.productPrice = ele.productPrice;
                        code.shade = ele.measurementUnit.label;
                        code.code = ele.measurementUnit.label;
                        code.name = code.name;
                    });
                }
                updateFromat.push([ele]);
            });
        };
        return updateFromat;
    };

    editRecord = (item, attr, value, index) => {
        // let maxQtyList = [];
        let newProducts = this.state.multipleProducts || [];
        if (newProducts && newProducts.length > 0) {
            newProducts.forEach((ele, i) => {
                if (ele[0].productName == item[0].productName) {
                    ele[0].totalProductQty = 0;
                    ele[0].subTotalQuantity = 0;
                    ele[0].subTotal = 0;
                    if (ele[0].attributes && ele[0].attributes.length > 0) {
                        ele[0].attributes.forEach((att) => {
                            // console.log(attr.name, att.name, index, i);
                            if (att.name == attr.name && index == i) {
                                // att.quantity = value;
                                att.shadeQuantity = value;
                                if (Number(attr.shadeQuantity) > Number(attr.maxQty)) {
                                    // maxQtyList.push(`Maximum quantity is ${attr.maxQty} of ${att.name}`);
                                    // alert(`Maximum quantity is ${attr.maxQty} of ${att.name}`);
                                    // att.quantity = attr.maxQty;

                                    // att.shadeQuantity = attr.maxQty;
                                }
                            };
                            ele[0].subTotal = Number(ele[0].subTotal) +
                                (!isNaN(parseFloat(att.convFact) * parseFloat(att.shadeQuantity) * parseFloat(ele[0].productPrice)) ? parseFloat(att.convFact) * parseFloat(att.shadeQuantity) * parseFloat(ele[0].productPrice) : 0);
                            ele[0].totalProductQty += (att.shadeQuantity ? Number(att.shadeQuantity) : 0);
                            ele[0].subTotalQuantity += (att.shadeQuantity ? Number(att.shadeQuantity) : 0);

                        });
                    }
                }
            });
        };

        this.setState({
            multipleProducts: newProducts
        }, () => {
            // if (maxQtyList && maxQtyList.length > 0) {
            //     alert(maxQtyList.length);
            // }
            this.countFinalTotal();
        });

    };

    render() {
        const { isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">

                                <div className="card-header">
                                    <div className="card-title">
                                        Create Order
                                    </div>
                                </div>

                                {
                                    getSingleLoader ? <Loader /> :

                                        <div className="card-body">

                                            <div className="row">

                                                <div className="form-group col-12 col-lg-4">
                                                    <Userdropdown
                                                        ondropdownChange={this.ondropdownChangeagent}
                                                        name="agent"
                                                        value={this.state.agent}
                                                        className="form-control form-control-square"
                                                        placeholder="User"
                                                        errMessage={this.state.userError}
                                                        isDisabled={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                    />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <SelectInputField label="Customer Type" errMessage={this.state.typeError}
                                                        onChange={(e) => this.dropdownValueChange(e.target.value, "type")} name="type" value={this.state.type}
                                                        disabled={(["AG", "WHS", "ASM"].indexOf(this.state.utype) < 0 ? false : true) || (this.state.isMultiple && this.state.multipleProducts.length > 0)} className="form-control" options={this.state.customerTypeOptions} />
                                                </div>

                                                <div className={this.state.type === "wholesaler" ? "form-group  col-12 col-lg-4" : "form-group required col-12 col-lg-4"} id={"customer"}>
                                                    <CustomerDropDown
                                                        ondropdownChange={(e) => this.dropdownValueChange(e, "customer")}
                                                        placeholder="Search Customer"
                                                        preFixValue={"customer"}
                                                        value={this.state.customer}
                                                        filterBy={this.state.filterCus}
                                                        name="customer"
                                                        dontPreLoad={((this.state.agent || this.state.type) && this.state.customer === "" && this.state.filterCus && this.state.filterCus.customerType) ? false : true}
                                                        errMessage={this.state.customerError}
                                                        disable={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                    />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Transporterdropdown
                                                        ondropdownChange={(e) => this.dropdownValueChange(e, "transporter")}
                                                        placeholder="Search Transporter"
                                                        value={this.state.transporter}
                                                        filterBy={this.state.filterTrans}
                                                        dontPreLoad={this.state.customer ? false : true}
                                                        errMessage={this.state.transporterError}
                                                        disable={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                    />
                                                </div>

                                                {
                                                    this.state.type === "wholesaler" ?
                                                        <div className="form-group required col-12 col-lg-4" id={"indirectCus"}>
                                                            <Wholesalerdropdown
                                                                isWhLabel={true}
                                                                label="Indirect Customer"
                                                                ondropdownChange={(e) => this.dropdownValueChange(e, "indirectCustomer")}
                                                                placeholder="Search Indirect Customer"
                                                                value={this.state.indirectCustomer}
                                                                filterBy={this.state.filterIndirectCus}
                                                                dontPreLoad={(this.state.agent || this.state.customer || this.state.type) ? false : true}
                                                                errMessage={this.state.wholesalerError}
                                                                disable={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                            />
                                                        </div> : null
                                                }

                                                {/* {console.log("render", this.state.seriesList[this.state.displaySeries[this.state.type]])} */}

                                                <div className="form-group required col-12 col-lg-4">
                                                    <SelectInputField label="Series" errMessage={this.state.seriesError}
                                                        disabled={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                        onChange={(e) => this.dropdownValueChange(e.target.value, "series")} name="series" value={this.state.series}
                                                        className="form-control" options={this.state.seriesList[this.state.displaySeries[this.state.type]] || this.state.seriesList[""]} />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <TextAreaInputField
                                                        onChange={(e) => this.dropdownValueChange(e.target.value, "note")}
                                                        type="text"
                                                        name="note"
                                                        maxLength="5000"
                                                        value={this.state.note}
                                                        className="form-control"
                                                        placeholder="Note"
                                                    />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.onChangeFun} maxlength="200" type="text" name="paymentTerms"
                                                        value={this.state.paymentTerms} className="form-control" id="paymentTerms" aria-describedby="paymentTermsHelp" placeholder="Payment Terms" />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.onChangeFun} maxlength="200" type="text" name="deliveryStation"
                                                        value={this.state.deliveryStation} className="form-control" id="deliveryStation" aria-describedby="deliveryStationHelp" placeholder="Delivery Station" />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <label htmlFor="exampleInputName1" className="control-label">Dispatch Date</label>
                                                    <input type="date" onChange={(e) => this.dropdownValueChange(e.target.value, "dispatchDate")} name="dispatchDate"
                                                        value={this.state.dispatchDate} className="form-control" id="dispatchDate" min={new Date().toISOString().split('T')[0]} max={this.state.maxDate} aria-describedby="dispatchDateHelp" placeholder="Dispatch Date" />
                                                </div>


                                                {
                                                    this.state.webFeature && this.state.webFeature.orderTemplate ?
                                                        <Fragment>

                                                            <div className="form-group col-12 col-lg-4">
                                                                <label className="control-label">Order Template
                                                                </label>
                                                                <Select
                                                                    classNamePrefix="orderTemplate"
                                                                    instanceId='orderTemplate-id'
                                                                    name="orderTemplate"
                                                                    placeholder='Select Order Template'
                                                                    onChange={(e) => this.dropdownValueChange(e, "orderTemplate")}
                                                                    options={this.state.orderTemplateList}
                                                                    value={this.state.orderTemplate} />
                                                            </div>

                                                            <div className="form-group col-12 col-lg-4">
                                                                <Inputfield handleChange={this.onChangeFun} type="number" name="templateQty"
                                                                    value={this.state.templateQty} className="form-control" id="templateQty" placeholder="Template Qty" />
                                                            </div>

                                                        </Fragment>
                                                        : null
                                                }

                                                <div className="form-group required col-12 col-lg-4">
                                                    <SingleBrandDropDown
                                                        value={this.state.brand}
                                                        ondropdownChange={(e) => this.dropdownValueChange(e, "brand")}
                                                        placeholder="Search Brand"
                                                        errMessage={this.state.brandError}
                                                        disable={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                    />
                                                </div>


                                            </div>

                                            {
                                                this.state.orderTemplate ? null :
                                                    <Fragment>
                                                        <div className="row">
                                                            <div className='col-md-12'>

                                                                <div className='alert alert-primary'>

                                                                    <div className='row'>
                                                                        <div className="form-group col-12 col-lg-4">
                                                                            {
                                                                                <ProductDropdown
                                                                                    isLabel={true}
                                                                                    ondropdownChange={(e) => this.dropdownValueChange(e, "product")}
                                                                                    placeholder="Search Product"
                                                                                    value={this.state.product}
                                                                                    errMessage={this.state.productError}
                                                                                    dontPreload={this.state.product === null ? false : true}
                                                                                    filterBy={this.state.filterProduct}
                                                                                />
                                                                            }
                                                                        </div>

                                                                        {/* <div className="form-group col-12 col-lg-4">
                                                                            <SelectInputField label="UOM" errMessage={this.state.uomError}
                                                                                onChange={(e) => this.dropdownValueChange(e.target.value, "uom_data")} name="uom_data" value={this.state.uom_data}
                                                                                className="form-control" options={this.state.uom} />
                                                                        </div> */}

                                                                        {/* {console.log(this.state.shade.length)} */}

                                                                        {
                                                                            this.state.utype === "WHS" ?
                                                                                <div className="form-group required col-12 col-lg-4"></div>
                                                                                : null
                                                                        }

                                                                        {
                                                                            this.state.shade && this.state.shade.length > 0 && this.state.product ?
                                                                                <div className='w-100'>                                                                               
                                                                                    <div className='col-lg-6'>
                                                                                        <div
                                                                                            className="table-responsive product-table"
                                                                                        >
                                                                                            <table
                                                                                                className="table"
                                                                                            >
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>&nbsp;</th>
                                                                                                        <th><div className='ml-2'>shade Name </div></th>
                                                                                                        <th><div className='ml-2'> Qty</div> </th>
                                                                                                        <th><div>Max Qty </div></th>
                                                                                                    </tr>
                                                                                                </thead>

                                                                                                {this.state.shade.map((ele, idx) =>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td scope="row"><div className='uombtn'>
                                                                                                        <a
                                                                                                            name=""
                                                                                                            id=""
                                                                                                            className="btn btn-light uom-sidebtn"
                                                                                                            onClick={(e) => this.onClickButton(idx)}
                                                                                                            role="button"
                                                                                                        >
                                                                                                            <i className={this.state.uom_name && this.state.selectedButtonIndex === idx ? "fa fa-angle-up" : "fa fa-angle-down"} style={{ float: 'right' }}></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    </td>
                                                                                                        <td>
                                                                                                        <Inputfield noLabel="true" type="text" className="form-control" name={idx} id={ele.name + "_" + idx} placeholder="Shade name" value={ele.name} disabled="true" />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                        <div key={idx + "select_qty"}>
                                                                                                                <input type="number" className="form-control border-radius-0 text-center ml-2" name="quantity" id={ele.name + "_quantity_" + idx} placeholder="QTY" value={ele.selectedQTY} disabled="true" />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                        {ele.quantity || 0}
                                                                                                        {ele.stockType === "forward" ? <span className='text-primary'><b> [FWD] </b>  </span> : <span></span>}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {this.state.selectedButtonIndex === idx ?
                                                                                                        ele.uom.map((el, idxe) =>
                                                                                                            <tr className="inner-row" key={idx}>
                                                                                                                <td>
                                                                                                                    &nbsp;
                                                                                                                </td>
                                                                                                                <td  key={idxe + "name"}>
                                                                                                                    <Inputfield noLabel="true" type="text" className="form-control" name={idxe} id={el.unitName + "_" + idxe} placeholder="Shade name" value={this.state.uomDisplay[el.unitName] ? this.state.uomDisplay[el.unitName] : el.unitName} disabled="true" />
                                                                                                                </td>

                                                                                                                <td className="qty-ml" key={idx + "minus"}>
                                                                                                                    <div className='d-flex align-items-center ml-2'>
                                                                                                                        <button className="btn btn-danger border-radius-0" onClick={(e) => this.QTYfun(ele, "minus", idx, e.target.value, el.unitName)} type="button">-</button>
                                                                                                                        <input type="number" className="form-control min-80 border-radius-0 text-center" name="quantity" id={ele.name + "_quantity_" + idx} placeholder="QTY" onChange={(e) => this.QTYfun(ele, "change", idx, e.target.value, el.unitName)} max={ele.quantity} min={0} value={el.selectedQTY} />
                                                                                                                        <button className="btn btn-primary border-radius-0" onClick={(e) => this.QTYfun(ele, "add", idx, e.target.value, el.unitName)} type="button">+</button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td key={idx + "plus"}>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                        : null
                                                                                                    }
                                                                                                </tbody>
                                                                                                ) }
                                                                                                
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : this.state.product ? <p className="col-12 mt-2 mb-2 text-center text-danger">Stock is unavailable</p> : null
                                                                        }
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className='col-md-3 col-12 mt-md-5 '>
                                                                            <label className="mr-2">Total Amount Per Product</label>
                                                                            <p className="form-control border border-primary rounded text-right">{parseFloat(this.state.productTotal || 0).toFixed(2)} /-</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6 mb-4 mt-3 offset-md-3 text-center">
                                                                <Buttons onclick={this.addMoreItem} type="button" className="btn btn-success mr-1" name="Add" />
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                            }

                                            {
                                                this.state.alertMessage ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-danger  text-center'>
                                                                <span className='mb-0'>{this.state.alertMessage}</span>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }

                                            {
                                                this.state.isMultiple && this.state.multipleProducts && this.state.multipleProducts.length > 0 ?
                                                    <table className="table table-bordered order-list-data">
                                                        <thead>
                                                            <tr>
                                                                <th className='border-radius-0 p-2'>Product Name</th>
                                                                <th className='border-radius-0 p-2'>Shade 
                                                                    {
                                                                        this.state.webFeature && this.state.webFeature.orderTemplate && this.state.orderTemplate ?
                                                                            "  -  Max Qty"
                                                                            : null
                                                                    }
                                                                </th>
                                                                {
                                                                    this.state.quantityUnits ?
                                                                        this.state.quantityUnits.map((item, i) => 
                                                                        <th className='border-radius-0 p-2' key={i}>{this.state.uomDisplay[item] ? this.state.uomDisplay[item] : item}</th> 
                                                                        ) 
                                                                    : null
                                                                }
                                                                <th className='border-radius-0 p-2'>Total Qty</th>
                                                                <th className='border-radius-0 p-2'>Product Price</th>
                                                                {/* <th className='border-radius-0 p-2'>Conv. Fact</th> */}
                                                                <th className='border-radius-0 p-2'>Total</th>
                                                                <th className='border-radius-0 p-2 text-center'>Remove</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.multipleProducts.map((productData, index) => (
                                                                productData.map((product, idx) => {
                                                                    const uniqueShades = [...new Set(product.attributes.map(attr => attr.shade))];
                                                                    return uniqueShades.map((shade, i) => {
                                                                        const shadeAttributes = product.attributes.filter(attr => attr.shade === shade);
                                                                        return (
                                                                        <tr key={idx + ""}>
                                                                            {i === 0 && (
                                                                                <td key={idx + "qty"} rowSpan={uniqueShades.length}>{product.productName}</td>
                                                                            )}
                                                                            <td className='shade-cell' key={idx + "shade"}>
                                                                                {shade} {shadeAttributes[0].stockType === "forward" ?<span className='text-primary'><b> [FWD] </b></span>:""}
                                                                            </td>
                                                                            {this.state.quantityUnits.map((unit, j) => {
                                                                            const unitAttribute = shadeAttributes.find(attr => attr.unitCode.unitName === unit);
                                                                            return (
                                                                                <td key={j}>{unitAttribute ? unitAttribute.quantity === "" ? 0 : unitAttribute.quantity : 0}</td>
                                                                            );
                                                                            })}
                                                                            {i === 0 && (
                                                                            <>
                                                                                <td key={idx + "pqty"} rowSpan={uniqueShades.length}>{product.totalProductQty}</td>
                                                                                <td key={idx + "price"} rowSpan={uniqueShades.length} >{product.productPrice}</td>
                                                                                <td key={idx + "subTotal"} rowSpan={uniqueShades.length} >{product.subTotal}</td>
                                                                                <td className='text-center' key={idx + "delete"} rowSpan={uniqueShades.length}>
                                                                                    <a className='btn btn-link text-danger action-btn p-0' onClick={(e) => this.deleteDuplicate(e, index)}><i className='ti ti-trash'></i></a>
                                                                                </td>
                                                                            </>
                                                                            )}
                                                                        </tr>
                                                                        );
                                                                    });
                                                                    })
                                                               
                                                        ))}
                                                            <tr>
                                                                <td colSpan={2} className='text-right'><b>Total :</b></td>
                                                                {this.state.quantityUnits.map((unit, unitIndex) => {
                                                                    let totalQuantity = 0;
                                                                    
                                                                    this.state.multipleProducts.forEach((productData) => {
                                                                        productData[0].attributes.forEach((item) => {
                                                                            if (item.unitCode.unitName === unit) {
                                                                                totalQuantity += Number(item.quantity);
                                                                            }
                                                                        });
                                                                    });
                                                                    
                                                                    return <td key={unitIndex}><b>{formatOfDecimal(totalQuantity)}</b></td>;
                                                                })}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    : null
                                            }

                                            {
                                                this.state.webFeature && this.state.webFeature.orderTemplate ?
                                                    <div className="form-group col-12 col-lg-4">
                                                        <Inputfield handleChange={this.checkBoxChange} type="checkbox" name="isThirdPartyOrder" checked={this.state.isThirdPartyOrder} placeholder="Third Party Order ? " className="ml-2" />
                                                    </div> : null
                                            }

                                            <div className="row  justify-content-end">
                                                <div className='col-3 mt-5 text-right'>
                                                    <label className="mr-2">Total Amount</label>
                                                    <p className="form-control border border-primary rounded ">{parseFloat(this.state.totalAmount || 0).toFixed(2)}/-</p>
                                                </div>
                                            </div>


                                            <div className="row">
                                                {/* {console.log(getSingleLoader , isLoading)} */}
                                                <div className="col-md-6 mb-4 mt-3 offset-md-3 text-center">
                                                    {/* <Buttons onclick={this.addMoreItem} type="button" className="btn btn-success mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name="Add More" /> */}
                                                    <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name="Place Order" />
                                                    <Links to={routehelp.ordermanagement} className="btn btn-outline-dark" name="Cancel" />
                                                </div>
                                            </div>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    };

}

function mapStateToProps(state) {
    return {
        uommanagement_res: state.uommanagement_res,
        order_create: state.order_create,
        orderTemplate_list_res: state.orderTemplate_list_res,
        orderTemplate_get_res: state.orderTemplate_get_res,
        companyConfigSingle_res: state.companyConfigSingle_res,
        productmanagement_res: state.productmanagement_res
    };
}

const mapDispatchToProps = dispatch => ({
    orderTemplate_list: data => dispatch(orderTemplate_list(data)),
    orderTemplate_get: item_id => dispatch(orderTemplate_get(item_id)),
    uommanagementlist: data => dispatch(uommanagementlist(data)),
    orderCreate: data => dispatch(orderCreate(data)),
    companyConfigSingle: data => dispatch(companyConfigSingle(data)),
    productmanagementlist: data => dispatch(productmanagementlist(data))
});

OrderCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderCreate);

export default OrderCreate;