import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { brandmanagementlist, zeroStockList, profilesingle, sendNotifications, zeroStockExport } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, loaderUpdate, fileDownload } from '../../helpers/functions';
import { SORT_BY, SORT, ITEM_PER_PAGES, CURRENT_PAGE } from '../../helpers/constants';
import { Link } from 'react-router-dom';
import toastr from 'reactjs-toastr';
import { confirmAlert } from 'react-confirm-alert';
import ButtonWithItag from '../../components/buttonwithTagi';

const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Loader = lazy(() => import('../../components/loader'));
const Entries = lazy(() => import('../../components/entries/entires'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class ZeroStockList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSelectRemove: 'select-all',
            isLoading: false,
            isExportLoading: false,
            successRequest: ["sendNotifications_res"],
            serverErrors: ["zeroStockList_res", "zeroStockList_update", "brandmanagement_res", "sendNotifications_res", "zeroStockExport_res"],
            allProductsTableHeaders: [
                {
                    name: 'notificationData.product',
                    label: "Product Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'notificationData.shade',
                    label: "Shade Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'notificationData.brand',
                    label: "Brand Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'notificationData.orderDate',
                    label: "Order Date",
                    type: "date_time",
                    sortable: false,
                    isShow: true
                }
            ],
            brandHeaders: [
                {
                    name: 'name',
                    label: "Brand Name",
                    // type: "text",
                    // sortable: true,
                    // isShow: true
                },
                {
                    name: 'code',
                    label: "Brand Code",
                    // type: "text",
                    // sortable: true,
                    // isShow: true
                }
            ],
            selectedViewType: "all",
            viewTypeList: [
                { label: "Brand wise Products", value: "brand" },
                { label: "All Products", value: "all" }
            ],
            notificationType: "default",
            selectedNotificationData: [],
            zeroStockList_res: {},
            mappingObj: {
                true: "Sent",
                false: "Pending",
                undefined: "Pending"
            }
        }
    }

    componentDidMount() {
        currentPage = 1;
        itemsPerPage = 50;
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.callCompanyProfile();
        this.loadResult();
    }

    callCompanyProfile() {
        let data = {};
        this.props.profilesingle(data);
        this.setState({ isLoading: true, isFilter: false });
    }

    export = () => {
        this.setState({
            isExportLoading: true
        });
        let companyID = JSON.parse(JSON.stringify(localStorage.getItem('userId')));
        this.props.zeroStockExport({
            company: companyID,
            brand: this.state.brandID ? this.state.brandID :null,
        });
    };

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        };
        let limit = itemsPerPage;
        let skip = (currentPage - 1) * itemsPerPage;
        let companyID = JSON.parse(JSON.stringify(localStorage.getItem('userId')));
        this.props.zeroStockList({
            company: companyID,
            brand: this.state.brandID ? this.state.brandID : null,
            limit: limit,
            skip: skip
        });
        this.setState({ isLoading: true, isFilter: false });
    }

    subAlertForCheckData = (type, perpage) => {
        if (type === "handleNext") {
            currentPage = currentPage + 1;
        } else if (type === "handlePrevious") {
            currentPage = currentPage - 1;
        } else if (type === 'handlePage') {
            currentPage = perpage;
        } else if (type === 'handleChangeiItemsPerPage') {
            currentPage = 1;
            itemsPerPage = perpage;
        };
        this.loadResult();
    }

    alertForCheckData = (type, perpage) => {
        if (this.state.selectedNotificationData.length > 0 && type) {
            confirmAlert({
                title: '',
                message: <h2>Do you want to change this page ? </h2>,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.subAlertForCheckData(type, perpage);
                            this.setState({ isSelectRemove: "select-all", selectedNotificationData: [] });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { "" }
                    },
                ]
            });
        } else if (type) {
            this.subAlertForCheckData(type, perpage);
        }
    }

    handleNext = () => {
        this.alertForCheckData("handleNext", '');
    }

    handlePrevious = () => {
        this.alertForCheckData("handlePrevious", '');
    }

    handlePage = (page) => {
        this.alertForCheckData("handlePage", page);
    }

    handleChangeiItemsPerPage = (items) => {
        this.alertForCheckData("handleChangeiItemsPerPage", items);
    }

    callBrandList = () => {
        let data = {};
        this.props.brandmanagementlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                if (this.state.selectedViewType === "all") {
                    this.setState({ isLoading: loaderUpdate(nextProps, "zeroStockList_res") });
                } else {
                    this.setState({ isLoading: loaderUpdate(nextProps, "brandmanagement_res") });
                }
            }

            if (nextProps && nextProps.zeroStockExport_res && nextProps.zeroStockExport_res !== undefined) {
                this.setState({ isExportLoading: false });
                fileDownload(nextProps, "zeroStockExport_res", "fileName");
            };

            this.state.serverErrors.forEach((el) =>
                errorRender(nextProps, el, el === "zeroStockList_update"
                    ? this.loadResult : undefined)
            );

            this.state.successRequest.forEach((el) =>
                successRender(
                    nextProps,
                    el.name,
                    this.loadResult,
                    el.msg
                ));

            // console.log(nextProps);
            if (nextProps.profile_single && nextProps.profile_single.notificationType && this.props !== nextProps) {
                this.setState({
                    notificationType: nextProps.profile_single.notificationType || "default"
                }, () => {
                    this.changeNotificationType();
                })
            }
            if (this.state.selectedNotificationData.length === 0) {
                if (nextProps.zeroStockList_res && nextProps.zeroStockList_res !== undefined && this.props !== nextProps) {
                    let updatedZeroStockRes = nextProps.zeroStockList_res;
                    if (nextProps.zeroStockList_res && nextProps.zeroStockList_res.list && nextProps.zeroStockList_res.list.length > 0) {
                        updatedZeroStockRes.list = updatedZeroStockRes.list.filter((ele) => {
                            ele.isNotificationSend = false;
                            return ele;
                        });
                    }
                    this.setState({
                        zeroStockList_res: updatedZeroStockRes
                    });
                }
            }

            if (nextProps.sendNotifications_res !== undefined && nextProps.sendNotifications_res.success === true && this.props !== nextProps) {
                // console.log(nextProps.sendNotifications_res);
                toastr.success('Notification send request is done', 'success');
                this.setState({
                    selectedNotificationData: [],
                    isLoading: false,
                    isSelectRemove: 'select-all'
                });
                nextProps.sendNotifications_res.success = null;
            }
        }
        return true;
    }

    changeNotificationType = () => {
        // console.log(this.state.notificationType);
        if (this.state.notificationType === "custom") {
            let addCustomOption = this.state.allProductsTableHeaders;

            if (addCustomOption.filter((ele) => ele.label === "Status").length === 0) {
                addCustomOption.push({
                    name: 'isSend',
                    label: "Status",
                    type: "boolean",
                    sortable: false,
                    isShow: true
                });
            }

            if (addCustomOption.filter((ele) => ele.label === "Actions").length === 0) {
                addCustomOption.push({
                    name: 'isNotificationSend',
                    label: "Actions",
                    type: "checkbox",
                    sortable: false,
                    isShow: true,
                    function: this.checkedData
                });
            }

            this.setState({
                allProductsTableHeaders: addCustomOption
            });

        }
    }

    checkedData = (e, item) => {

        //update the zerostock List
        let updateZeroStockList = this.state.zeroStockList_res.list || [];
        updateZeroStockList.forEach((ele) => {
            if (ele._id === item._id) {
                // console.log(ele, ele._id, item._id);
                ele.isNotificationSend = e.target.checked;
            }
        });
        // this.setState({
        //     zeroStockList_res: {
        //         count: updateZeroStockList.length,
        //         list: updateZeroStockList
        //     }
        // });

        if (this.state.isSelectRemove !== 'remove-all') {
            //build sendData Array
            let tempSelectedData = this.state.selectedNotificationData || [];
            if (e.target.checked) {
                tempSelectedData.push(item);
            } else {
                tempSelectedData.forEach((ele, index) => {
                    if (ele._id === item._id) {
                        // console.log(ele, ele._id, item._id);
                        tempSelectedData.splice(index, 1);
                    }
                });
            }
            if ((this.state.zeroStockList_res.list.length === this.state.selectedNotificationData.length)) {
                this.setState({
                    isSelectRemove: 'remove-all'
                });
            };
            this.setState({
                selectedNotificationData: tempSelectedData
            });
            // console.log(e.target.checked, item);
        } else {
            let removeListElement = this.state.selectedNotificationData;
            if (removeListElement && removeListElement.length > 0) {
                removeListElement = removeListElement.filter((ele) => ele._id !== item._id);
                this.setState({
                    selectedNotificationData: removeListElement
                });
                if ((this.state.zeroStockList_res.list.length !== removeListElement.length)) {
                    this.setState({
                        isSelectRemove: 'select-all'
                    });
                };
            }
        }
        // console.log("tempSelectedData", this.state.selectedNotificationData);
    }

    selectAll = (ev) => {
        let updateZeroStockList = this.state.zeroStockList_res.list || [];
        updateZeroStockList.forEach((ele) => {
            ele.isNotificationSend = true;
        });
        this.setState({
            // zeroStockList_res: {
            //     count: updateZeroStockList.length,
            //     list: updateZeroStockList
            // }
        }, () => {
            this.setState({
                selectedNotificationData: updateZeroStockList,
                isSelectRemove: 'remove-all'
            });
        });
    }

    removeAll = (ev) => {
        let updateZeroStockList = this.state.zeroStockList_res.list || [];
        updateZeroStockList.forEach((ele) => {
            ele.isNotificationSend = false;
        });
        this.setState({
            // zeroStockList_res: {
            //     count: updateZeroStockList.length,
            //     list: updateZeroStockList
            // }
        }, () => {
            this.setState({
                selectedNotificationData: [],
                isSelectRemove: 'select-all'
            })
        });
    }

    sendNotification = () => {
        if (!this.state.selectedNotificationData || (this.state.selectedNotificationData && this.state.selectedNotificationData.length === 0)) {
            alert("Please select at least one record to send the notification");
            return true;
        } else {
            let finalArrayToSend = this.state.selectedNotificationData || [];
            finalArrayToSend = finalArrayToSend.map(ele => ele._id);
            this.setState({
                isLoading: true,
                selectedNotificationData:[],
            });
            this.props.sendNotifications({
                list: finalArrayToSend
            });
        }

    }

    callforDebounce = () => this.loadResult();

    viewChange = (value) => {
        // console.log("view change", this.state.selectedViewType, value);
        if (value === "brand") {
            this.setState({
                selectedViewType: value,
                selectedNotificationData: [],
                isSelectRemove:"select-all",
            }, () => {
                if (this.state.selectedViewType === "brand") {
                    this.callBrandList();   
                } else {
                    this.loadResult();
                }
            });
        }
    }

    callBrandWiseProducts = (brandID) => {
        // console.log("BRAND ID", brandID);
        this.setState({
            selectedViewType: "all",
            brandID: brandID,
            isFilter: true,
        }, () => {
            this.loadResult();
        });
    }

    render() {
        const { brandmanagement_res } = this.props;
        const totalBrands = brandmanagement_res !== undefined ? brandmanagement_res.count : 0;

        let zeroStockList_res = this.state.zeroStockList_res;
        const totalZeroStockResult = zeroStockList_res !== undefined ? zeroStockList_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="mr-3 mt-2 text-right">
                                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                </div>
                                <div className="card-header d-flex">

                                    <div className="card-title col-3">
                                        Zero Stock Products
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            {/* <div className="col-sm-12 col-md-6">
                                                {totalZeroStockResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div> */}
                                            {/* <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    <LinkWithItag to={routehelp.usermanagementadd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add User" />
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="alert alert-info font-weight-bold">
                                                    Note: Last 15 days product data is displayed.
                                                </div>
                                                <div className="row">   
                                                    <div className="col-sm-12 col-md-6">
                                                        {this.state.selectedViewType === "all" ? totalZeroStockResult && totalZeroStockResult > 0 ? <Entries
                                                            itemsPerPage={itemsPerPage}
                                                            handleChangeiItemsPerPage={
                                                                this.handleChangeiItemsPerPage
                                                            }
                                                        /> : null : null}
                                                    </div>

                                                    <div className="card-title col-6 ml-auto d-flex justify-content-end">
                                                        <div className='col-6'>
                                                            <select
                                                                className="form-control"
                                                                name="selectedViewType"
                                                                value={this.state.selectedViewType}
                                                                onChange={(e) => this.viewChange(e.target.value)}
                                                                id="selectedViewType">
                                                                {
                                                                    this.state.viewTypeList.map((ele, key) =>
                                                                        <option value={ele.value} id={key} key={key}>{ele.label}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                        
                                                        { this.state.selectedViewType !== "brand"  ?
                                                            <div className="add-btn-icon ">
                                                                <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} type="button" onclick={this.export} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Upload all" />
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                {/* {
                                                    this.state.selectedViewType === "all" ?
                                                        <div className="table-responsive">
                                                            <div>
                                                                {
                                                                    this.state.isLoading ? <Loader /> :
                                                                        totalZeroStockResult === undefined ? <p className="text-center">No Data Found</p> : zeroStockList_res !== undefined && zeroStockList_res.count === 0 ?
                                                                            <p className="text-center">No Data Found</p> :
                                                                            zeroStockList_res && zeroStockList_res.count > 0 ?
                                                                                <DataTable mappingObj={this.state.mappingObj} headers={this.state.allProductsTableHeaders} sortByCol={this.sortBy}
                                                                                    sortByvalue={sortBy} sortvalue={sort} data={zeroStockList_res}
                                                                                    props={this.props} />
                                                                                : <p className='text-center'>Something Went Wrong</p>
                                                                }
                                                            </div>
                                                            {
                                                                this.state.notificationType === "custom" ?
                                                                    <div className='d-flex justify-content-end mt-3'>
                                                                        <button className={this.state.isSelectRemove === 'select-all' ? 'btn btn-success' : this.state.isSelectRemove === 'remove-all' ? 'btn btn-danger' : 'btn btn-info'} type="button" onClick={this.state.isSelectRemove === 'select-all' ? (ev) => this.selectAll(ev) : this.state.isSelectRemove === 'remove-all' ? (ev) => this.removeAll(ev) : null}>
                                                                            {
                                                                                this.state.isSelectRemove === 'select-all' ? "Select All" : this.state.isSelectRemove === 'remove-all' ? "Remove All" : "NA"
                                                                            }
                                                                        </button>
                                                                        <button className={'btn btn-primary ml-2'} type="button" onClick={(ev) => this.sendNotification()}>
                                                                            Confirm Send
                                                                        </button>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                        : null
                                                } */}
                                                {this.state.selectedViewType === "all" ?
                                                    totalZeroStockResult === undefined ? <DataTable mappingObj={this.state.mappingObj} headers={this.state.allProductsTableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} /> :
                                                        this.state.isLoading ? <Loader /> : zeroStockList_res !== undefined && zeroStockList_res.count === 0 ?
                                                            <DataTable headers={this.state.allProductsTableHeaders} />
                                                            : zeroStockList_res && zeroStockList_res.count > 0 ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="table-responsive">
                                                                            <DataTable mappingObj={this.state.mappingObj} headers={this.state.allProductsTableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={zeroStockList_res} props={this.props} />
                                                                        </div>
                                                                    </div>
                                                                </div> : "Something Went Wrong" : null
                                                }
                                                {
                                                    this.state.selectedViewType === "brand" ?
                                                        <div className="table-responsive">
                                                            {totalBrands === undefined ? <p className="text-center">No Data Found</p> :
                                                                this.state.isLoading ? <Loader /> : brandmanagement_res !== undefined && brandmanagement_res.count === 0 ?
                                                                    <p className="text-center">No Data Found</p> :
                                                                    brandmanagement_res && brandmanagement_res.count > 0 ?
                                                                        <table className="table table-bordered dataTable">
                                                                            <thead>
                                                                                <tr role='row'>
                                                                                    {
                                                                                        this.state.brandHeaders.map((header, key) =>

                                                                                            <th id={key + "header"} className="text-center">{header.label}</th>
                                                                                        )
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {brandmanagement_res.list.map((item, key) =>
                                                                                    <tr id={key + "row"} role="row" className="odd notification-list">
                                                                                        <td className="text-center" id={key}>
                                                                                            <a href="true" onClick={(e) => { e.preventDefault(); this.callBrandWiseProducts(item._id) }}>{item.name}</a>
                                                                                        </td>
                                                                                        <td className="text-center" id={key}>{item.code}</td>
                                                                                    </tr>
                                                                                )
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        : <p className='text-center'>Something Went Wrong</p>}
                                                        </div> : null
                                                }
                                                {this.state.selectedViewType === "all" ? totalZeroStockResult !== undefined ?
                                                    <Pagination
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                        handlePage={this.handlePage}
                                                        handleNext={this.handleNext}
                                                        handlePrevious={this.handlePrevious}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        totResult={totalZeroStockResult}
                                                        key={currentPage}
                                                    />
                                                    : "" : null
                                                }
                                                {this.state.notificationType === "custom" && this.state.selectedViewType === "all" ?
                                                    <div className='d-flex justify-content-end mt-3'>
                                                        <button className={this.state.isSelectRemove === 'select-all' ? 'btn btn-success' : this.state.isSelectRemove === 'remove-all' ? 'btn btn-danger' : 'btn btn-info'} type="button"
                                                            onClick={this.state.isSelectRemove === 'select-all' ? (ev) => this.selectAll(ev) : this.state.isSelectRemove === 'remove-all' ? (ev) => this.removeAll(ev) : null}
                                                        >
                                                            {
                                                                this.state.isSelectRemove === 'select-all' ? "Select All" : this.state.isSelectRemove === 'remove-all' ? "Remove All" : "NA"
                                                            }
                                                        </button>
                                                        <button className={'btn btn-primary ml-2'} type="button" onClick={(ev) => this.sendNotification()}>
                                                            Confirm Send
                                                        </button>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile_single: state.profile_single,
        brandmanagement_res: state.brandmanagement_res,
        zeroStockList_res: state.zeroStockList_res,
        zeroStockExport_res: state.zeroStockExport_res,
        sendNotifications_res: state.sendNotifications_res
    };
}

const mapDispatchToProps = dispatch => ({
    profilesingle: data => dispatch(profilesingle(data)),
    brandmanagementlist: data => dispatch(brandmanagementlist(data)),
    zeroStockList: data => dispatch(zeroStockList(data)),
    zeroStockExport: data => dispatch(zeroStockExport(data)),
    sendNotifications: data => dispatch(sendNotifications(data))
});

ZeroStockList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ZeroStockList);

export default ZeroStockList;