import React, { Component } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { retailerCreate, retailersingle, state_search, city_search, retailermanageupdate } from '../../actions';
import CustomerDropDown from '../../container/customerManagement/customerDropdown';
import CountryDropdown from '../../components/address/countrydropdown';
import StateDropdown from '../../components/address/stateDropdown'
import CityDropdown from '../../components/address/citydropdown';
import SingleBrandDropDown from '../brandsManagement/singleBrandDropDown';
import { errorRender, optionsList, validate, successRedriect, getValue, inputCheck } from '../../helpers/functions';
import TextAreaInputField from '../../components/textAreaInputField';

class RetailerAdd extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        country: null,
        state: null,
        city: null,
        line1: "",
        postalCode: '',
        type: "retailer",
        company: "",
        filterCustomer: { limit: 50000, customerType: "retailer" },
        forRedirect: [
            { name: "retailer_create_res", pageName: 'Retailer', pageUrl: 'retail', action: "Added" },
            { name: "retailer_update", pageName: 'Retailer', pageUrl: 'retail', action: "Updated" }
        ],
        serverErrors: ["retailer_create_res", "retailer_single", "retailer_update"],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "email", stateName: "email", type: "text" },
            { name: "phone", stateName: "phone", type: "text" },
            { name: "password", stateName: "password", type: "password" },
            { name: "item.brands", stateName: "brand_array", type: "selectMulti" },
            { name: "country", stateName: "country", type: "select" },
            { name: "state", stateName: "state", type: "select" },
            { name: "city", stateName: "city", type: "select" },
            { name: "item.customer", stateName: "selectedCustomer", type: "select" },
            { name: "address.line1", stateName: "line1", type: "text" },
            { name: "address.postalCode", stateName: "postalCode", type: "text" }
        ],
        selectedCustomer: null,
        brand_array: null,
        nameError: '',
        emailError: '',
        phoneError: '',
        passwrodError: '',
        countryError: '',
        stateError: '',
        districtError: '',
        cityError: '',
        line1Error: '',
        postalCodeError: '',
        typeError: '',
        selectedCustomerError: "",
        brandError: "",
        isLoading: false
    }


    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value, "retailerUser");

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    dropDownValueChange = (e, t) => this.setState({ [t]: e });


    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id })
            this.props.retailersingle(id);
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "country") {
                if (this.state.country !== null) {
                    if (val.value !== this.state.country.value) {
                        this.setState({ state: null, city: null });
                        let data = {
                            country: val.value
                        };
                        this.props.state_search(data)
                    }
                } else {
                    let data = {
                        country: val.value
                    };
                    this.props.state_search(data)
                }
            }
            if (name === "state") {
                if (this.state.state !== null) {
                    if (val.value !== this.state.state.value) {
                        this.setState({ city: null });
                        let data = {
                            state: val.value
                        };
                        this.props.city_search(data);
                    }
                } else {
                    let data = {
                        state: val.value
                    };
                    this.props.city_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: false, isNumber: false, onlyChar: false, type: "email", message: "", errName: "emailError" },
            { name: 'phone', label: "Mobile", value: this.state.phone, hasError: false, isRequired: true, isNumber: true, onlyChar: false, minLength: 10, maxLength: 10, type: "text", message: "", errName: "phoneError" },
            { name: 'password', label: "Password", value: this.state.password, hasError: false, isRequired: this.state.pageType === "update" ? false : true, isNumber: false, maxLength: 16, minLength: 8, onlyChar: false, type: "password", message: "", errName: "passwrodError" },
            { name: 'selectedCustomer', label: "Customer", value: this.state.selectedCustomer, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "selectedCustomerError" },
            { name: 'brand_array', label: "Brand", value: this.state.brand_array, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "brandError" },
            { name: 'country', label: "Country", value: this.state.country, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "countryError" },
            { name: 'state', label: "State", value: this.state.state, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "stateError" },
            { name: 'city', label: "City", value: this.state.city, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "cityError" },
            // { name: 'line1', label: "Address", value: this.state.line1, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "line1Error" },
            { name: 'postalCode', label: "Zip Code", value: this.state.postalCode, hasError: false, isRequired: true, isNumber: false, onlyChar: false, minLength: 8, maxLength: 8, type: "text", message: "", errName: "postalCodeError" }
        ];

        let data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password ? this.state.password : undefined,
            company: this.state.company,
            customer: this.state.selectedCustomer && this.state.selectedCustomer.value,
            brands: this.state.brand_array && this.state.brand_array.map((item) => item.value),
            address: {
                country: this.state.country && this.state.country.value,
                state: this.state.state && this.state.state.value,
                city: this.state.city && this.state.city.value,
                // district: this.state.district,
                line1: this.state.line1,
                postalCode: this.state.postalCode
            }
        }

        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));
        // console.log(newArr);

        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.retailermanageupdate(data, this.state.itemId);
            } else {
                this.props.retailerCreate(data);
            }
            validationFields = null;
            newArr = null;
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.retailer_single && nextProps.retailer_single.success === true) {
                nextProps.retailer_single.success = null;
                if (nextProps.retailer_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.retailer_single.item, value.name) })
                        } else if (value.type === "select" && getValue(nextProps.retailer_single, value.name)) {
                            let obj = {
                                label: getValue(nextProps.retailer_single, value.name).name,
                                value: getValue(nextProps.retailer_single, value.name)._id
                            }
                            this.setState({ [value.stateName]: obj });
                            this.ondropdownChange(obj, value.name);
                            obj = null
                        } else if (value.type === "selectMulti" && getValue(nextProps.retailer_single, value.name)) {
                            this.setState({ [value.stateName]: getValue(nextProps.retailer_single, value.name) ? optionsList(getValue(nextProps.retailer_single, value.name)) : [] })
                        }
                    })
                }
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update Retailer User" : "Add Retailer User"}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                                        </div>
                                        <div className="form-group col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.emailError} handleChange={this.handleChange} maxlength="100" type="text" name="email" value={this.state.email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.phoneError} handleChange={this.handleChange} maxlength="10" type="text" name="phone" value={this.state.phone} className="form-control" id="phone" placeholder="Mobile" />
                                        </div>
                                        {/* {
                                            pageType === "add" ? */}
                                        <div className={pageType === "add" ? "form-group required col-12 col-lg-4" : "form-group col-12 col-lg-4"}>
                                            <Inputfield errMessage={this.state.passwrodError} handleChange={this.handleChange} minlength="8" maxlength="16" type="password" name="password" value={this.state.password} className="form-control" id="password" placeholder="Password" />
                                            <small className="text-info d-flex">Password must contain atleast one uppercase, one lowercase, one digit and one special character. Length of password must be between 8 to 16 charcter long.</small>
                                        </div>
                                        {/* : null */}
                                        {/* } */}
                                        <div className="form-group required col-12 col-lg-4">
                                            <CustomerDropDown
                                                value={this.state.selectedCustomer}
                                                ondropdownChange={(ev) => this.dropDownValueChange(ev, "selectedCustomer")}
                                                placeholder="Select Customer"
                                                filterBy={this.state.filterCustomer}
                                                errMessage={this.state.selectedCustomerError}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <div className="form-group ">
                                                <SingleBrandDropDown
                                                    ondropdownChange={(ev) => this.dropDownValueChange(ev, "brand_array")}
                                                    value={this.state.brand_array}
                                                    onSelectResetsInput={true}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    errMessage={this.state.brandError}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Add Address
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <CountryDropdown
                                                value={this.state.country}
                                                ondropdownChange={(e) => this.ondropdownChange(e, "country")}
                                                placeholder="Search Country"
                                                errMessage={this.state.countryError}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <StateDropdown
                                                dontPreload={pageType === "update"}
                                                ondropdownChange={(e) => this.ondropdownChange(e, "state")}
                                                value={this.state.state}
                                                placeholder="Search State"
                                                country={this.state.country}
                                                errMessage={this.state.stateError}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <CityDropdown
                                                dontPreload={pageType === "update"}
                                                ondropdownChange={(e) => this.ondropdownChange(e, "city")}
                                                value={this.state.city}
                                                placeholder="Search City"
                                                state={this.state.state}
                                                country={this.state.country}
                                                errMessage={this.state.cityError}
                                            />
                                        </div>
                                        <div className="form-group  col-12 col-lg-4">
                                            <TextAreaInputField errMessage={this.state.line1Error} onChange={this.onChange} maxLength="5000" name="line1" value={this.state.line1} className="form-control" id="line1" rows="2" placeholder="Address line-1" />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.postalCodeError} handleChange={this.handleChange} maxlength="8" type="text" name="postalCode" value={this.state.postalCode} className="form-control" id="postalCode" placeholder="Zip Code" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.retail} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div >
            </main >

        )
    }
}

function mapStateToProps(state) {
    return {
        retailer_create_res: state.retailer_create_res,
        retailer_single: state.retailer_single,
        retailer_update: state.retailer_update,
    };
}


const mapDispatchToProps = dispatch => ({
    retailerCreate: data => dispatch(retailerCreate(data)),
    retailersingle: data => dispatch(retailersingle(data)),
    state_search: data => dispatch(state_search(data)),
    city_search: data => dispatch(city_search(data)),
    retailermanageupdate: (data, id) => dispatch(retailermanageupdate(data, id)),
});

RetailerAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(RetailerAdd);

export default RetailerAdd;