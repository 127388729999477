import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { expenseCategorySearch } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../../components/errorView';

let ExpenseCategoryDropdown = ({ ondropdownChange, value, placeholder, errMessage }) => {
    const dispatch = useDispatch();

    let expenseCategorySearchData = useSelector(state => state.expenseCategorySearchData);

    let [searchList, setSearchList] = React.useState([]);

    React.useEffect(() => {
        let data = {
            type: "categories"
        };
        dispatch(expenseCategorySearch(data));
    }, [])

    React.useEffect(() => {
        if (expenseCategorySearchData !== undefined && expenseCategorySearchData.list) {
            setSearchList(optionsList(expenseCategorySearchData.list))
        };
    }, [expenseCategorySearchData])

    return (
        <div>
            <label className="control-label">Expense Category</label>
            <SearchableDropDown
                value={value}
                onChange={ondropdownChange}
                options={searchList}
                instanceId="expense_category-id"
                prefixValue="expense_category"
                placeholder={placeholder !== undefined ? placeholder : "Category"}
            />
            <ErrorView message={errMessage} />
        </div>
    );
}

export default ExpenseCategoryDropdown;