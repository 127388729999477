import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import SearchableDropDown from '../../components/searchableDropDown';
import ErrorView from '../../components/errorView';
import { productmanagementlist } from '../../actions';

let ShadeDropDown = ({ ondropdownChange, value, placeholder, errMessage, filterBy, dontPreload, productValue }) => {
    const dispatch = useDispatch();

    let productmanagement_res = useSelector(state => state.productmanagement_res);

    let [shadeList, setShadeList] = React.useState([]);
    let [isData, setIsData] = React.useState();

    React.useEffect(() => {
        let data = {};
        data.limit = 50000;
        data.filter = { brand: productValue.value };
        if (!dontPreload) {
            dispatch(productmanagementlist(data));
            setIsData(true);
        }
    }, [filterBy, value]);

    React.useEffect(() => {
        // console.log(productValue)
        if (productmanagement_res !== undefined && productmanagement_res.list) {
            productmanagement_res.list = productmanagement_res.list.filter(item => {
                if (item._id === productValue.value) {
                    return { value: item._id, label: item.name, items: item }
                }
            });
            if (productmanagement_res.list[0] && productmanagement_res.list[0].attributes && productmanagement_res.list[0].attributes.length > 0) {
                let data = [];
                for (let i = 0; i < productmanagement_res.list[0].attributes.length; i++) {
                    if (productmanagement_res.list[0].attributes[i].name !== "") {
                        data.push({ value: productmanagement_res.list[0].attributes[i].name, label: productmanagement_res.list[0].attributes[i].name })
                    }
                }
                setShadeList(data);
            } else {
                setIsData(false);
            }
        };
    }, [productmanagement_res]);

    return (

        <div>
            <label className="control-label">Shade</label>
            <SearchableDropDown
                value={value}
                noData={isData}
                onChange={ondropdownChange}
                options={shadeList}
                instanceId="shade-id"
                prefixValue="shade"
                placeholder={placeholder !== undefined ? placeholder : "Shade"}
            />
            <ErrorView message={errMessage} />
        </div>
    )
}

export default ShadeDropDown;