import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routehelp } from '../src/Routehelper';
import Base from '../src/components/base';
import Usermanagement from '../src/container/userManagement/list';
import Login from '../src/container/login/login';
import Signup from '../src/container/signup/signup';
import Logout from '../src/components/logout';
import forgotpassword from '../src/container/forgotpassword/forgotpassword';
import Otp from '../src/container/forgotpassword/otp';
import Dashboardmain from '../src/container/dashboards/dashboardmain';
import PrivateRoute from '../src/PrivateRoute';
import User_manage_add from '../src/container/userManagement/form';
import Dashboardprimary from '../src/container/dashboards/dashboardprimary';
import Zonemanagement from '../src/container/zoneManagement/list';
import Zone_manage_add from '../src/container/zoneManagement/form';
import Regionmanagement from '../src/container/regionManagement/list';
import Region_manage_add from '../src/container/regionManagement/form';
import Territorymanagement from '../src/container/territoryManagement/list';
import Territory_manage_add from '../src/container/territoryManagement/form';
import Beatsmanagement from '../src/container/beatsManagement/list';
import Beats_manage_add from '../src/container/beatsManagement/form';
import Brandmanagemet from '../src/container/brandsManagement/list';
import Brands_manage_add from '../src/container/brandsManagement/form';
import Customermanagement from '../src/container/customerManagement/list';
import Customer_manage_add from '../src/container/customerManagement/form';
import Productmanagement from '../src/container/productManagement/list';
import Product_manage_add from '../src/container/productManagement/form';
import Stockmanagement from '../src/container/stockManagement/list';
import Ordermanagement from '../src/container/orderManagement/list';
import OrderForm from '../src/container/orderManagement/form';
import Profile from '../src/container/profile/profile';
import changePassword from '../src/container/changePassword/passChange';
import routeplanning from '../src/container/routeplanning/list';
import route_add from '../src/container/routeplanning/add';
import routeupdate from '../src/container/routeplanning/update';
import outstanding from '../src/container/outstanding/list';
import dispatchDetails from '../src/container/dispatchDetails/list';
import indirectcustomer from './container/indirectCustomer/list';
import IndirectCustomer_manage_add from './container/indirectCustomer/form';
import RateMapping from './container/rateMapping/rateMapping';
import Rate_manage_add from './container/rateMapping/add';
import RateUpdate from './container/rateMapping/update';
import SrAsmUserAssignment from './container/SrASM-UsersAssignment/list';
import SrAsmUsersAssignAdd from './container/SrASM-UsersAssignment/add';
import SrAsmUsersAssignUpdate from './container/SrASM-UsersAssignment/update';
import Schememanagement from './container/schemeManagement/list';
import Scheme_manage_add from './container/schemeManagement/form';
import Transporter from './container/transporterManagement/list';
import Transporter_add from './container/transporterManagement/form';
import Retailmanagement from './container/retailerManagement/list';
import RetailerAdd from './container/retailerManagement/form';
import Trending from './container/whatsTrending/list';
import TrendingAdd from './container/whatsTrending/form';
import Catagory from './container/catagory/list';
import CatagoryAdd from './container/catagory/form';
import Catalogue from './container/catalogue/list';
import CatalogueAdd from './container/catalogue/form';
import Setting from './container/setting/setting';
import Mis from './container/mis/mis';
import MisReportSetting from './container/mis/misReportSetting';
import CompanyAdd from '../src/container/company/add';
import CompanyUpdate from '../src/container/company/update';
import DiscountMaster from '../src/container/discountMaster/discountmaster';
import DiscountMasterAdd from '../src/container/discountMaster/add';
import DiscountMasterUpdate from '../src/container/discountMaster/update';
import Agency from '../src/container/agency/list';
import AgencyAdd from '../src/container/agency/form';
import AllowanceCatagory from '../src/container/allowanceCatagory/list';
import AllowanceCatagoryAdd from '../src/container/allowanceCatagory/form';
import AllowanceSubCatagory from '../src/container/allowanceSubCatagory/list';
import AllowanceSubCatagoryAdd from '../src/container/allowanceSubCatagory/form';
import TouringVoucher from '../src/container/touringVouchers/touringVoucher';
import ExpenseApproval from '../src/container/expenseApproval/expenseApproval';
import AdvanceApproval from '../src/container/advanceApproval/advanceApproval';
import LandingPage from '../src/container/landingPage/landingPage';
import PackageList from '../src/container/packages/list';
import ErrorBoundary from '../src/components/errorBoundary';
import UOMList from '../src/container/uom/list';
import Uom_Master_From from '../src/container/uom/form';
import RetailerOrdermanagement from '../src/container/retailerOrder/list';
import InactiveUsers from '../src/container/dashboardLists/listUsers';
import InactiveCustomers from './container/dashboardLists/listCustomers';
import TopCustomers from './container/dashboardLists/listTopCustomers';
import TopOrders from './container/dashboardLists/listTopOrders';
import TopProducts from './container/dashboardLists/listTopProducts';
import LeastCustomers from './container/dashboardLists/listLeastCustomers';
import LeastOrders from './container/dashboardLists/listLeastOrders';
import LeastProducts from './container/dashboardLists/listLeastProducts';
import LogicalLogs from './container/logicalLogs/list';
import ZeroStockList from './container/dashboardLists/zeroStockList';
// import order_manage_add from '../src/container/Order Management/add';
import CompanyConfig from './container/companyConfig/companyConfig';
import OrderCreate from './container/orderManagement/create';
import Email from './container/emailConfiguration/form';
import StockUpdate from './container/stockManagement/form';
import UserHierarchyList from './container/userHierarchy/list';
import UserHierarchyForm from './container/userHierarchy/form';
import CommissionList from './container/commission/list';
import OrderCreateFailed from './container/orderCreationFailed/list';
import CommissionRange from './container/commissionRange/list';
import CommissionForm from './container/commissionRange/form';
import B2BPrivacyPolicy from './container/privacyPolicy/b2b';
import B2CPrivacyPolicy from './container/privacyPolicy/b2c';
import PaymentList from './container/payment/list';
import CustomersVisit from './container/dashboardLists/listCustomersVisit';
import Options from './container/options/form';
import OrderTemplateList from './container/orderTemplate/list';
import OrderTemplateForm from './container/orderTemplate/form';
import FeedbackList from './container/feedback/list';

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <BrowserRouter>
          <Switch>
            <Route exact path={routehelp.default} component={LandingPage} />
            <Route exact path={routehelp.b2bPrivacy} component={B2BPrivacyPolicy} />
            <Route exact path={routehelp.b2cPrivacy} component={B2CPrivacyPolicy} />
            <Route exact path={routehelp.login} component={Login} />
            <Route exact path={routehelp.signup} component={Signup} />
            <Route exact path={routehelp.forgotpassword} component={forgotpassword} />
            <Route exact path={routehelp.otp} component={Otp} />
            <PrivateRoute exact path={routehelp.primarydashboard} component={Dashboardprimary} />
            <PrivateRoute exact path={routehelp.companyAdd} component={CompanyAdd} />
            <PrivateRoute
              exact={false}
              path={routehelp.companyUpdate + ":item_id"}
              component={CompanyUpdate}
            />
            <PrivateRoute
              exact={true}
              path={routehelp.logs}
              component={LogicalLogs}
            />
            <PrivateRoute
              exact={true}
              path={routehelp.version}
              component={Options}
            />
            <PrivateRoute
              exact
              path={routehelp.logout}
              component={Logout}
            />
            <Base>
              <Route
                exact
                path={routehelp.maindashboard}
                component={Dashboardmain}
              />
              <PrivateRoute
                exact
                path={routehelp.packages}
                component={PackageList}
              />
              <PrivateRoute
                exact
                path={routehelp.usermanagement}
                component={Usermanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.inactiveUsers}
                component={InactiveUsers}
              />
              <PrivateRoute
                exact
                path={routehelp.inactiveCustomers}
                component={InactiveCustomers}
              />
              <PrivateRoute
                exact
                path={routehelp.customerVisities}
                component={CustomersVisit}
              />
              <PrivateRoute
                exact
                path={routehelp.least_10_customers}
                component={LeastCustomers}
              />
              <PrivateRoute
                exact
                path={routehelp.top_10_customers}
                component={TopCustomers}
              />
              <PrivateRoute
                exact
                path={routehelp.top_10_orders}
                component={TopOrders}
              />
              <PrivateRoute
                exact
                path={routehelp.least_10_orders}
                component={LeastOrders}
              />
              <PrivateRoute
                exact
                path={routehelp.top_10_products}
                component={TopProducts}
              />
              <PrivateRoute
                exact
                path={routehelp.least_10_products}
                component={LeastProducts}
              />
              <PrivateRoute
                exact
                path={routehelp.zero_stock}
                component={ZeroStockList}
              />
              <PrivateRoute
                exact
                path={routehelp.usermanagementadd}
                component={User_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.usermanagementupdate + ":item_id"}
                component={User_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.zonemanagement}
                component={Zonemanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.zonemanagementadd}
                component={Zone_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.zonemanagementupdate + ":item_id"}
                component={Zone_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Regionmanagement}
                component={Regionmanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.Regionmanagementadd}
                component={Region_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.regionmanagementupdate + ":item_id"}
                component={Region_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Territorymanagement}
                component={Territorymanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.Territorymanagementadd}
                component={Territory_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Territorymanagementupdate + ":item_id"}
                component={Territory_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Beatsmanagement}
                component={Beatsmanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.Beatsmanagementadd}
                component={Beats_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Beatsmanagementupdate + ":item_id"}
                component={Beats_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Brandmanagement}
                component={Brandmanagemet}
              />
              <PrivateRoute
                exact
                path={routehelp.Brandmanagementadd}
                component={Brands_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.Brandmanagementupdate + ":item_id"}
                component={Brands_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.customermanagement}
                component={Customermanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.Customeradd}
                component={Customer_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.customermanagementupdate + ":item_id"}
                component={Customer_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.productmanagement}
                component={Productmanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.productmanagementadd}
                component={Product_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.productmanagementupdate + ":item_id"}
                component={Product_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.stockmanagement}
                component={Stockmanagement}
              />
              <PrivateRoute exact path={routehelp.stockEdit + ":item_id"} component={StockUpdate} />
              <PrivateRoute
                exact={true}
                path={routehelp.ordermanagement}
                component={Ordermanagement}
              />
              <PrivateRoute
                exact={true}
                path={routehelp.retailerOrdermanagement}
                component={RetailerOrdermanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.ordermanagementUpdate + ":item_id"}
                component={OrderForm}
              />
              <PrivateRoute
                exact
                path={routehelp.order_create}
                component={OrderCreate}
              />
              <PrivateRoute
                exact={true}
                path={routehelp.profile}
                component={Profile}
              />
              <PrivateRoute
                exact={true}
                path={routehelp.changePassword}
                component={changePassword}
              />
              <PrivateRoute
                exact={true}
                path={routehelp.routeplanning}
                component={routeplanning}
              />
              <PrivateRoute
                exact={true}
                path={routehelp.addroute}
                component={route_add}
              />
              <PrivateRoute
                exact
                path={routehelp.updateroute + ":item_id"}
                component={routeupdate}
              />
              <PrivateRoute
                exact
                path={routehelp.outstanding}
                component={outstanding}
              />
              <PrivateRoute
                exact
                path={routehelp.dispatchDetails}
                component={dispatchDetails}
              />
              <PrivateRoute
                exact
                path={routehelp.indirectcustomer}
                component={indirectcustomer}
              />
              <PrivateRoute
                exact
                path={routehelp.indirectCustomeradd}
                component={IndirectCustomer_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.IndirectCustomermanagementUpdate + ":item_id"}
                component={IndirectCustomer_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.RateMapping}
                component={RateMapping}
              />
              <PrivateRoute
                exact
                path={routehelp.rateAdd}
                component={Rate_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.rateUpdate + ":item_id"}
                component={RateUpdate}
              />
              <PrivateRoute
                exact
                path={routehelp.SrAsmAssignment}
                component={SrAsmUserAssignment}
              />
              <PrivateRoute
                exact
                path={routehelp.SrAsmAssignmentAdd}
                component={SrAsmUsersAssignAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.SrAsmAssignmentUpdate + ":item_id"}
                component={SrAsmUsersAssignUpdate}
              />
              <PrivateRoute
                exact
                path={routehelp.schememanagement}
                component={Schememanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.schememanagementadd}
                component={Scheme_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.schememanagementUpdate + ":item_id"}
                component={Scheme_manage_add}
              />
              <PrivateRoute
                exact
                path={routehelp.transporter}
                component={Transporter}
              />
              <PrivateRoute
                exact
                path={routehelp.transporterAdd}
                component={Transporter_add}
              />
              <PrivateRoute
                exact
                path={routehelp.transporterupdate + ":item_id"}
                component={Transporter_add}
              />
              <PrivateRoute
                exact
                path={routehelp.retail}
                component={Retailmanagement}
              />
              <PrivateRoute
                exact
                path={routehelp.retailAdd}
                component={RetailerAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.retailUpdate + ":item_id"}
                component={RetailerAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.trending}
                component={Trending}
              />
              <PrivateRoute
                exact
                path={routehelp.trendAdd}
                component={TrendingAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.trendUpdate + ":item_id"}
                component={TrendingAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.catagory}
                component={Catagory}
              />
              <PrivateRoute
                exact
                path={routehelp.catagoryAdd}
                component={CatagoryAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.catagoryUpdate + ":item_id"}
                component={CatagoryAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.orderTemplateList}
                component={OrderTemplateList}
              />
              <PrivateRoute
                exact
                path={routehelp.orderTemplateListAdd}
                component={OrderTemplateForm}
              />
              <PrivateRoute
                exact
                path={routehelp.orderTemplateListUpdate + ":item_id"}
                component={OrderTemplateForm}
              />
              <PrivateRoute
                exact
                path={routehelp.eCatalogue}
                component={Catalogue}
              />
              <PrivateRoute
                exact
                path={routehelp.eCatalogueAdd}
                component={CatalogueAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.catalogueupdate + ":item_id"}
                component={CatalogueAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.setting}
                component={Setting}
              />
              <PrivateRoute
                exact
                path={routehelp.mis}
                component={Mis}
              />
              <PrivateRoute
                exact
                path={routehelp.misReportSetting}
                component={MisReportSetting}
              />
              <PrivateRoute
                exact
                path={routehelp.discountMaster}
                component={DiscountMaster}
              />
              <PrivateRoute
                exact
                path={routehelp.discountMasterAdd}
                component={DiscountMasterAdd}
              />
              <PrivateRoute
                exact
                path={routehelp.discountMasterUpdate + ":item_id"}
                component={DiscountMasterUpdate}
              />
              <PrivateRoute exact path={routehelp.agencyMaster} component={Agency} />
              <PrivateRoute exact path={routehelp.agencyAdd} component={AgencyAdd} />
              <PrivateRoute exact path={routehelp.agencyUpdate + ":item_id"} component={AgencyAdd} />
              <PrivateRoute exact path={routehelp.allowanceCatagory} component={AllowanceCatagory} />
              <PrivateRoute exact path={routehelp.allowanceCatagoryAdd} component={AllowanceCatagoryAdd} />
              <PrivateRoute exact path={routehelp.allowanceCatagoryUpdate + ":item_id"} component={AllowanceCatagoryAdd} />
              <PrivateRoute exact path={routehelp.allowanceSubCatagory} component={AllowanceSubCatagory} />
              <PrivateRoute exact path={routehelp.allowanceSubCatagoryAdd} component={AllowanceSubCatagoryAdd} />
              <PrivateRoute exact path={routehelp.allowanceSubCatagoryUpdate + ":item_id"} component={AllowanceSubCatagoryAdd} />
              <PrivateRoute exact path={routehelp.touringVoucher} component={TouringVoucher} />
              <PrivateRoute exact path={routehelp.expenseApproval} component={ExpenseApproval} />
              <PrivateRoute exact path={routehelp.advanceApproval} component={AdvanceApproval} />
              <PrivateRoute exact path={routehelp.uomMaster} component={UOMList} />
              <PrivateRoute exact path={routehelp.uomAdd} component={Uom_Master_From} />
              <PrivateRoute exact path={routehelp.uomUpdate + ":item_id"} component={Uom_Master_From} />
              <PrivateRoute exact path={routehelp.config} component={CompanyConfig} />
              <PrivateRoute exact path={routehelp.emailConfig} component={Email} />
              <PrivateRoute exact path={routehelp.userHierarchyList} component={UserHierarchyList} />
              <PrivateRoute exact path={routehelp.userHierarchyAdd} component={UserHierarchyForm} />
              <PrivateRoute exact path={routehelp.userHierarchyUpdate + ":item_id"} component={UserHierarchyForm} />

              <PrivateRoute exact path={routehelp.commissionList} component={CommissionList} />
              <PrivateRoute exact path={routehelp.orderFailed} component={OrderCreateFailed} />

              <PrivateRoute exact path={routehelp.commissionRange} component={CommissionRange} />
              <PrivateRoute exact path={routehelp.commissionRangeAdd} component={CommissionForm} />
              <PrivateRoute exact path={routehelp.commissionRangeUpdate + ":item_id"} component={CommissionForm} />
              <PrivateRoute exact path={routehelp.payment} component={PaymentList} />
              <PrivateRoute exact path={routehelp.feedback} component={FeedbackList} />

            </Base>
          </Switch>

        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default App;
