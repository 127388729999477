import React, { Component, lazy } from 'react';
import { apiUrl, uploadUrl } from '../../config';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import ErrorView from '../../components/errorView';
import { connect } from 'react-redux';
import { companyConfigSingle, updatecompanyconfig } from '../../actions';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { errorRender, getValue, successRedriect, validate } from '../../helpers/functions';
import helperFunc from '../../helpers/helperFuncs';
import Select from 'react-select';
import Branddropdown from '../brandsManagement/brandDropdown'

// const UomDropDown = lazy(() => import('../uom/uomDropdown'));
const SchemeDropDown = lazy(() => import('../schemeManagement/schemeDropdwon'));

class ComapnyConfig extends Component {
    state = {
        uploadPercentage: 0,
        splashScreen: [],
        low_qty: "",
        setFields: [
            { name: "splashScreen", stateName: "splashScreen", type: "text" },
            { name: "low_qty", stateName: "low_qty", type: "number" },
            { name: "defaultUOM", stateName: "defaultUOM", type: "" },

            { name: "defaultOrderScheme", stateName: "defaultOrderScheme", type: "select" },
            { name: "defaultSettingApplyTo", stateName: "defaultSettingApplyTo", type: "select" },
            { name: "defaultOrderType", stateName: "defaultOrderType", type: "select" },
            { name: "defaultOrderBrand", stateName: "defaultOrderBrand", type: "select" },
            { name: "defaultOrderSeries", stateName: "defaultOrderSeries", type: "select" }
        ],
        error: {
            companyConfigError: ""
        },
        isLoading: false,
        defaultUOM: "",
        defaultOrderScheme: "",
        defaultOrderType: "",
        defaultOrderBrand: "",
        defaultOrderSeries: "",
        defaultSettingApplyTo: "",
        seriesList: [],
        orderTypeList: [],
        userTypeList: []
    };

    uploadFile = ({ target: { files } }) => {
        // console.log(files[0])
        let data = new FormData();
        data.append('file', files[0])
        const token = localStorage.getItem('token');
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }
            }
        }
        axios.defaults.headers = { 'x-access-token': token }
        axios.post(apiUrl + "/api/account/company-config/upload", data, options).then(res => {
            // console.log(res)
            this.setState({ splashScreen: res.data.item.splashScreen, uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 })
                }, 1000);
            })
        });
    }

    componentDidMount() {
        let data = {};
        this.props.companyConfigSingle(data);
        let series = helperFunc.seriesTypeList();
        let newSeriesList = []
        if (series && series.length > 0) {
            series.forEach((ele) => {
                newSeriesList.push({ value: ele.name, label: ele.name })
            });
        };
        // console.log(newSeriesList);
        this.setState({
            seriesList: newSeriesList,
            orderTypeList: helperFunc.orderTypeList(),
            userTypeList: helperFunc.userTypeFun()
        }, () => {
            // console.log(this.state.orderTypeList);
        });
    }

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            };

            successRedriect(nextProps, "companyconfig_update", "Company Config", this.props, "maindashboard", "Updated");

            if (nextProps.companyConfigSingle_res && nextProps.companyConfigSingle_res.success === true) {
                nextProps.companyConfigSingle_res.success = null;
                if (nextProps.companyConfigSingle_res.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "number") {
                            this.setState({ [value.stateName]: getValue(nextProps.companyConfigSingle_res.item, value.name) })
                        } else if (value.type === "select" && nextProps.companyConfigSingle_res.item[value.name]) {
                            let obj = {};
                            if (["defaultOrderType", "defaultOrderSeries", "defaultSettingApplyTo"].indexOf(value.name) > -1) {
                                if (["defaultOrderType"].indexOf(value.name) > -1) {
                                    obj = {
                                        label: nextProps.companyConfigSingle_res.item[value.name].toUpperCase(),
                                        value: nextProps.companyConfigSingle_res.item[value.name]
                                    };
                                } else if (["defaultSettingApplyTo"].indexOf(value.name) > -1) {
                                    // console.log(nextProps.companyConfigSingle_res.item[value.name], helperFunc.userTypeFun().filter((ele) => ele.value === nextProps.companyConfigSingle_res.item[value.name]));
                                    obj = helperFunc.userTypeFun().filter((ele) => ele.value === nextProps.companyConfigSingle_res.item[value.name])[0] || {};
                                    let orderTypeList = helperFunc.orderTypeList();

                                    if (obj.value === "AG") {
                                        orderTypeList = helperFunc.orderTypeList().filter((ele) => ele.value !== "wholesaler");
                                    } else if (obj.value === "WHS" || obj.value === "ASM") {
                                        orderTypeList = helperFunc.orderTypeList().filter((ele) => ele.value !== "retailer");
                                    };

                                    // console.log(orderTypeList, obj);

                                    this.setState({
                                        orderTypeList: orderTypeList
                                    });

                                } else {
                                    obj = {
                                        label: nextProps.companyConfigSingle_res.item[value.name],
                                        value: nextProps.companyConfigSingle_res.item[value.name]
                                    };
                                }
                            } else {
                                obj = {
                                    label: nextProps.companyConfigSingle_res.item[value.name].name,
                                    value: nextProps.companyConfigSingle_res.item[value.name]._id
                                };
                            };
                            // console.log(value.name, obj);
                            this.setState({ [value.name]: obj });
                            this.ondropdownChange(obj, value.name);
                            this.brandChange(obj, value.name);
                            obj = null;
                        } else {
                            this.setState({ [value.stateName]: nextProps.companyConfigSingle_res.item[value.name] })
                        }
                    })
                }
            }

            errorRender(nextProps, "companyconfig_update");
        }
        return true;
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    onsubmit = (e) => {

        let validationFields = [
            { name: 'low_qty', label: "Low Level QTY", value: this.state.low_qty, hasError: false, isRequired: false, isNumber: true, onlyChar: false, type: "number", message: "", errName: "companyConfigError" },
        ];

        let data = {
            low_qty: this.state.low_qty,
            defaultUOM: this.state.defaultUOM,
            splashScreen: this.state.splashScreen,
            defaultOrderScheme: this.state.defaultOrderScheme ? this.state.defaultOrderScheme.value : null,
            defaultOrderSeries: this.state.defaultOrderSeries ? this.state.defaultOrderSeries.value : null,
            defaultOrderBrand: this.state.defaultOrderBrand ? this.state.defaultOrderBrand.value : null,
            defaultOrderType: this.state.defaultOrderType ? this.state.defaultOrderType.value : null,
            defaultSettingApplyTo: this.state.defaultSettingApplyTo ? this.state.defaultSettingApplyTo.value : null
        };

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
            this.setState({ isLoading: true });
            this.props.updatecompanyconfig(data);
            validationFields = null;
            newArr = null;
        }
    }

    ondropdownChange = (e, name) => {

        this.setState({ [name]: e }, () => {
            let orderTypeList = helperFunc.orderTypeList() || [];
            let orderDefaultType = this.state.defaultOrderType;
            if (name === "defaultSettingApplyTo" && e.value === "AG") {
                orderTypeList = helperFunc.orderTypeList().filter((ele) => ele.value !== "wholesaler");
                orderDefaultType = "";
            } else if (name === "defaultSettingApplyTo" && (e.value === "WHS" || e.value === "ASM")) {
                orderTypeList = helperFunc.orderTypeList().filter((ele) => ele.value !== "retailer");
                orderDefaultType = "";
            };

            if (name === "defaultOrderType") {
                if (this.state.defaultSettingApplyTo.value === "AG") {
                    orderTypeList = helperFunc.orderTypeList().filter((ele) => ele.value !== "wholesaler");
                } else if (this.state.defaultSettingApplyTo.value === "WHS" || this.state.defaultSettingApplyTo.value === "ASM") {
                    orderTypeList = helperFunc.orderTypeList().filter((ele) => ele.value !== "retailer");
                };
            }

            // console.log(orderTypeList, name, e);
            this.setState({
                defaultOrderType: orderDefaultType,
                orderTypeList: orderTypeList
            });
        });
        // console.log("uom", this.state.defaultUOM);
    }


    brandChange = (e, t) => this.setState({ [e]: t });

    render() {
        const { isLoading, uploadPercentage } = this.state;
        // console.log(this.state);
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Update Company Configuration
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {
                                            this.state.splashScreen && this.state.splashScreen.length !== 0 ?
                                                <div className="form-group col-lg-4">
                                                    <label htmlFor="splashScreen" className="control-label">Splash Screen</label>
                                                    <div className="document-img ">
                                                        <div onClick={() => { this.setState({ splashScreen: [] }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                                                        <img alt="img" className="images" src={uploadUrl + this.state.splashScreen.medium} />
                                                        <div className="media-content">
                                                        </div>
                                                    </div>
                                                </div> : 
                                                <div className="form-group  col-12 col-lg-4">
                                                    <label htmlFor="splashScreen" className="control-label">Splash Screen</label>
                                                    <input
                                                        maxLength="5"
                                                        onChange={this.uploadFile}
                                                        // value={this.state.code}
                                                        name="splashScreen"
                                                        type="file"
                                                        className="form-control"
                                                        accept='.jpeg, .jpg, .png'
                                                    />
                                                    <ErrorView message={this.state.splashScreenError} />
                                                    <div className="progressbar-image">
                                                        {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                                                    </div>
                                                </div>
                                        }
                                        <div className="form-group col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.companyConfigError} handleChange={this.handleChange} type="number" name="low_qty" value={this.state.low_qty} className="form-control" id="qty" placeholder="Low Level QTY" />
                                        </div>
                                        {/* <div className="form-group col-12 col-lg-4">
                                            <UomDropDown
                                                value={this.state.defaultUOM}
                                                isMulti={false}
                                                ondropdownChange={(e) => this.ondropdownChange(e, "defaultUOM")}
                                            />
                                        </div> */}
                                        <div className="form-group col-12 col-lg-4"></div>
                                        <div className="form-group col-12 col-lg-4">
                                            <label className="control-label">Order Default Setting Apply To</label>
                                            <Select
                                                onChange={(e) => this.ondropdownChange(e, "defaultSettingApplyTo")}
                                                closeMenuOnSelect={true}
                                                options={this.state.userTypeList}
                                                placeholder="Order Default Setting Apply To"
                                                instanceId="defaultSettingApplyTo"
                                                classNamePrefix="defaultSettingApplyTo"
                                                value={this.state.defaultSettingApplyTo}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-lg-4">
                                            <Branddropdown
                                                ondropdownChange={this.brandChange}
                                                name="defaultOrderBrand"
                                                value={this.state.defaultOrderBrand}
                                                className="form-control form-control-square"
                                            />
                                        </div>
                                        <div className="form-group col-12 col-lg-4">
                                            <SchemeDropDown
                                                ondropdownChange={(e) => this.ondropdownChange(e, "defaultOrderScheme")}
                                                placeholder="Default Order Scheme"
                                                label="Default Order Scheme"
                                                value={this.state.defaultOrderScheme}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-lg-4">
                                            <label className="control-label">Order Default Type</label>
                                            <Select
                                                onChange={(e) => this.ondropdownChange(e, "defaultOrderType")}
                                                closeMenuOnSelect={true}
                                                options={this.state.orderTypeList}
                                                placeholder="Default Order Type"
                                                instanceId="order-type-id"
                                                classNamePrefix="order-type-id"
                                                value={this.state.defaultOrderType}
                                            />
                                        </div>
                                        {/* <div className="form-group col-12 col-lg-4">
                                            <label className="control-label">Default Series Type</label>
                                            <Select
                                                onChange={(e) => this.ondropdownChange(e, "defaultOrderSeries")}
                                                closeMenuOnSelect={true}
                                                options={this.state.seriesList}
                                                placeholder="Default Order Series"
                                                instanceId="series-id"
                                                classNamePrefix="series-id"
                                                value={this.state.defaultOrderSeries}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" disable={isLoading} loading={isLoading} className="btn btn-primary mr-1" name="Update" />
                            <Links to={routehelp.maindashboard} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        companyConfigSingle_res: state.companyConfigSingle_res,
        companyconfig_update: state.companyconfig_update
    };
}

const mapDispatchToProps = dispatch => ({
    companyConfigSingle: data => dispatch(companyConfigSingle(data)),
    updatecompanyconfig: (data) => dispatch(updatecompanyconfig(data))
});

ComapnyConfig = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComapnyConfig);

export default ComapnyConfig;