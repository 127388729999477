import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import SearchableDropDown from '../../components/searchableDropDown';
import ErrorView from '../../components/errorView';
import { productmanagecreate, productsingle, productmanageupdate, uommanagementlist } from '../../actions';
import { errorRender, successRedriect, getValue, validate, inputCheck } from '../../helpers/functions';
import Select from 'react-select';

const SingleBrandDropDown = lazy(() => import('../brandsManagement/singleBrandDropDown'));
const UomDropDown = lazy(() => import('../uom/uomDropdown'));
const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));

const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

class Product_manage_add extends Component {

    state = {
        displayUOM: {},
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        // itemCode: '',
        price: '',
        whlprice: '',
        brand: '',
        serverError: ["product_create_res", "product_update", "product_single"],
        attributes: [],
        forRedirect: [
            { name: "product_create_res", pageName: 'Product', pageUrl: 'productmanagement', action: "Added" },
            { name: "product_update", pageName: 'Product', pageUrl: 'productmanagement', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "code", stateName: "code", type: "text" },
            // { name: "itemCode", stateName: "itemCode", type: "text" },
            { name: "price", stateName: "price", type: "text" },
            { name: "whlprice", stateName: "whlprice", type: "text" },
            { name: "attributes", stateName: "attributes", type: "text" },
            { name: "brand", stateName: "brand", type: "select" },
            { name: "defaultUOM", stateName: "defaultUOM", type: "text" }
        ],
        nameError: '',
        uomError: '',
        codeError: '',
        priceError: '',
        whlPriceError: '',
        brandError: '',
        itemCodeError: '',
        isLoading: false,
        getSingleLoader: false,
        uom: "",
        isUnitSub: false,
        uomListRes: {},
        defaultUOMList: [],
        defaultUOM: "",
        defaultUOMError: "",
        allUOMList: []
    }

    onChangeSelect = (value, actionMeta) => {
        // eslint-disable-next-line default-case
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                value = this.state.uom.filter((v) => v.isFixed);
                break;
        }

        value = this.orderOptions(value);
        this.setState({
            uom: value
        }, () => {
            let tempAttributesList = this.state.attributes;
            if (tempAttributesList && tempAttributesList.length > 0) {
                tempAttributesList.forEach((ele) => {
                    ele.unitCodes = ele.unitCodes || [];

                    let unitArr = ele.unitCodes.filter(item => {
                        return value.filter(selectUOM => item.unitName === selectUOM.value).length > 0
                    });

                    if (value && value.length > 0) {
                        value.forEach((selectUOM) => {
                            if (ele.unitCodes.filter(item => item.unitName === selectUOM.value).length === 0) {
                                unitArr.push({
                                    unitName: selectUOM.value,
                                    unitCode: "",
                                    convFact: selectUOM.conFact
                                });
                            }
                        });
                    }
                    ele.unitCodes = unitArr;
                });
            }

            this.setState({
                attributes: tempAttributesList
            });

        });

        // this.ondropdownChange(value, "uom");
    }

    orderOptions = (values) => {
        return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
    };

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true });
            this.props.productsingle(id);
        }
        this.callUOMList();
    }

    callUOMList = () => {
        let data = {}
        data.filter = { name: '', code: '' };
        data.skip = 0;
        data.limit = 1000000;
        this.props.uommanagementlist(data);
    }

    handleChange = (name, e) => {

        let isValid = inputCheck(name, e.target.value);

        // console.log(isValid);
        if (isValid) {
            this.setState({ [name]: e.target.value });
        }

        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleatttributesNameChange = idx => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, name: evt.target.value };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleatttributescodeChange = idx => evt => {
        // console.log(idx, evt);
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, code: evt.target.value };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleatttributesquantityChange = idx => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, quantity: evt.target.value };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleatttributesDescriptionChange = idx => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, description: evt.target.value };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleatttributesquantityunitChange = idx => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, quantityUnit: evt.target.value || "NA" };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleatttributespriceChange = idx => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, price: evt.target.value };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleAddattributes = () => {
        // console.log(this.state.uom);
        // console.log(this.state.pageType);
        let newCodes = [];
        if (this.state.pageType === "update") {
            if (this.state.uom && this.state.uom.length > 0) {
                this.state.uom.forEach((ele) => {
                    if (ele) {
                        if (this.state.uomListRes && this.state.uomListRes.list && this.state.uomListRes.list.length > 0) {
                            this.state.uomListRes.list.forEach((e) => {
                                if (e.code === ele.value) {
                                    newCodes.push({
                                        unitName: e.code,
                                        unitCode: "",
                                        convFact: e.conFact
                                    });
                                }
                            })
                        }
                    }
                })
            }
        } else {
            //comes when add page
            newCodes = this.state.uom && this.state.uom.map((ele) => {
                return {
                    unitName: ele.value,
                    unitCode: "",
                    convFact: ele.conFact
                }
            });
        }
        this.setState({
            attributes: this.state.attributes.concat([{
                name: '', code: '', description: '', quantity: '', quantityUnit: '', price: '',
                unitCodes: newCodes
            }])
        });
    };

    handleRemoveattributes = idx => () => {
        this.setState({
            attributes: this.state.attributes.filter((s, sidx) => idx !== sidx)
        });
    };

    handleunitNameChange = idx => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            const newunitCodes = this.state.uom.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, unitName: evt.target.value };
            });
            this.setState({ unitCodes: newunitCodes });
        }
    };

    handleunitCodeChange = (index, idx) => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            // eslint-disable-next-line
            this.state.attributes[idx].unitCodes[index].unitCode = evt.target.value;
        }
        this.setState(this.state)
    };

    handleunitConvFactChange = (index, idx) => evt => {
        const re = /([^\s]+)/;
        if (evt.target.value === '' || re.test(evt.target.value)) {
            // eslint-disable-next-line
            this.state.attributes[idx].unitCodes[index].convFact = evt.target.value;
        }
        this.setState(this.state)
    };

    ondropdownChange = (e, name) => {
        this.setState({ [name]: e });
        if (name === 'uom') {
            let newCodes = e && e.length > 0 && e.map((ele) => {
                return {
                    unitName: ele.value,
                    unitCode: "",
                    convFact: ele.conFact
                }
            });

            // console.log("before att", this.state.attributes);
            // console.log("final codes", newCodes);
            // console.log("uom", this.state.uom);

            let newAttrList = [];
            if (this.state.attributes && this.state.attributes.length > 0) {
                this.state.attributes.forEach((ele) => {
                    ele.unitCodes = newCodes;
                    newAttrList.push(ele);
                });
            } else {
                newAttrList.push({
                    name: '', code: '', description: '', quantity: '', quantityUnit: '', price: '',
                    unitCodes: newCodes
                });
            }

            // console.log("new attribute list====>", newAttrList);

            this.setState({
                attributes: newAttrList
            });

        }
    };

    finalSubmit = () => {
        // window.confirm("This is confirm that your units are going to store permanent as you can not edit or remove uom from this particular product. Do you want to store then click 'ok'.");
        this.setState({
            isUnitSub: true
        }, () => {
            this.setDefaultUOM();
        });
    }

    setDefaultUOM = () => {
        // console.log("before", this.state.defaultUOMList);
        // console.log("this.state.uom", this.state.uom, this.state.uom.length);
        this.setState({
            defaultUOMList: this.state.uom
        }, () => {
            if (this.state.uom && this.state.uom.length === 1) {
                this.setState({
                    defaultUOM: this.state.uom[0]
                });
            }
        });

    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" },
            { name: 'brand', label: "Brand", value: this.state.brand, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "brandError" },
            // { name: 'itemCode', label: "Product ERP code", value: this.state.itemCode, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "itemCodeError" },
            { name: 'price', label: "Ex-Mill Price", value: this.state.price, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "priceError" },
            { name: 'whlprice', label: "Wholesaler Price", value: this.state.whlprice, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "whlPriceError" },
            { name: 'defaultUOM', label: "Default UOM", value: this.state.defaultUOM, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "defaultUOMError" },
            { name: 'uom', label: "UOM", value: this.state.uom, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "uomError" },
        ];

        // console.log(this.state.pageType);

        if (this.state.pageType === 'update') {
            validationFields.pop();
        }

        // console.log(validationFields);

        let attri = this.state.attributes;
        let attributes = [];

        attri.map((item) =>
            attributes.push({
                name: item.name,
                code: item.code,
                quantity: item.quantity,
                description: item.description,
                quantityUnit: item.quantityUnit || "NA",
                price: item.price,
                unitCodes: item.unitCodes
            })
        )

        let finalUOM = [];
        if (this.state.uom && this.state.uom.length > 0) {
            finalUOM = this.state.uom.map((ele) => {
                return ele.value
            });
        }

        let data = {
            name: this.state.name,
            code: this.state.code,
            price: parseFloat(this.state.price),
            whlprice: parseFloat(this.state.whlprice),
            brand: this.state.brand.value,
            // itemCode: this.state.itemCode,
            uom: finalUOM || [],
            attributes,
            defaultUOM: this.state.defaultUOM
        }

        // console.log(data.uom);

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        if (this.state.isUnitSub === false && this.state.pageType === 'add') {
            alert('Please submit the UOM Button for this product');
            // e.preventDefault();
            return true;
        }

        let isAnyNullShadeName = 0;

        // console.log("final submit", this.state.attributes);

        if (this.state.attributes && this.state.attributes && this.state.attributes.length > 0) {
            this.state.attributes.forEach((ele) => {
                if (!ele.name) {
                    ele.shadeError = "Shade Name is required";
                    isAnyNullShadeName++;
                } else {
                    ele.shadeError = null;
                }
            });

            this.setState({ attributes: this.state.attributes });
        }

        // console.log(isAnyNullShadeName);
        if (valid) {
            if (isAnyNullShadeName <= 0) {
                this.setState({ isLoading: true });
                if (this.state.pageType === "update") {
                    this.props.productmanageupdate(data, this.state.itemId);
                } else {
                    this.props.productmanagecreate(data);
                }
                validationFields = null;
                newArr = null;
            } else {
                alert('Please fill the missing details');
            }
        }
        // console.log(this.state);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

            if (nextProps.uommanagement_res && nextProps.uommanagement_res.list && nextProps.uommanagement_res.list.length > 0) {
                let displayUOMList = {};
                nextProps.uommanagement_res.list.forEach((ele) => {
                    displayUOMList[ele.code] = ele.name;
                });
                this.setState({
                    uomListRes: nextProps.uommanagement_res,
                    displayUOM: displayUOMList
                }, () => {
                    if (this.state.uomListRes && this.state.uomListRes.count > 0) {
                        this.setAllUomList();
                        this.setSelecetdUomList();
                    }
                });
            }

            if (nextProps.product_single && nextProps.product_single.success) {

                nextProps.product_single.success = null;
                this.setState({ getSingleLoader: false });

                if (nextProps.product_single.item) {
                    if (nextProps.product_single.item && nextProps.product_single.item.uom) {
                        this.setState({
                            uom: nextProps.product_single.item.uom || []
                        }, () => {
                            // console.log("uom" , this.state.uom);
                            this.setSelecetdUomList();
                        });
                    }

                    if (this.state.pageType === "update") {
                        this.setState({
                            isUnitSub: true
                        }, () => {
                            // this.setDefaultUOM();
                        });
                    }

                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.product_single.item, value.name) })
                        } else if (value.type === "select" && getValue(nextProps.product_single.item, value.name)) {
                            let obj = {
                                label: nextProps.product_single.item[value.name].name,
                                value: nextProps.product_single.item[value.name]._id,
                            };
                            this.setState({ [value.stateName]: obj });
                            obj = null;
                        }
                    });
                }

            }

        }
        return true;
    }

    setSelecetdUomList = () => {
        // console.log("select", this.state.uom, this.state.pageType, this.state.uomListRes.list);
        if (this.state.pageType === "update" && this.state.uom && this.state.uom.length > 0 && this.state.uomListRes && this.state.uomListRes.list && this.state.uomListRes.list.length > 0) {
            let tempUOM = [];
            this.state.uomListRes.list.forEach((ele) => {
                this.state.uom.forEach((uom) => {
                    // console.log(uom);
                    if (uom && typeof uom === "string") {
                        // console.log("called-1");
                        if (uom === ele.code) {
                            tempUOM.push(this.callPushData(ele));
                        }
                    } else if (uom && typeof uom === "object") {
                        // console.log("called-2");
                        if (uom.value === ele.code) {
                            tempUOM.push(this.callPushData(ele));
                        }
                    }
                });
            });

            this.setState({
                uom: tempUOM,
                defaultUOMList: tempUOM
            }, () => {
                // console.log("uom2=>", this.state.uom);
            });
        }
    }

    callPushData = (ele) => {
        return {
            label: ele.name,
            value: ele.code,
            conFact: ele.conFact,
            isFixed: true
        }
    }

    setAllUomList = () => {
        if (this.state.uomListRes && this.state.uomListRes.list && this.state.uomListRes.list.length > 0) {
            let tempUOM = [];
            this.state.uomListRes.list.forEach((ele) => {
                tempUOM.push({
                    label: ele.name,
                    value: ele.code,
                    conFact: ele.conFact
                });
            });

            this.setState({
                allUOMList: tempUOM
            });
        }
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update Product" : "Add Product"}
                                    </div>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Product Name" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} maxlength="10" type="text" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Product Code" />
                                                </div>
                                                {/* <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.itemCodeError} handleChange={this.handleChange} maxlength="100" type="text" name="itemCode" value={this.state.itemCode} className="form-control" id="itemCode" aria-describedby="codeHelp" placeholder="Product ERP Code" />
                                                </div> */}
                                                <div className="form-group required col-12 col-lg-4">
                                                    <SingleBrandDropDown
                                                        value={this.state.brand}
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "brand")}
                                                        placeholder="Search Brand"
                                                        errMessage={this.state.brandError}
                                                    />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.priceError} handleChange={this.handleChange} type="text" maxlength="10" name="price" value={this.state.price} className="form-control" id="price" aria-describedby="codeHelp" placeholder="Ex-Mill Price" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.whlPriceError} handleChange={this.handleChange} type="text" name="whlprice" value={this.state.whlprice} className="form-control" id="whlprice" aria-describedby="codeHelp" placeholder="Wholesaler Price" />
                                                </div>
                                                {
                                                    pageType === 'add' ?
                                                        <div className="col-12">
                                                            <div className="form-group alert alert-info">
                                                                NOTE: Once you click the "submit UOM button"
                                                                your UOM code is store permantely for this particular product.
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    pageType === 'add' && !this.state.isUnitSub ?

                                                        <div className="form-group required col-12 col-lg-4" disable={this.state.finalSubmit}>
                                                            <UomDropDown
                                                                value={this.state.uom}
                                                                ondropdownChange={(e) => this.ondropdownChange(e, "uom")}
                                                                errMessage={this.state.uomError}
                                                            />

                                                            {/* <div className="col-12 col-lg-4">
                                                                <Buttons id="submit_units" className="btn btn-primary mr-1" onClick={this.unitSubmit.bind(this)}>Submit Units</Buttons>
                                                            </div> */}

                                                        </div> : null
                                                }
                                                {
                                                    pageType === 'add' && !this.state.isUnitSub ?
                                                        <div className="form-group required col-12 col-lg-1 mt-35 mr-4">
                                                            <button type="button" className="btn btn-primary" disabled={(this.state.attributes && this.state.attributes.length === 0) || this.state.uom === null || (this.state.uom && this.state.uom.length === 0)}
                                                                onClick={this.finalSubmit}>
                                                                Submit UOM
                                                            </button>
                                                        </div> : null
                                                }
                                                {/* {
                                                    console.log(this.state.allUOMList, this.state.uom, pageType, this.state.isUnitSub)
                                                } */}
                                                {
                                                    pageType === "update" && this.state.isUnitSub ?
                                                        <div className='col-12 col-lg-4'>
                                                            <div className="form-group required">
                                                                <label>UOM</label>
                                                                <Select
                                                                    value={this.state.uom}
                                                                    isMulti
                                                                    styles={styles}
                                                                    isClearable={this.state.uom.some((v) => !v.isFixed)}
                                                                    name="UOM"
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="Add Uoms"
                                                                    onChange={this.onChangeSelect}
                                                                    options={this.state.isUnitSub ? this.state.allUOMList : []}
                                                                />
                                                            </div>
                                                        </div> : null
                                                }

                                                {
                                                    this.state.isUnitSub ?
                                                        <div className="form-group required col-12 col-lg-4">
                                                            <label className="control-label">Default UOM</label>
                                                            <SearchableDropDown
                                                                value={this.state.defaultUOM}
                                                                isMulti={false}
                                                                onChange={(e) => this.ondropdownChange(e, "defaultUOM")}
                                                                options={this.state.isUnitSub ? this.state.defaultUOMList : []}
                                                                prefixValue="defaultUOM"
                                                                instanceId="defaultUOM-id"
                                                                placeholder={"Select Default UOM"}
                                                            />
                                                            <ErrorView message={this.state.defaultUOMError} />
                                                        </div> : null
                                                }

                                            </div>

                                            {
                                                this.state.isUnitSub && this.state.attributes && this.state.attributes.length > 0 ?

                                                    <div className="card-body">
                                                        <div className="form-row m-2">
                                                            <div className="col"><label htmlFor="ShadeName" className="control-label">Shade Name</label></div>
                                                            <div className="col"><label htmlFor="ShadeCode" className="control-label">Shade Code</label></div>
                                                            <div className="col"><label htmlFor="Description" className="control-label">Description</label></div>
                                                            {/* <div className="col"><label htmlFor="exampleInputName1" className="control-label">Shade Quantity</label></div> */}
                                                            {/* <div className="col"><label htmlFor="Shade_Quantity_Unit" className="control-label">Shade Quantity Unit</label></div> */}
                                                            {/* <div className="col"><label htmlFor="exampleInputName1" className="control-label">Price</label></div> */}
                                                            {
                                                                this.state.attributes.length !== 0 ? null :
                                                                    <button type="button" className="btn btn-primary"
                                                                        onClick={this.handleAddattributes}>
                                                                        <i className="fa fa-plus mr-2 "></i>
                                                                    </button>
                                                            }
                                                        </div>

                                                        {
                                                            this.state.attributes.map((attributes, idx) => (
                                                                <div key={idx} className='border border-info p-3 mb-2 bg-white rounded'>

                                                                    <div className="form-row m-2" key={idx}>
                                                                        <div className="col">
                                                                            <input type="text" className="form-control" name="name" id={"name" + idx} onChange={this.handleatttributesNameChange(idx)} maxlength="100" placeholder="Shade Name" value={attributes.name} required />
                                                                            {
                                                                                attributes.shadeError ?
                                                                                    <small className='text-danger' id={"shadenameErr" + idx}>{attributes.shadeError}</small> : null
                                                                            }
                                                                        </div>
                                                                        <div className="col">
                                                                            <input type="text" className="form-control" name="code" id={"code" + idx} onChange={this.handleatttributescodeChange(idx)} placeholder="Shade Code" value={attributes.code} maxlength="200" required />
                                                                        </div>
                                                                        <div className="col">
                                                                            <textarea type="text" className="form-control" name="description" id={"description" + idx} maxlength="5000" onChange={this.handleatttributesDescriptionChange(idx)} placeholder="Shade description" value={attributes.description} required />
                                                                        </div>
                                                                        {/* <div className="col">
                                                                            <input type="text" className="form-control" name="quantity" maxlength="10" onChange={this.handleatttributesquantityChange(idx)} placeholder="Shade Quantity" value={attributes.quantity} required />
                                                                        </div> */}
                                                                        {/* {console.log(attributes.quantityUnit)} */}
                                                                        {/* <div className="col">
                                                                            <input type="text" className="form-control" name="quantityUnit" id={"quantityUnit" + idx}
                                                                                onChange={this.handleatttributesquantityunitChange(idx)} placeholder="Shade Quantity Unit"
                                                                                value={attributes.quantityUnit} required />
                                                                        </div> */}
                                                                        {/* <div className="col">
                                                                            <input type="text" className="form-control" name="price" onChange={this.handleatttributespriceChange(idx)} placeholder="Price" value={attributes.price} required />
                                                                        </div> */}
                                                                        {
                                                                            idx === this.state.attributes.length - 1 ?
                                                                                <div className="prod-button">
                                                                                    <div className="flex-shrink-1">
                                                                                        <button type="button" className="btn btn-primary mr-2" onClick={this.handleAddattributes} id={"add" + idx}><i className="fa fa-plus "></i> </button>
                                                                                    </div>
                                                                                    {
                                                                                        pageType === 'update' && this.state.attributes.length > 1 ? <div className="flex-shrink-1">
                                                                                            <button type="button" className="btn btn-danger" onClick={this.handleRemoveattributes(idx)} id={"remove" + idx}> <i className="fa fa-minus"></i>  </button>
                                                                                        </div> : null
                                                                                    }

                                                                                </div> : <div className="flex-shrink-1">
                                                                                    <button type="button" className="btn btn-danger" onClick={this.handleRemoveattributes(idx)} id={"remove_other" + idx}> <i className="fa fa-minus"></i>  </button>
                                                                                </div>
                                                                        }

                                                                    </div>
                                                                    <div className="form-row m-2">
                                                                        <div className="col">
                                                                            <label htmlFor="uom_code" id="uom_code_label">UOM Code</label>
                                                                        </div>
                                                                        <div className="col">
                                                                            <label htmlFor="code" id="code_label">Code</label>
                                                                        </div>
                                                                        <div className="col">
                                                                            <label htmlFor="conv_fact" id="conv_fact_label">Coversion Factor</label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        attributes.unitCodes && attributes.unitCodes.map((item, index) => (
                                                                            <div className="form-row m-2" key={index}>
                                                                                <div className="col">
                                                                                    {/* <label htmlFor="uom_code" id="uom_code_label">UOM Code</label> */}
                                                                                    <input type="text" className="form-control" name="price" onChange={this.handleunitNameChange(index)} placeholder="Name" id={"name" + idx + "_" + index} value={this.state.displayUOM[item.unitName] || item.unitName} required readOnly />
                                                                                </div>
                                                                                <div className="col">
                                                                                    {/* <label htmlFor="code" id="code_label">Code</label> */}
                                                                                    <input type="text" className="form-control" name="unitCode" onChange={this.handleunitCodeChange(index, idx)} placeholder="Code" id={"code" + idx + "_" + index} value={item.unitCode} required />
                                                                                </div>
                                                                                <div className="col">
                                                                                    {/* <label htmlFor="conv_fact" id="conv_fact_label">Coversion Factor</label> */}
                                                                                    <input type="text" className="form-control" name="convFact" onChange={this.handleunitConvFactChange(index, idx)} placeholder="ConvFact" id={"convFact" + idx + "_" + index} value={item.convFact} required />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ))
                                                        }

                                                    </div> : null
                                            }

                                        </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <Buttons onclick={this.onsubmit} type="submit" id="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                        <Links to={routehelp.productmanagement} className="btn btn-outline-dark" name="Cancel" />
                    </div>
                </div>
            </main >

        )
    }
}

function mapStateToProps(state) {
    return {
        uommanagement_res: state.uommanagement_res,
        product_create_res: state.product_create_res,
        product_single: state.product_single,
        product_update: state.product_update
    };
}


const mapDispatchToProps = dispatch => ({
    uommanagementlist: data => dispatch(uommanagementlist(data)),
    productmanagecreate: data => dispatch(productmanagecreate(data)),
    productsingle: data => dispatch(productsingle(data)),
    productmanageupdate: (data, id) => dispatch(productmanageupdate(data, id))
});

Product_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Product_manage_add);

export default Product_manage_add;