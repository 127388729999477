/* eslint-disable */
import React, { Component, Fragment, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { uommanagementlist, orderTemplate_create, orderTemplate_get, orderTemplate_update, productsingle } from '../../actions';
import { validate, errorRender, successRedriect, getValue } from '../../helpers/functions';
import 'reactjs-toastr/lib/toast.css';
require('react-datetime');

const ProductDropdown = lazy(() => import('../productManagement/productDropdown'));
const SelectInputField = lazy(() => import('../../components/selectInputField'));
const SingleBrandDropDown = lazy(() => import('../../container/brandsManagement/singleBrandDropDown'));

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));

class OrderTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageType: 'add',
            shadeDesc: {},
            name: "",
            nameError: "",
            uomDisplay: {},
            isMultiple: false,
            brandError: "",
            brand: "",
            product: "",
            products: [],
            productError: "",
            uom: [{
                label: "Please select UOM",
                value: "",
                code: "",
                conFact: ""
            }],
            shade: [],
            totalAmount: 0,
            price: 0,
            uom_data: "",
            isLoading: false,
            getSingleLoader: false,
            filterProduct: {},
            note: "",
            uomError: "",
            unitCodes: [],
            multiplier: 1,
            subTotal: 0,
            serverErrors: ["orderTemplate_create_res", "orderTemplate_update_res"],
            forRedirect: [
                { name: "orderTemplate_create_res", pageName: 'Order Template', pageUrl: 'orderTemplateList', action: "create" },
                { name: "orderTemplate_update_res", pageName: 'Order Template', pageUrl: 'orderTemplateList', action: "update" }
            ],
            multipleProducts: [],
            alertMessage: "",
            productTotal: 0,
            price: 0,
            setFields: [
                { name: "name", type: "text" },
                { name: "brand", type: "select" },
                { name: "totalQuantity", type: "text" },
                { name: "totalAmount", type: "text" },
                { name: "note", type: "text" },
                { name: "products", type: "array" }
            ],
            productDetails: [],
        }
    };

    componentDidMount() {
        this.loadResult();
    };

    loadResult = () => {
        this.props.uommanagementlist({});
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.orderTemplate_get(id);
        }
        // this.setState({ isLoading: true });
    };

    QTYfun = (item, name, index, qty = 0) => {

        let shadeUpdate = this.state.shade;

        if (name === "add") {

            if (shadeUpdate && shadeUpdate.length > 0) {
                shadeUpdate.forEach((ele, i) => {
                    if (!ele.selectedQTY) {
                        ele.selectedQTY = 0;
                    }

                    if (typeof ele.quantity === "string" && ele.quantity !== "0") {
                        ele.quantity = Number(ele.quantity);
                    }

                    if (typeof ele.quantity === "string" && ele.quantity === "0") {
                        ele.quantity = 0;
                    }

                    if (i === index) {
                        // if (ele.selectedQTY < ele.quantity) {
                        if (typeof ele.selectedQTY === "string") {
                            ele.selectedQTY = Number(ele.selectedQTY);
                        }
                        ele.selectedQTY++;
                        // } 
                        // else {
                        //     ele.selectedQTY = ele.quantity;
                        //     alert(`Selected Qty is greather then max qty. Please check max Qty of shade name: ${item.name} and select accordingly`);
                        //     return true;
                        // }
                    }

                });
            }

        }

        if (name === "minus") {
            if (shadeUpdate && shadeUpdate.length > 0) {
                shadeUpdate.forEach((ele, i) => {
                    if (!ele.selectedQTY) {
                        ele.selectedQTY = 0;
                    }
                    if (i === index && ele.selectedQTY > 0) {
                        if (typeof ele.selectedQTY === "string") {
                            ele.selectedQTY = Number(ele.selectedQTY);
                        }
                        ele.selectedQTY--;
                    }
                });
            }
        }

        if (name === "change") {

            // if (typeof qty === "string" && qty !== "0") {
            //     qty = Number(qty);
            // }

            // if (typeof qty === "string" && qty === "0") {
            //     qty = 0;
            // }

            if (typeof item.quantity === "string" && item.quantity !== "0") {
                item.quantity = Number(item.quantity);
            }

            if (qty < 0) {
                alert("Negative value is not allowed");
                qty = 0;
            }

            // if (qty > item.quantity) {
            //     // qty = item.quantity;
            //     alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${item.name} and select accordingly`);
            //     return true;
            // }

            if (shadeUpdate && shadeUpdate.length > 0) {
                shadeUpdate.forEach((ele, i) => {

                    if (i === index) {
                        // if (qty > item.quantity) {
                        //     qty = item.quantity;
                        // }
                        // console.log(qty);

                        ele.selectedQTY = qty;
                    }

                });
            }

            // console.log(qty);
            // console.log(item.selectedQTY);
        }

        // console.log("shadeupdate", shadeUpdate);
        // console.log("uom", this.state.unitCodes);

        this.setState({
            shade: shadeUpdate
        });

        this.calculation(shadeUpdate);
        // console.log("item", item);
    };

    componentWillUnmount() {
        this.setState({});
    };

    calculation(shadeList = []) {

        // console.log("selectUOM", this.state.uom_data);
        // console.log("productP", this.state.price);

        // console.log("==============called calfun==============");
        // console.log("Shade List", shadeList);

        if (shadeList && shadeList.length > 0) {

            let total = 0;
            let totalQuantity = 0;
            let mainSubTotal = 0;

            shadeList.forEach((ele) => {
                // console.log(ele.quantity);
                // if (Number(ele.quantity) > 0) {
                // if (Number(ele.selectedQTY) <= Number(ele.quantity)) {
                // console.log(this.state.unitCodes);
                if (this.state.unitCodes && this.state.unitCodes.length > 0) {
                    this.state.unitCodes.forEach((code) => {
                        if (code.name === ele.name) {
                            code.unitCodes.forEach((e) => {
                                let subTotal = 0;
                                if (e.unitName === this.state.uom_data) {
                                    // console.log("selected UOM", this.state.uom_data);
                                    // console.log("convFact", e.convFact);
                                    totalQuantity = Number(totalQuantity) + Number(ele.selectedQTY);
                                    subTotal = parseFloat(e.convFact || 1) * parseFloat(ele.selectedQTY) * parseFloat(this.state.price);
                                    total = parseFloat(total) + parseFloat(subTotal);
                                    mainSubTotal = parseFloat(mainSubTotal) + parseFloat(subTotal);
                                }
                            });
                        }
                    });
                }
                // }
                // }
            });

            // console.log("product price", this.state.price);
            // console.log("uom", this.state.uom_data);
            // console.log("type", this.state.utype, "multiplier", this.state.multiplier);
            // console.log("totalPqty", totalQuantity);
            // console.log("subTotal", mainSubTotal);
            // console.log("total", total);
            // console.log("unitcodes", this.state.unitCodes);

            // console.log("================end==============");

            this.setState({
                totalQuantity: totalQuantity,
                // totalAmount: total,
                productTotal: parseFloat(total).toFixed(2),
                subTotal: parseFloat(mainSubTotal).toFixed(2)
            });

        }
    };

    dropdownValueChange = (value, name) => {
        // console.log(name, value);
        if (name === "brand") {
            this.setState({
                [name]: value,
                product: null,
                uom: [{
                    label: "Please select UOM",
                    value: "",
                    code: "",
                    conFact: ""
                }],
                shade: [],
                uom_data: "",
                totalAmount: 0,
                totalQuantity: 0,
                productTotal: 0,
                subTotal: 0,
                price: 0,
                filterProduct: {
                    brand: value.value
                },
                multiplier: value.multiplier
            });
        } else if (name === "product") {
            // console.log(name, value.items);
            let tempArr = [value.items];
            let uomFilterData = value && value.items && value.items.attributes && value.items.attributes.length > 0 &&
                value.items.attributes[0].unitCodes && value.items.attributes[0].unitCodes.length > 0 ?
                value.items.attributes[0].unitCodes.map((ele) => {
                    return {
                        label: this.state.uomDisplay[ele.unitName] || ele.unitName,
                        value: ele.unitName,
                        code: ele.unitName,
                        conFact: ele.convFact || 1
                    }
                }) : [];

            // console.log("shade", this.state.shade);

            if (value && value.items && value.items.stockAttributes && value.items.stockAttributes.length > 0) {
                value.items.stockAttributes = value.items.stockAttributes.map((ele) => {

                    let filtArr = value.items.attributes.filter((att) => {
                        if (att.name == ele.name) {
                            return att;
                        }
                    });

                    ele.selectedQTY = 0;
                    ele.code = !filtArr[0].code ? filtArr[0].name : filtArr[0].code;
                    ele.description = filtArr[0].description;
                    ele.shade = filtArr[0].name;
                    ele.productPrice = value.items.price;
                    // console.log(ele);
                    return ele;
                });
            }

            // console.log(value.items.stockAttributes);

            this.setState({
                // totalAmount: 0,
                productTotal: 0,
                totalQuantity: 0,
                subTotal: 0,
                products: tempArr,
                product: value,
                price: value.items.price,
                uom: [...[{
                    label: "Please select UOM",
                    value: "",
                    code: "",
                    conFact: ""
                }], ...uomFilterData] || [],
                unitCodes: value.items.attributes || [],
                uom_data: "",
                shade: value && value.items && value.items.stockAttributes && value.items.stockAttributes.length > 0 ?
                    value.items.stockAttributes || [] :
                    []
            }, () => {
                // console.log("before", this.state.shade);

                const sorter = (a, b) => {
                    // console.log(a, b);
                    const isNumber = (v) => (+v).toString() === v;
                    const aPart = a.name.match(/\d+|\D+/g);
                    const bPart = b.name.match(/\d+|\D+/g);
                    let i = 0; let len = Math.min(aPart.length, bPart.length);
                    while (i < len && aPart[i] === bPart[i]) { i++; };
                    if (i === len) {
                        return aPart.length - bPart.length;
                    };
                    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
                        return aPart[i] - bPart[i];
                    };
                    return aPart[i].localeCompare(bPart[i]);
                };

                if (this.state.shade && this.state.shade.length > 0) {
                    this.state.shade.sort(sorter);
                };

                // console.log("after", this.state.shade);

                this.setState({
                    shade: this.state.shade
                });

            });


        } else if (name === "shade") {
            this.setState({ [name]: value });
            this.calculation(this.state.shade || []);
        } else if (name === "uom_data") {
            this.setState({ [name]: value }, function () {
                // console.log("called-callback");
                this.calculation(this.state.shade);
            });
        } else {
            this.setState({ [name]: value });
        }
    };

    blankAllData = (type) => {
        if (type === "multiple") {
            this.setState({
                product: "",
                products: [],
                uom: [{
                    label: "Please select UOM",
                    value: "",
                    code: "",
                    conFact: ""
                }],
                shade: [],
                // totalAmount: 0,
                productTotal: 0,
                price: 0,
                uom_data: "",
                unitCodes: [],
                // multiplier: 1,
                subTotal: 0,
                totalQuantity: 0
            });
        }
    };

    onsubmit = (e) => {
        let saveButtonOpen = false;
        let productList = [];
        if (this.state.multipleProducts && this.state.multipleProducts && this.state.multipleProducts.length > 0) {
            this.state.multipleProducts.forEach((ele) => {
                if (ele[0].isEditOpen) {
                    saveButtonOpen = true;
                    productList.push(ele[0].productName);
                }
            });
        }
        if (!saveButtonOpen) {
            // consoel.log(this.state.isMultiple, this.state.multipleProducts);
            if (this.state.isMultiple && this.state.multipleProducts && this.state.multipleProducts.length > 0 && +this.state.totalQuantity > 0 && +this.state.totalAmount > 0) {
                let savedProductsDetails = [];

                if (this.state.multipleProducts && this.state.multipleProducts.length > 0) {
                    this.state.multipleProducts.forEach((ele) => {
                        // console.log(ele[0]);
                        savedProductsDetails.push(ele[0]);
                    });
                }

                let finalProductArray = savedProductsDetails;

                if (finalProductArray && finalProductArray.length > 0) {
                    let duplicateP = finalProductArray.map((ele) => { return { name: ele.productName, uom: ele.measurementUnit.code } });
                    let isAllow = this.checkIfArrayIsUnique(duplicateP);
                    if (!isAllow) {
                        let data = this.callFinalJson(finalProductArray);
                        data.totalAmount = 0;
                        data.totalQuantity = 0;
                        if (data.products && data.products.length > 0) {
                            data.products.forEach((ele) => {
                                data.totalAmount += Number(ele.subTotal);
                                data.totalQuantity += Number(ele.totalProductQty);
                            });
                        }
                        if (data.totalQuantity > 0) {
                            data.totalSaleAmount = parseFloat(data.totalAmount).toFixed(2);
                            data.totalPaidAmount = parseFloat(data.totalAmount).toFixed(2);
                            // console.log("payload", data);
                            this.setState({
                                isLoading: true,
                                getSingleLoader: false
                            });
                            this.callApi(data, e);
                        } else {
                            this.setState({
                                alertMessage: "Please select a minimum 1 Quantity to add the new product for Order Template."
                            });
                        }
                    } else {
                        this.setState({
                            alertMessage: "Please check your added products details. Duplicate products with same uom is not allowed. Please remove it and try again."
                        });
                    }
                } else {
                    this.setState({
                        alertMessage: "Please add any product to create/update order template."
                    });
                }
            } else {
                this.setState({
                    alertMessage: "Please add any product or shade to create/update order template."
                });
            };
        } else {
            alert(`Please save the edited details. Products are ${productList.map((ele) => ele).join(", ")}. Then try to update the order.`)
        };
    };

    checkIfArrayIsUnique = (myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            for (var j = 0; j < myArray.length; j++) {
                if (i !== j) {
                    if (myArray[i].name === myArray[j].name && myArray[i].uom === myArray[j].uom) {
                        return true; // means there are duplicate values
                    }
                }
            }
        }
        return false; // means there are no duplicate values.
    };

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);

        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.setState({ getSingleLoader: false });
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

            if (nextProps.uommanagement_res && nextProps.uommanagement_res.count > 0) {
                let displayUOM = {};
                nextProps.uommanagement_res.list.forEach((ele) => {
                    // console.log(ele);
                    displayUOM[ele.code] = ele.name;
                });
                this.setState({
                    uomDisplay: displayUOM
                });
                // console.log(disPlayUOM);
            }

            if (nextProps.orderTemplate_get_res && nextProps.orderTemplate_get_res.success) {
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.name]: getValue(nextProps.orderTemplate_get_res.item, value.name) })
                    } else if (value.type === "select") {
                        this.setState({
                            [value.name]: {
                                value: getValue(nextProps.orderTemplate_get_res.item, value.name)._id,
                                label: getValue(nextProps.orderTemplate_get_res.item, value.name).name
                            }
                        }, () => {
                            if (value.name === "brand") {
                                this.setState({
                                    filterProduct: { brand: getValue(nextProps.orderTemplate_get_res.item, value.name)._id }
                                });
                            };
                        });
                    } else if (value.type === "array") {
                        if (value.name === "products") {
                            this.setState({
                                isMultiple: true,
                                multipleProducts: this.convertMultipleProducts(nextProps.orderTemplate_get_res.item.products || [])
                            });
                        }
                    }
                });
                nextProps.orderTemplate_get_res.success = null;
            };

            if (nextProps.product_single && nextProps.product_single.success) {
                if (nextProps.product_single.item) {

                    let productDetails = this.state.productDetails || [];
                    if (productDetails && productDetails.length > 0 && productDetails.findIndex((e) => e._id === nextProps.product_single.item._id) !== -1) {
                        let productInd = productDetails.findIndex((e) => e._id === nextProps.product_single.item._id);
                        productDetails[productInd] = nextProps.product_single.item;
                    } else {
                        // console.log(nextProps.product_single.item);
                        productDetails.push(nextProps.product_single.item);
                    };

                    this.setState({
                        productDetails: productDetails
                    }, () => {
                        this.checkingStocks();
                    });

                };
                nextProps.product_single.success = null;
            }

        }

        return true;
    };

    convertMultipleProducts(data) {
        let updateFromat = [];
        if (data && data.length > 0) {
            data.forEach((ele) => {
                ele.totalProductQty = 0;
                if (ele.attributes && ele.attributes.length > 0) {
                    ele.attributes.forEach((code) => {
                        code.shadeQuantity = code.quantity;
                        code.productPrice = ele.productPrice;
                        code.shade = code.name;
                        ele.totalProductQty += Number(code.quantity);
                    });
                }
                updateFromat.push([ele]);
            });
        };

        // console.log(updateFromat);
        return updateFromat;
    };

    onChangeFun = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    };

    addMoreItem = () => {
        let valid = this.callCheckValidations();
        if (valid && this.state.totalQuantity > 0) {
            let productsArr = this.callSingleProsuctDetails();
            let data = this.callFinalJson(productsArr);
            // let finalData = this.state.multipleProducts;

            let finalData = [];
            finalData.push(data.products);

            let ptotal = 0;
            if (data.products && data.products.length > 0) {
                data.products.forEach((ele) => {
                    ptotal += ele.totalAmount;
                });
            };

            // console.log(finalData);

            let multipleObj = this.state.multipleProducts || [];

            if (finalData && finalData.length > 0) {
                finalData.forEach((ele) => {
                    if (ele[0].product && this.state.multipleProducts.findIndex((e, index) => JSON.stringify(ele[0].product) === JSON.stringify(e[0].product)) < 0) {
                        multipleObj.push([ele[0]]);
                    } else {
                        alert("Duplicate product is not allowed. Try to edit instead of add the product");
                    }
                });
            };

            // console.log(multipleObj);
            // console.log(finalData);

            this.setState({
                isMultiple: true,
                multipleProducts: multipleObj,
                productTotal: parseFloat(ptotal).toFixed(2)
            }, () => {
                this.countFinalTotal();
            });

            this.blankAllData("multiple");
            // console.log("Multiple Products", this.state.multipleProducts);
            this.setState({
                alertMessage: ""
            });

        } else if (this.state.totalQuantity === 0) {

            this.setState({
                alertMessage: "Please select a minimum 1 Quantity to add the new product for create/update order template."
            });

            // alert("Please select a minimum 1 Quantity to add the new product for create/update order template.");
        };
    };

    countFinalTotal = () => {
        let total = 0;
        let qty = 0;
        if (this.state.multipleProducts && this.state.multipleProducts.length > 0) {
            this.state.multipleProducts.forEach((ele) => {
                // console.log(ele);
                if (ele[0].attributes && ele[0].attributes.length > 0) {
                    ele[0].attributes.forEach((e) => {
                        if (e.quantity) {
                            qty += +e.quantity;
                        }
                    });
                };
                total = Number(total) + Number(ele[0].subTotal)
            });
        };

        // console.log("total", total);
        // this.setState({
        //     totalAmount: parseFloat(total).toFixed(2)
        // });

        if (!isNaN(qty)) {
            this.setState({
                totalQuantity: qty,
                totalAmount: parseFloat(total).toFixed(2),
                // multipleProducts: multipleProducts
            });
        }
        // console.log(this.state.totalAmount);
    };

    callSingleProsuctDetails = () => {

        let productsArr = [{
            product: this.state.product && this.state.product.value ? this.state.product.value : undefined,
            productName: this.state.product && this.state.product.label ? this.state.product.label : undefined,
            productErpCode: "",
            productPrice: this.state.price,
            attributes: [],
            subTotal: 0,
            totalProductQty: 0,
            totalAmount: 0
        }];

        // console.log("shade", this.state.shade);
        // console.log("uom List", this.state.uom);
        // console.log("unitCodes", this.state.unitCodes);

        if (this.state.shade && this.state.shade.length > 0) {
            this.state.shade.forEach((sh) => {
                if (this.state.unitCodes && this.state.unitCodes.length > 0) {
                    this.state.unitCodes.forEach((code) => {
                        if (JSON.stringify(sh.name) === JSON.stringify(code.name) && Number(sh.selectedQTY) > 0) {
                            if (code && code.unitCodes.length > 0) {
                                code.unitCodes.forEach((cd) => {
                                    if (JSON.stringify(cd.unitName) === JSON.stringify(this.state.uom_data)) {
                                        let measureData = {};
                                        productsArr[0].subTotal = 0;
                                        if (this.state.uom_data && this.state.uom && this.state.uom.length > 0) {
                                            this.state.uom.forEach((ud) => {
                                                if (JSON.stringify(ud.value) === JSON.stringify(this.state.uom_data)) {
                                                    measureData.value = ud.conFact;
                                                    measureData.label = ud.label;
                                                    measureData.code = ud.code;
                                                }
                                            });
                                        };
                                        // console.log(sh);
                                        productsArr[0].attributes.push({
                                            unitCode: { ...cd, shade: sh.name },
                                            convFact: cd.convFact || 1,
                                            productPrice: this.state.price,
                                            quantity: sh.selectedQTY,
                                            shadeQuantity: sh.selectedQTY,
                                            shade: sh.name,
                                            code: sh.code,
                                            description: sh.description,
                                            name: sh.name,
                                            price: 0,
                                            quantityUnit: this.state.uom_data || "NA"
                                        });
                                        productsArr[0].subTotal = parseFloat(cd.convFact || 1) * parseFloat(sh.selectedQTY) * parseFloat(this.state.price);
                                        productsArr[0].totalAmount = parseFloat(productsArr[0].totalAmount) + parseFloat(productsArr[0].subTotal);
                                        productsArr[0].totalProductQty += Number(sh.selectedQTY);
                                        productsArr[0].measurementUnit = measureData;
                                        // console.log("totalAMount", productsArr[0].totalAmount);

                                    }
                                });
                            }
                        }
                    });
                }
            });
        }

        productsArr[0].subTotal = productsArr[0].totalAmount;
        return productsArr;
    };

    callFinalJson = (productsArr) => {
        const data = {
            name: this.state.name || null,
            brand: this.state.brand && this.state.brand.value ? this.state.brand.value : undefined,
            totalQuantity: this.state.totalQuantity,
            totalAmount: parseFloat(this.state.totalAmount).toFixed(2),
            note: this.state.note,
            products: productsArr,
            company: JSON.parse(JSON.stringify(localStorage.getItem('userId'))),
        }
        return data;
    };

    callCheckValidations = () => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "nameError" },
            { name: 'uom_data', label: "UOM", value: this.state.uom_data, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "uomError" },
            { name: 'brand', label: "Brand", value: this.state.brand, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "brandError" },
            { name: 'product', label: "Product", value: this.state.product, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "productError" },
        ];
        // console.log(validationFields);
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        return valid;
    };

    callApi = (data, e) => {
        e.preventDefault();

        // console.log("final Data", data);

        if (data.totalQuantity > 0) {

            if (this.state.pageType === 'add') {
                this.props.orderTemplate_create(data);
            }

            if (this.state.pageType === 'update') {
                this.props.orderTemplate_update(data, this.props.match.params.item_id);
            }

        } else if (data.totalQuantity === 0) {
            this.setState({
                alertMessage: "Please select the qty of any shade to create/update the order template."
            });
        }
    };

    deleteDuplicate = (e, index) => {

        e.preventDefault();
        let multipleProducts = this.state.multipleProducts;
        if (multipleProducts && multipleProducts.length > 0) {
            multipleProducts.forEach((ele, ind) => {
                if (index === ind) {
                    multipleProducts.splice(ind, 1);
                }
            });
        }

        this.setState({
            multipleProducts: multipleProducts
        }, () => {
            this.countFinalTotal()
        });

        // console.log("multiple", multipleProducts);
    };

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    };

    editRecord = (item, attr, value, index) => {
        // console.log(item, attr, value, index);
        // item.quantity = value;
        let newProducts = this.state.multipleProducts;
        if (newProducts && newProducts.length > 0) {
            newProducts.forEach((ele, i) => {
                if (ele[0].productName == item[0].productName) {
                    ele[0].totalProductQty = 0;
                    ele[0].subTotal = 0;
                    if (ele[0].attributes && ele[0].attributes.length > 0) {
                        ele[0].attributes.forEach((att) => {
                            // console.log(index, i);
                            // console.log(value, att);
                            if (att.name == attr.name && index == i) {
                                att.quantity = value;
                                att.shadeQuantity = value;
                            };
                            ele[0].subTotal = Number(ele[0].subTotal) + (!isNaN(parseFloat(att.convFact || 1) * parseFloat(att.quantity) * parseFloat(ele[0].productPrice)) ? parseFloat(att.convFact || 1) * parseFloat(att.quantity) * parseFloat(ele[0].productPrice) : 0);
                            ele[0].totalProductQty += (att.quantity ? Number(att.quantity) : 0);
                            // console.log(ele[0].subTotal);
                        });
                    }
                }
            });

            this.setState({
                multipleProducts: newProducts
            }, () => {
                this.countFinalTotal();
            });

        }

    };

    changeEditQty = (product, attri, index, qty = 0) => {
        // console.log(product, attri, index, qty);

        if (typeof attri.shadeQuantity === "string" && attri.shadeQuantity !== "0") {
            attri.shadeQuantity = Number(attri.shadeQuantity);
        }

        if (qty < 0) {
            alert("Negative value is not allowed");
            qty = 0;
        };

        // if (qty > attri.availableQty) {
        //     // qty = item.quantity;
        //     alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${attri.name} and select accordingly`);
        //     return true;
        // };

        let multipleProducts = this.state.multipleProducts || {};
        if (multipleProducts && multipleProducts.length > 0) {
            let findIndex = multipleProducts.findIndex((ele) => ele[0].product === product.product);
            // console.log(findIndex, index);
            // console.log(multipleProducts[findIndex][0]);
            // console.log(multipleProducts[0][findIndex]);
            if (findIndex > -1) {
                multipleProducts[findIndex][0].attributes[index].shadeQuantity = qty;
                multipleProducts[findIndex][0].attributes[index].quantity = qty;
                // console.log(multipleProducts[0][findIndex].attributes[index]);
            }
        };

        // console.log(this.state.multipleProducts);

        this.setState({
            multipleProducts: multipleProducts
        });

    };

    openDesc = (item) => {
        this.setState({
            shadeDesc: item
        });
    };

    editProduct = (item, index) => {
        let multipleProducts = this.state.multipleProducts || {};

        //edit option enable
        if (multipleProducts && multipleProducts.length > 0) {
            multipleProducts.forEach((ele, ind) => {
                if (ind === index) {
                    ele[0].isEditOpen = true;
                }
            });
        };

        //call product details
        this.props.productsingle(item.product);

        this.setState({
            multipleProducts: multipleProducts
        });

    };

    confirmEditProduct = (index) => {
        let multipleProducts = this.state.multipleProducts || {};

        // edit option enable
        if (multipleProducts && multipleProducts.length > 0) {
            multipleProducts.forEach((ele, ind) => {
                // console.log(ind === index);
                if (ind === index) {
                    if (ele[0].attributes && ele[0].attributes.length > 0) {
                        let openProducts = ele[0].attributes;
                        ele[0].attributes = ele[0].attributes.filter((attr) => +attr.quantity > 0 || +attr.shadeQuantity > 0);
                        // console.log("attributes", ele, ele[0].attributes);
                        if (ele[0].attributes && ele[0].attributes.length > 0) {
                            ele[0].subTotal = 0;
                            ele[0].totalProductQty = 0;
                            ele[0].attributes.forEach((attri) => {
                                if (multipleProducts.type === "wholesaler") {
                                    ele[0].subTotal += +attri.quantity * +ele[0].productPrice * +this.state.multiplier * (attri.convFact || 1);
                                } else {
                                    ele[0].subTotal += +attri.quantity * +ele[0].productPrice * (attri.convFact || 1);
                                };
                                ele[0].totalProductQty += +attri.quantity;
                            });
                            ele[0].totalProductQty = parseFloat(ele[0].totalProductQty).toFixed(2);
                            ele[0].subTotal = parseFloat(ele[0].subTotal).toFixed(2);
                            ele[0].isEditOpen = false;
                        } else {
                            ele[0].attributes = openProducts || [];
                            alert("Minimum one shade qty is required to save this product.")
                        };
                    };
                };
            });

        };

        this.setState({
            multipleProducts: multipleProducts
        }, () => {
            this.countFinalTotal();
        });

    };

    checkingStocks = () => {
        // console.log(this.state.productDetails, JSON.stringify(this.state.multipleProducts));
        let multipleProducts = this.state.multipleProducts || {};

        if (multipleProducts && multipleProducts.length > 0) {
            multipleProducts.forEach((eleP) => {
                if (eleP[0].isEditOpen) {
                    let findedProduct = this.state.productDetails.filter((e) => e._id === eleP[0].product);
                    // console.log(findedProduct);

                    if (findedProduct && findedProduct.length > 0) {
                        if (findedProduct[0].attributes && findedProduct[0].attributes.length > 0) {
                            let newAttr = [];

                            // console.log(findedProduct[0].attributes);

                            findedProduct[0].attributes.forEach((fatt) => {
                                // console.log(fatt.name, eleP[0].attributes);
                                // console.log("index=>", eleP[0].attributes.findIndex((p) => JSON.stringify(p.name) === JSON.stringify(fatt.name)));

                                if (eleP[0].attributes.findIndex((p) => JSON.stringify(p.name) === JSON.stringify(fatt.name)) > -1) {
                                    // shade is already available
                                    let findAttInd = eleP[0].attributes.findIndex((p) => p.name === fatt.name);
                                    let findAtt = eleP[0].attributes.filter((p) => p.name === fatt.name);
                                    let originalQty = findedProduct[0].stockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity || [];
                                    eleP[0].attributes[findAttInd].availableQty = +originalQty - +findAtt[0].shadeQuantity;
                                    newAttr.push(eleP[0].attributes[findAttInd]);
                                } else {
                                    // console.log(fatt);
                                    // console.log(eleP);

                                    // find unitCode
                                    if (eleP[0].attributes && eleP[0].attributes.length > 0) {
                                        let uniCodeSelectByDefault = eleP[0].attributes[0].unitCode;
                                        let finalUnitCode = fatt.unitCodes.filter((filt) => filt.unitName === uniCodeSelectByDefault.unitName)[0];
                                        if (finalUnitCode) {
                                            // console.log(finalUnitCode);
                                            //shade is not available
                                            newAttr.push({
                                                convFact: finalUnitCode.convFact || 1,
                                                description: fatt.description,
                                                code: fatt.code,
                                                name: fatt.name,
                                                shade: fatt.name,
                                                productPrice: eleP[0].productPrice,
                                                price: fatt.price,
                                                quantity: 0,
                                                quantityUnit: fatt.quantityUnit || "NA",
                                                shadeQuantity: 0,
                                                availableQty: +findedProduct[0].stockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity || 0,
                                                unitCode: finalUnitCode
                                            });
                                        } else {
                                            // console.log("shade unitcode is missing", eleP[0].name, fatt.name, uniCodeSelectByDefault);
                                        }
                                    } else {
                                        // console.log(findedProduct);
                                        let finalUnitCode = fatt.unitCodes.filter((filt) => filt.unitName === findedProduct[0].defaultUOM.value)[0];

                                        if (finalUnitCode) {
                                            finalUnitCode.shade = fatt.name;
                                            newAttr.push({
                                                convFact: finalUnitCode.convFact || 1,
                                                description: fatt.description,
                                                code: fatt.code,
                                                name: fatt.name,
                                                shade: fatt.name,
                                                productPrice: eleP[0].productPrice,
                                                price: fatt.price,
                                                quantity: 0,
                                                quantityUnit: finalUnitCode.unitName || "NA",
                                                shadeQuantity: 0,
                                                availableQty: +findedProduct[0].stockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity || 0,
                                                unitCode: finalUnitCode
                                            });
                                        }
                                        // console.log("attribite array is missing", eleP[0].name, fatt.name);
                                    };

                                };
                            });

                            if (newAttr && newAttr.length > 0) {
                                // console.log(newAttr);
                                eleP[0].attributes = newAttr;
                            };

                        };
                    };
                };
            });
        };

        // console.log(multipleProducts);

        this.setState({
            multipleProducts: multipleProducts
        });

    };

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">

                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update Order Template" : "Add Order Template"}
                                    </div>
                                </div>

                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <SingleBrandDropDown
                                                        value={this.state.brand}
                                                        ondropdownChange={(e) => this.dropdownValueChange(e, "brand")}
                                                        placeholder="Search Brand"
                                                        errMessage={this.state.brandError}
                                                        disable={this.state.isMultiple && this.state.multipleProducts.length > 0}
                                                    />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <TextAreaInputField
                                                        onChange={(e) => this.dropdownValueChange(e.target.value, "note")}
                                                        type="text"
                                                        name="note"
                                                        maxLength="5000"
                                                        value={this.state.note}
                                                        className="form-control"
                                                        placeholder="Note"
                                                    />
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className='col-md-12'>

                                                    <div className='alert alert-primary'>

                                                        <div className='row'>
                                                            <div className="form-group col-12 col-lg-4">
                                                                {/* {console.log(this.state.filterProduct)} */}
                                                                {
                                                                    <ProductDropdown
                                                                        isLabel={true}
                                                                        ondropdownChange={(e) => this.dropdownValueChange(e, "product")}
                                                                        placeholder="Search Product"
                                                                        value={this.state.product}
                                                                        errMessage={this.state.productError}
                                                                        dontPreload={this.state.brand ? false : true}
                                                                        filterBy={this.state.filterProduct}
                                                                    />
                                                                }
                                                            </div>

                                                            <div className="form-group col-12 col-lg-4">
                                                                <SelectInputField label="UOM" errMessage={this.state.uomError}
                                                                    onChange={(e) => this.dropdownValueChange(e.target.value, "uom_data")} name="uom_data" value={this.state.uom_data}
                                                                    className="form-control" options={this.state.uom} />
                                                            </div>

                                                            {/* {console.log(this.state.shade.length)} */}

                                                            {
                                                                this.state.shade && this.state.shade.length > 0 && this.state.uom_data && this.state.product ?
                                                                    <div className='w-100'>
                                                                        <div className="row mb-3 mx-0">
                                                                            <div className="col-3">
                                                                                Shade Name
                                                                            </div>
                                                                            <div className="col-3">
                                                                                Shade Code
                                                                            </div>
                                                                            <div className="col-4">
                                                                                Shade Description
                                                                            </div>
                                                                            <div className="col-2 text-center">
                                                                                Qty
                                                                            </div>
                                                                            {/* <div className="col-2">
                                                                                Max Qty
                                                                            </div> */}
                                                                        </div>
                                                                        {/* {console.log(this.state.shade)} */}
                                                                        {
                                                                            this.state.shade.map((ele, idx) =>
                                                                                <div className="row mb-1 mx-0" key={idx}>
                                                                                    <div className="col-3" key={idx + "name"}>
                                                                                        <Inputfield noLabel="true" type="text" className="form-control" name={idx} id={ele.name + "_" + idx} placeholder="Shade name" value={ele.name} disabled="true" />
                                                                                    </div>
                                                                                    <div className="col-3" key={idx + "name"}>
                                                                                        <div className="form-control" name={idx} id={ele.name + "_" + idx}>{ele.code || "NA"}</div>
                                                                                    </div>
                                                                                    <div className="col-4" key={idx + "name"}>
                                                                                        <div className="form-control" name={idx} id={ele.name + "_" + idx}>{ele.description || "NA"} </div>
                                                                                    </div>
                                                                                    <div className="col-2" key={idx + "minus"}>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <button className="btn btn-danger border-radius-0" onClick={(e) => this.QTYfun(ele, "minus", idx)} type="button">-</button>
                                                                                            <div key={idx + "select_qty"}>
                                                                                                <input type="number" className="form-control min-80 border-radius-0 text-center" name="quantity" id={ele.name + "_quantity_" + idx} placeholder="QTY" onChange={(e) => this.QTYfun(ele, "change", idx, e.target.value)} max={ele.quantity} min={0} value={ele.selectedQTY} />
                                                                                            </div>
                                                                                            <div key={idx + "plus"}>
                                                                                                <button className="btn btn-primary border-radius-0" onClick={(e) => this.QTYfun(ele, "add", idx)} type="button">+</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="col-2" key={idx + "qty"}>
                                                                                        {ele.quantity || 0}
                                                                                    </div> */}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    : this.state.product && this.state.uom_data ? <p className="col-12 mt-2 mb-2 text-center text-danger">Stock is unavailable</p> : null
                                                            }
                                                        </div>

                                                        <div className="row">
                                                            <div className='col-3 mt-5 '>
                                                                <label className="mr-2">Total Amount Per Product</label>
                                                                <p className="form-control border border-primary rounded text-right">{parseFloat(this.state.productTotal || 0).toFixed(2)} /-</p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6 mb-4 mt-3 offset-md-3 text-center">
                                                                <Buttons onclick={this.addMoreItem} type="button" className="btn btn-success mr-1" name="Add" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                this.state.alertMessage ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-danger  text-center'>
                                                                <span className='mb-0'>{this.state.alertMessage}</span>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }

                                            {
                                                this.state.isMultiple && this.state.multipleProducts && this.state.multipleProducts.length > 0 ?
                                                    <table className="table table-bordered order-list-data">

                                                        <thead>
                                                            <tr>
                                                                <th className='border-radius-0 p-2'>Product Name</th>
                                                                <th className='border-radius-0 p-2'>UOM</th>
                                                                <th className='border-radius-0 p-2'>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <span>
                                                                            Shade
                                                                        </span>
                                                                        <span>Qty</span>
                                                                    </div>
                                                                </th>
                                                                <th className='border-radius-0 p-2'>Total Qty</th>
                                                                <th className='border-radius-0 p-2'>Product Price</th>
                                                                {/* <th className='border-radius-0 p-2'>Conv. Fact</th> */}
                                                                <th className='border-radius-0 p-2'>Total</th>
                                                                <th className='border-radius-0 p-2 text-center'>Remove</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.multipleProducts.map((ele, idx) =>
                                                                    <tr key={idx}>
                                                                        <td key={idx + "qty"}>
                                                                            {ele[0].productName}
                                                                        </td>

                                                                        <td key={idx + "uomName"}>
                                                                            {ele[0].measurementUnit.label}
                                                                        </td>

                                                                        <td className='shade-cell' key={idx + "shade"}>
                                                                            {
                                                                                ele[0].attributes.map((att, i) =>
                                                                                    <ul className='list-unstyled mb-0' key={"s" + i}>
                                                                                        <li className='d-flex align-items-center justify-content-between shade-qty'>
                                                                                            <button title={"stock details"} className={"btn btn-link text-primary action-btn p-0"}
                                                                                                disabled={false}
                                                                                                type={"button"}
                                                                                                data-original-title={"Click to Stock Details"} data-target={".bd-example-modal-lg"}
                                                                                                onClick={() => this.openDesc(att)} data-placement={"top"} data-toggle={"modal"}>
                                                                                                {/* <i className={classNameI}></i> */}
                                                                                                {att.name || "NA"}
                                                                                            </button>
                                                                                            -
                                                                                            <div>
                                                                                                {
                                                                                                    ele[0].isEditOpen ?
                                                                                                        <Fragment>
                                                                                                            {/* {console.log(att.availableQty)} */}
                                                                                                            {/* disabled={Number(att.availableQty) <= 0}  */}
                                                                                                            <input type="number" className="text-right form-control w-auto ml-auto" name="shadeQuantity" id={att.shadeQuantity + "_quantity_" + idx} placeholder="shadeQty"
                                                                                                                onChange={(e) => this.changeEditQty(ele[0], att, i, e.target.value)}
                                                                                                                min={0}
                                                                                                                value={att.shadeQuantity} />
                                                                                                            {/* <span className="text-success">&nbsp;{att.availableQty || 0}</span> */}
                                                                                                        </Fragment>
                                                                                                        :
                                                                                                        att.shadeQuantity
                                                                                                }
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                )
                                                                            }
                                                                        </td>

                                                                        <td key={idx + "pqty"}>
                                                                            {ele[0].totalProductQty}
                                                                        </td>

                                                                        <td key={idx + "price"}>
                                                                            {ele[0].productPrice}
                                                                        </td>

                                                                        {/* <td key={idx + "conFact"}>
                                                                            {ele[0].measurementUnit.value}
                                                                        </td> */}

                                                                        <td key={idx + "subTotal"}>
                                                                            {parseFloat(ele[0].subTotal).toFixed(2)}
                                                                        </td>

                                                                        <td className='text-center' key={idx + "delete/edit"}>
                                                                            {
                                                                                ele[0].isEditOpen ?
                                                                                    <button className="btn btn-success mr-1" title="Save" onClick={(e) => this.confirmEditProduct(idx)}>
                                                                                        <i className="fa fa-check"></i>
                                                                                    </button> :
                                                                                    <button className="btn btn-primary mr-1" title="Edit" onClick={(e) => this.editProduct(ele[0], idx)}>
                                                                                        <i className="fa fa-pencil"></i>
                                                                                    </button>
                                                                            }
                                                                            <button className="btn btn-danger mr-1" title="Edit" onClick={(e) => this.deleteDuplicate(e, idx)}>
                                                                                <i className="ti ti-trash"></i>
                                                                            </button>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    : null
                                            }

                                            <div className="row  justify-content-end">
                                                <div className='col-3 mt-5 text-right'>
                                                    <label className="mr-2">Total Amount</label>
                                                    <p className="form-control border border-primary rounded ">{parseFloat(this.state.totalAmount || 0).toFixed(2)}/-</p>
                                                </div>
                                            </div>

                                            {/* {console.log(getSingleLoader, isLoading)} */}

                                            <div className="row">
                                                <div className="col-md-6 mb-4 mt-3 offset-md-3 text-center">
                                                    {/* <Buttons onclick={this.addMoreItem} type="button" className="btn btn-success mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name="Add More" /> */}
                                                    <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name="Save" />
                                                    <Links to={routehelp.orderTemplateList} className="btn btn-outline-dark" name="Cancel" />
                                                </div>
                                            </div>

                                        </div>
                                }

                                <div
                                    className="modal fade bd-example-modal-lg"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="myLargeModalLabel"
                                    style={{ display: "none" }}
                                    aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="myLargeModalLabel">
                                                    {"Shade Details"}
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true" className='cursor' title='close'>×</span>
                                                </button>
                                            </div>

                                            <div className="modal-body">
                                                <div className="row">

                                                    <table className="table table-bordered dataTable" cellSpacing="0">

                                                        <thead>
                                                            <tr>
                                                                <th>Shade Name</th>
                                                                <th>Shade Code</th>
                                                                <th>Shade Description</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {this.state.shadeDesc.name}
                                                                </td>
                                                                <td>
                                                                    {this.state.shadeDesc.code}
                                                                </td>
                                                                <td>
                                                                    {this.state.shadeDesc.description}
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    };

}

function mapStateToProps(state) {
    return {
        uommanagement_res: state.uommanagement_res,
        orderTemplate_create_res: state.orderTemplate_create_res,
        orderTemplate_update_res: state.orderTemplate_update_res,
        orderTemplate_get_res: state.orderTemplate_get_res,
        product_single: state.product_single
    };
};

const mapDispatchToProps = dispatch => ({
    uommanagementlist: data => dispatch(uommanagementlist(data)),
    orderTemplate_create: data => dispatch(orderTemplate_create(data)),
    orderTemplate_update: (data, item_id) => dispatch(orderTemplate_update(data, item_id)),
    orderTemplate_get: item_id => dispatch(orderTemplate_get(item_id)),
    productsingle: (data) => dispatch(productsingle(data))
});

OrderTemplateForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderTemplateForm);

export default OrderTemplateForm;